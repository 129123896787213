import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { EVENT_CONSTANT } from '../../../../constants/event-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { EventDao } from '../../../../db-models/event-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { EventCartItem } from '../../../../models/cart.model';
import { GlobalObjects, Partner } from '../../../../models/global';
import { EventState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';

@Component({
  selector: 'app-event-navigation-bar',
  templateUrl: './event-navigation-bar.component.html',
  styleUrls: ['./event-navigation-bar.component.scss'],
  standalone: true,
  imports: [NgClass, FontAwesomeModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, TranslationPipe]
})
export class EventNavigationBarComponent implements OnDestroy {

  @Input() supportedWidgetLanguages: string[];
  @Input() viewMode: string;
  @Input() events: EventDao[];
  @Input() eventState: EventState;
  @Input() cart: EventCartItem[];
  @Input() showAds: boolean;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() partner: Partner;
  @Input() lang: string;
  @Input() hideSlotStep = false;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() globals: GlobalObjects;
  @Input() langSwitcher = true;

  @Output('navigateToEvent') navigateToEvent = new EventEmitter<any>();

  private translate = inject(TranslateService);

  readonly eventConstants = EVENT_CONSTANT;
  readonly widgetConstant = WIDGET_CONSTANTS;
  readonly templateContent = TRANSLATION_TEMPLATES;

  alive: boolean;
  environment: { [key: string]: any };
  widgetEventsLabelTemplate: CustomBookingMessageTemplate;
  widgetSlotsLabelTemplate: CustomBookingMessageTemplate;
  widgetSummaryLabelTemplate: CustomBookingMessageTemplate;

  constructor() {
    this.environment = environment;
    this.alive = true;
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ignore(): boolean {
    return false;
  }

  navigateTo(page: string): void {
    this.navigateToEvent.next(page);
  }

  setColorProperties(viewMode: string): any {
    return {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['widgetTemplates'] && changes['widgetTemplates']['currentValue']) {
      if (this.widgetTemplates && this.widgetTemplates.length > 0) {
        this.setupTemplates();
      }
    }
  }

  setupTemplates(): void {
    this.widgetEventsLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NAV_EVENTS_LABEL;
    });
    if (this.widgetEventsLabelTemplate) {
      this.widgetEventsLabelTemplate.is_multi_language = 1;
    }
    this.widgetSlotsLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NAV_SLOTS_LABEL;
    });
    if (this.widgetSlotsLabelTemplate) {
      this.widgetSlotsLabelTemplate.is_multi_language = 1;
    }
    this.widgetSummaryLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NAV_SUMMARY_LABEL;
    });
    if (this.widgetSummaryLabelTemplate) {
      this.widgetSummaryLabelTemplate.is_multi_language = 1;
    }
  }

  switchLanguage(language: string): void {
    this.lang = language;
    this.translate.use(language);
    this.globals.user_selected_language = language;
  }
}
