import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { WIDGET_CONSTANTS } from '../constants/widget-constants';
import { EventStatisticsTraceModel } from '../db-models/event-dao';
import { AnalyticsDao, SubscriptionDao } from '../db-models/widget-conf-dao';
import { CUSTOM_CSS_WIDGET_TYPE } from '../models/widget-conf';
import { LoggerService } from './logger.service';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  private http = inject(HttpClient);
  private destroyRef = inject(DestroyRef);

  partnerAnalyticsObj: AnalyticsDao;
  private sessionUuid: string;
  private _partnerSubscriptionPlan: SubscriptionDao;

  set partnerSubscriptionPlan(subscriptionPlan: SubscriptionDao) {
    this._partnerSubscriptionPlan = subscriptionPlan;
  }

  emitBookingEvent(eventName: string, params: any = null) {
    const type = 'TRACKER_TRIGGER_ACTION';

    // Google Tag Manager
    try {
      if (this.partnerAnalyticsObj?.GTM && this.partnerAnalyticsObj?.GTM.trim() !== '') {
        dataLayer.push({
          event: eventName,
          ...params
        });
        LoggerService.log(`[GOOGLE_TAG]:[${eventName}] event was fired.`, params);
      }
    } catch (error) {
      LoggerService.warn('[GOOGLE_TAG]: Datalayer not defined.');
      LoggerService.error(error);
    }

    // Adobe launch tracking
    try {
      if (this.partnerAnalyticsObj?.AdobeLaunchTracking && this.partnerAnalyticsObj?.AdobeLaunchTracking.trim() !== '') {
        const someClick = new CustomEvent(type, {
          detail: {
            name: eventName,
            params
          }
        });
        window.dispatchEvent(someClick);
        LoggerService.log(`[ADOBE_LAUNCH_TRACKING]:[${eventName}] event was fired.`, params);
      }
    } catch (error) {
      LoggerService.warn('[ADOBE_LAUNCH_TRACKING]: Adobe event error handler.');
      LoggerService.error(error);
    }

    // Only enterprise partners can access this feature
    if ((this._partnerSubscriptionPlan?.subscription_type_id === WIDGET_CONSTANTS.SUBSCRIPTION_NEW_ENTERPRISE_TYPE.id ||
      this._partnerSubscriptionPlan?.subscription_type_id === WIDGET_CONSTANTS.SUBSCRIPTION_ENTERPRISE_TYPE.id)) {
      !this.sessionUuid && this.generateSessionUuid();
      this.emitBookingDetails(eventName, params);
    }

    // Send event to parent window
    this.emitEventToParent(eventName, params);
  }

  emitEventToParent(eventName: string, params: any = null): void {
    try {
      window.parent.postMessage(
        {
          eventName: eventName,
          bookingData: params,
        },
        '*'
      );
      LoggerService.log(`[EMIT_TO_PARNT_WINDOW]:[${eventName}] event was fired.`, params);
    } catch (error) {
      LoggerService.warn('[EMIT_TO_PARNT_WINDOW]: Adobe event error handler.');
      LoggerService.error(error);
    }
  }

  private emitBookingDetails(eventName: string, metaData: any = null): void {
    const param = {
      session_id: this.sessionUuid,
      widget_type: CUSTOM_CSS_WIDGET_TYPE.widget,
      event: eventName,
      appointment_type: this.extractBookingType(eventName),
      ...metaData && { event_meta: JSON.stringify(metaData) }
    };
    this.updateBookingDetails(param)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: error => LoggerService.log(error)
      });
  }

  updateBookingDetails(params: Partial<EventStatisticsTraceModel>): Observable<EventStatisticsTraceModel> {
    return this.http.post<EventStatisticsTraceModel>(environment.apiUrl + 'statistics/trace', params, {});
  }

  private extractBookingType(eventName: string): string | null {
    if (eventName.startsWith('appointment_')) {
      return WIDGET_CONSTANTS.APPOINTMENT;
    } else if (eventName.startsWith('event_')) {
      return WIDGET_CONSTANTS.GROUP_APPOINTMENT;
    } else if (eventName.startsWith('compact_widget_')) {
      return WIDGET_CONSTANTS.COMPACT;
    }
    return null;
  }

  generateSessionUuid(): void {
    this.sessionUuid = uuidv4();
  }
}
