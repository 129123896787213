import { StoresDao } from './stores-dao';
import { WorkerDao } from './widget-conf-dao';

export class AppointmentsSlotsDao {
  start: string;
  end: string;
  appointment_start: string;
  appointment_end: string;
  store: StoresDao;
  worker: WorkerDao;
  appointment_short_start: string;
  short_start: string;
  startHour: number;
  isGrouped?: boolean;
  appointmentOrderIndex?: number;
  location: string;
  parentServiceId?: number; // This will be used for realted service
  workerUuids: string[];
  is_reserved: boolean;
  is_bookable: boolean;

  // client side properties
  notes: string[];
  isExpanded = false;
}

interface MinutesBlocksDao {
  start: string,
  end: string,
  minutes: number,
  appointments_slots: AppointmentsSlotsDao[]
}

export interface WorkerMinutesBlockDao {
  worker: WorkerDao;
  workers: WorkerDao[];
  store: StoresDao;
  minutes_blocks: MinutesBlocksDao[];
}

export interface AvailableMinutesBlocksDao {
  date: string;
  workers_minutes_blocks: WorkerMinutesBlockDao[];
}

/**
 * Class representing day-wise free slots for appointments scheduling
 * Manages notes and availability status for different time slots
 */
export class DayWiseFreeSlotsDao {
  /** Map to store notes for each time slot key */
  private notesMap = new Map<string, string[]>();

  /** Map to store availability status for each date */
  private dayWiseAaailabilityMap = new Map<string, number>();

  /** Map to store availability status for each time slot key (date|appointment_short_start) */
  private slotWiseAaailabilityMap = new Map<string, boolean>();

  private commonNotes = new Array<string>();

  /**
   * Initializes a new instance of DayWiseFreeSlotsDao
   * @param available_minutes_blocks - Array of available minutes blocks containing scheduling data
   * @param debug - Optional debug data containing notes and worker appointments slots information.
   *               If provided, triggers generation of notes and availabilities
   */
  constructor(
    public available_minutes_blocks: AvailableMinutesBlocksDao[],
    public debug?: DebugDataDao
  ) {
    debug && this.generateNotesAndAvailabilities();
  }

  /**
   * Returns the common notes that apply to all time slots
   * @returns An array of strings containing common notes shared across all slots
   */
  getCommonNotes(): string[] {
    return this.commonNotes;
  }

  /**
   * Retrieves notes associated with a specific time slot key
   * @param key - A string in the format "date|appointment_short_start" that uniquely identifies a time slot
   * @returns An array of notes strings associated with the time slot, or an empty array if no notes exist
   */
  getDayWiseNotes(key: string): string[] | undefined {
    return this.notesMap.get(key) ?? [];
  }

  /**
   * Gets the availability status for a specific date
   * @param date - The date string to check availability for
   * @returns A number indicating the availability status:
   *          0 - No slots are bookable (Default value)
   *          1 - All slots are bookable
   *          2 - Mixed (both bookable and non-bookable slots exist)
   *          undefined - No data available for the given date
   */
  getDayAvailability(date: string): number | undefined {
    return this.dayWiseAaailabilityMap.get(date);
  }

  /**
   * Gets the availability status for a specific time slot
   * @param key - A string in the format "date|appointment_short_start" that uniquely identifies a time slot
   * @returns A boolean indicating if the slot is available for booking, or undefined if no data exists
   *          true - The slot is bookable
   *          false - The slot is not bookable
   *          undefined - No availability data exists for this slot
   */
  getSlotAvailability(key: string): boolean | undefined {
    return this.slotWiseAaailabilityMap.get(key);
  }

  /**
   * Generates notes and availability status for time slots based on debug data
   * Populates the notesMap and dayWiseAvailabilityMap
   */
  private generateNotesAndAvailabilities(): void {
    const notesMap = new Map<string, string[]>();
    const dayWiseAaailabilityMap = new Map<string, number>();
    const slotWiseAaailabilityMap = new Map<string, boolean>();

    /**
     * Assigns common notes from debug data to the instance
     * These notes are applicable across all time slots
     */
    this.commonNotes = this.debug.notes;

    if (!this.debug?.workers_appointments_slots) {
      return;
    }

    for (const workerSlots of Object.values(this.debug.workers_appointments_slots)) {
      for (const workerSlot of workerSlots) {
        const { date, appointments_slots } = workerSlot;
        let hasBookable = false;
        let hasNonBookable = false;

        for (const slot of appointments_slots) {
          const { appointment_short_start, notes = [], is_bookable } = slot;

          // identify if the day has both bookable and non-bookable slots
          is_bookable ? (hasBookable = true) : (hasNonBookable = true);

          // combine notes for the same time slot
          const key = `${date}|${appointment_short_start}`;
          const existingNotes = notesMap.get(key) ?? [];
          existingNotes.push(...notes);
          notesMap.set(key, existingNotes);

          // Directly update slot availability
          slotWiseAaailabilityMap.set(key, slotWiseAaailabilityMap.get(key) || is_bookable);
        }

        // if date has both bookable and non-bookable slots, set availability as 2 means mixed slots,
        // else set as 1 means all slots are bookable or 0 means non of the slots are bookable
        dayWiseAaailabilityMap.set(date, (hasBookable && hasNonBookable ? 2 : (hasBookable ? 1 : 0)));
      }
    }

    this.notesMap = notesMap;
    this.dayWiseAaailabilityMap = dayWiseAaailabilityMap;
    this.slotWiseAaailabilityMap = slotWiseAaailabilityMap;
  }
}

interface DebugDataDao {
  notes: string[];
  workers_appointments_slots: { [key: string]: DebugWorkerAppointmentsSlotsDao[] };
}

interface DebugWorkerAppointmentsSlotsDao {
  date: string;
  appointments_slots: DebugAppointmentsSlotsDao[];
}

interface DebugAppointmentsSlotsDao {
  appointment_start: string;
  appointment_end: string;
  appointment_short_start: string;
  is_bookable: boolean;
  notes: string[];
}
