import { CurrencyPipe, NgClass } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { booleanAttribute, Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, ElementRef, inject, Input, numberAttribute, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import 'intl-tel-input';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../constants/appointment-constants';
import { EVENT_CONSTANT } from '../constants/event-constants';
import { MappedAppointmentServicesDao } from '../db-models/appointment-service-dao';
import { CategoryDbModel } from '../db-models/categories-db.model';
import { CONFERENCE_ERROR_CODES, CONFERENCE_ERROR_STATE, ConferenceDbModel } from '../db-models/conference-db.model';
import { StoreDbModel } from '../db-models/stores-dao';
import { AnalyticsDao, PartnerDao, WidgetBookingInfoDao, WorkerDao } from '../db-models/widget-conf-dao';
import { GlobalObjects, Partner } from '../models/global';
import { IPartnerInformationBody, TokenType } from '../models/partners';
import { ConferenceState, UserState } from '../models/state.model';
import { WidgetColorConf } from '../models/widget-color.model';
import { CssOverwrites, CustomerPrefillDataModel, WidgetConf } from '../models/widget-conf';
import { AppointmentService } from '../services/appointment.service';
import { ConferenceService } from '../services/conference.service';
import { CustomEventService } from '../services/custom-event.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { HelperService } from '../services/helper.service';
import { LocalStorageService } from '../services/local-storage.service';
import { LoggerService } from '../services/logger.service';
import { PartnerService } from '../services/partner.service';
import { UtilService } from '../services/util.service';
import { WidgetUtilService } from '../services/widget-util.service';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { ButtonComponent } from '../shared/components/button/button.component';
import { FinalpageComponent } from '../shared/components/finalpage/finalpage.component';
import { GtmOperationComponent } from '../shared/components/gtm-operation/gtm-operation.component';
import { NewAlertComponent } from '../shared/components/new-alert/new-alert.component';
import { WIDGET_APPOINTMENT_TYPE, WIDGET_CONSTANTS } from './../constants/widget-constants';
import { PasswordProtectionComponent } from "./common/password-protection/password-protection.component";
import { icons } from './fortAwesomeIcons';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { CompactWidgetComponent } from './pages/compact-widget/compact-widget.component';
import { EventComponent } from './pages/event/event.component';

const modules = [NgSelectModule, FormsModule, TranslateModule];
const components = [AlertComponent, NewAlertComponent, ButtonComponent, EventComponent, FinalpageComponent, CompactWidgetComponent, GtmOperationComponent, AppointmentComponent, PasswordProtectionComponent];
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  standalone: true,
  imports: [NgClass, ...modules, ...components],
  providers: [CurrencyPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WidgetComponent implements OnInit {

  private partnerService = inject(PartnerService);
  private translateService = inject(TranslateService);
  private widgetUtilService = inject(WidgetUtilService);
  private helperService = inject(HelperService);
  private localStorageService = inject(LocalStorageService);
  private utilService = inject(UtilService);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private conferenceService = inject(ConferenceService);
  private appointmentService = inject(AppointmentService);
  private customEventService = inject(CustomEventService);
  private destroyRef = inject(DestroyRef);
  protected library = inject(FaIconLibrary);

  @Input('partner') bookingName: string = WIDGET_CONSTANTS.BOOKING_NAME;
  @Input('type') bookingType = WIDGET_CONSTANTS.ANY;
  @Input('lang') set lang(value: string) {
    if (value) {
      if (this.enableLangSetter) {
        this.setLanguage(value, (this.partnerGlobalData || null))
      } else {
        this._lang = value;
      }
    }
  }
  @Input('language') set language(value: string) {
    if (value) {
      if (this.enableLangSetter) {
        this.setLanguage(value, (this.partnerGlobalData || null))
      } else {
        this._language = value;
      }
    }
  };
  @Input('worker-uuid') workerUuid: string;
  @Input('meeting-type-id') meeting_type_id: string;
  @Input('customer-notification-preference') customer_notification_preference: string;
  @Input('booker-worker-id') bookerWorkerId: number;
  @Input('event-id') eventId: string;
  @Input('is-whitelabel') isWhiteLabeled: string;
  @Input('selected-store-id') selectedStoreId: number;
  @Input('selected-store-zip') selectedStoreZipCode: number;
  @Input('selected-store-name') selectedStoreName: string;
  @Input('selected-appointment-service-ids') selectedAppointmentServiceIds: string | string[];
  @Input('selected-appointment-service-category-ids') selectedAppointmentServiceCategoryIds: string | string[];
  @Input({
    alias: 'selected-worker-id',
    transform: numberAttribute
  }) selectedWorkerId: number;
  @Input('selected-worker-email') selectedWorkerEmail: string;
  @Input('selected-custom-fields') selectedCustomFields: string;
  @Input('selected-customer') selectedCustomerDetail: string;
  @Input('q') base64String: string;
  @Input() internal: string;
  @Input('date') calendarPreselectedDate: string;
  @Input('date-context') dateContext: string;
  @Input('date-context-start') dateContextStart: string;
  @Input('date-context-end') dateContextEnd: string;
  @Input('utm-source') utmSource: string;
  @Input('utm-medium') utmMedium: string;
  @Input('utm-campaign') utmCampaign: string;
  @Input('utm-content') utmContent: string;
  @Input('utm-term') utmTerm: string;
  @Input('disable-localstorage') disableLocalStorage: number;
  @Input('ui-mode') uiMode: string;
  @Input('hide-grid-switcher') hideGridSwitcher: string = 'false';
  @Input() template = '';
  @Input('internal-comment') internalComment = '';
  @Input('lang-switcher') langSwitcher: any = true;
  @Input('is-preview') isPreview: any = true;
  @Input() token = '';
  @Input('filter-stores') filterStores: string | string[];
  @Input('filter-categories') filterCategories: string | string[];
  @Input('conference-uuid') conferenceUuid: string;
  @Input() debug: string;
  @Input('hide-store') hideStoreSelection = false;
  @Input({ transform: booleanAttribute, alias: 'skip-qualification-question' }) skipQualificationQuestion = false;

  readonly widgetConstants = WIDGET_CONSTANTS;
  readonly baseUrl = environment.baseUrl;

  @ViewChild('calioSection', { static: true }) calioSectionRef: ElementRef;

  isFrame: boolean;
  tokenError = undefined;
  tokenErrorMessage = '';
  selectedJsonCustomFields: { [key: number]: { type: string, value: any } | any };
  selectedJsonCustomerDetail: CustomerPrefillDataModel;
  widgetConf: WidgetConf;
  bookTypesEnabled: string[] = [];
  currentBookingType: string;
  partnerGlobalData: Partner;
  globals: GlobalObjects;
  analytics: AnalyticsDao;
  environment: any;
  showDevAlert: boolean;
  isInternal: boolean;
  whiteLabelBoolean: boolean;
  widgetBookingInfo: WidgetBookingInfoDao;
  widgetColorConf: WidgetColorConf;
  now = new Date();
  showWorkersNotAvailableError = false;
  decodedBase64String: string;
  isBookingDisabled = false;
  isLoggingEnabled = false;
  showSubscriptionError = false;
  subscriptionType: number;
  foundedWorker: WorkerDao;
  cssOverwrites: string;
  eventCategories: CategoryDbModel[] = [];
  eventStores: StoreDbModel[] = [];
  isCompactMode = false;
  localStorageAccessible = true;
  conferenceNotBelongsWarning = false;
  errorInConference: string;
  conferenceState: Partial<ConferenceState> = {};
  workerUuids: string | string[];
  enableLangSetter = false;
  _lang: string;
  _language: string;
  isWidgetPasswordProtected = false;
  bookingWidgetMasterSettingsEnabled = true; // This will be used to disable the whole widget (Toggle from Partner management)
  appointmentExternalBookingEnabled = true;
  appointmentInternalBookingEnabled = true;
  eventExternalBookingEnabled = true;

  constructor() {
    this.library.addIcons(...icons);
    this.translateService.onLangChange.subscribe(language => this._lang = language.lang);
  }

  private setLanguage(language: string, partner: Partner | PartnerDao): void {
    const finalLang = this.helperService.getDefaultLanguage(partner, language);
    this._lang = finalLang;
    this._language = finalLang;
    this.translateService.use(finalLang);
    this.globals?.user_browser_language && (this.globals.user_browser_language = finalLang);
    this.globals?.user_selected_language && (this.globals.user_selected_language = finalLang);
    this.localStorageService.setItem(WIDGET_CONSTANTS.USER_LANG, finalLang, () => void (0));
  }

  ngOnInit(): void {
    this.langSwitcher = (['false', false].includes(this.langSwitcher) ? false : true);
    this._lang = this._language ? this._language : this._lang;

    if (this.selectedWorkerEmail) {
      this.selectedWorkerEmail = this.selectedWorkerEmail.toLowerCase();
    }

    if (this.selectedAppointmentServiceIds) {
      this.selectedAppointmentServiceIds = (<string>this.selectedAppointmentServiceIds).split(',');
    }

    if (this.selectedAppointmentServiceCategoryIds) {
      this.selectedAppointmentServiceCategoryIds = (<string>this.selectedAppointmentServiceCategoryIds).split(',');
    }

    if (this.filterStores) {
      this.filterStores = (<string>this.filterStores).split(',');
    }

    if (this.filterCategories) {
      this.filterCategories = (<string>this.filterCategories).split(',');
    }

    if (this.selectedCustomFields && this.selectedCustomFields !== '') {
      this.selectedJsonCustomFields = JSON.parse(this.selectedCustomFields);
    }

    if (this.selectedCustomerDetail && this.selectedCustomerDetail !== '') {
      this.selectedJsonCustomerDetail = JSON.parse(this.selectedCustomerDetail);
      if (this.selectedJsonCustomerDetail?.email) {
        this.selectedJsonCustomerDetail.email = this.helperService.fixEmailSpaceIssue(this.selectedJsonCustomerDetail.email);
      }
    }

    this.conferenceUuid && (this.conferenceUuid = this.conferenceUuid.trim());

    const urlQueryStringValue = decodeURIComponent(window.location.search.substring(1));
    const urlParams = urlQueryStringValue.split('&').reduce(function (q, query) {
      const chunks = query.split('=');
      const key = chunks[0];
      let value: string;
      if (chunks[1] === 'NULL' || chunks[1] === 'null' || chunks[1] === 'undefined' || chunks[1] === 'UNDEFINED') {
        value = undefined;
      } else {
        value = chunks[1];
      }
      return (q[key] = value, q);
    }, {});

    if (urlParams['q']) {
      this.base64String = urlParams['q'];
    }

    let base64Params: any;
    if (this.base64String && this.base64String !== 'null' && this.base64String !== null) {
      this.decodedBase64String = decodeURIComponent(window.atob(decodeURIComponent(this.base64String)));
      base64Params = this.decodedBase64String.split('&').reduce(function (q, query) {
        const chunks = query.split('=');
        const key = chunks[0];
        let value: string;
        if (chunks[1] === 'NULL' || chunks[1] === 'null' || chunks[1] === 'undefined' || chunks[1] === 'UNDEFINED') {
          value = undefined;
        } else {
          value = chunks[1];
        }
        return (q[key] = value, q);
      }, {});
    }

    this.isFrame = Boolean(urlParams['isFrame']);
    this.isCompactMode = (this.widgetConstants.COMPACT === this.bookingType);

    if (this.isFrame) {
      this.bookingName = urlParams['partner'];
      this.bookingType = urlParams['type'];
      this.isCompactMode = (this.widgetConstants.COMPACT === this.bookingType);
      this._lang = urlParams['language'] ? urlParams['language'] : urlParams['lang'] ? urlParams['lang'] : this._lang;
      this.template = urlParams['template'];
      this.token = urlParams['token'];
      this.calendarPreselectedDate = urlParams['date'];
      this.langSwitcher = ['false', false].includes(this.helperService.getQueryParamValues(urlParams, 'lang-switcher', 'lang_switcher')) ? false : true;
      urlParams['debug'] === "true" && (this.debug = urlParams['debug']);
      this.isPreview = this.helperService.getQueryParamValues(urlParams, 'is-preview', 'is_preview') ? true : false;
      this.isLoggingEnabled = this.helperService.getQueryParamValues(urlParams, 'enable_logs', 'enable-logs') ? true : false;
      this.isBookingDisabled = this.helperService.getQueryParamValues(urlParams, 'disable_bookings', 'disable-bookings') ? true : false;
      this.disableLocalStorage = this.helperService.getQueryParamValues(urlParams, 'disable_localstorage', 'disable-localstorage') ? 1 : 0;

      this.isLoggingEnabled && (environment.production = false);

      // Date context starts
      this.dateContext = this.helperService.getQueryParamValues(urlParams, 'date_context', 'date-context');
      // Date context ends

      // Date context start date starts
      this.dateContextStart = this.helperService.getQueryParamValues(urlParams, 'date_context_start', 'date-context-start');
      // Date context start date ends

      // Date context end date starts
      this.dateContextEnd = this.helperService.getQueryParamValues(urlParams, 'date_context_end', 'date-context-end');
      // Date context end date ends

      // worker uuid starts
      this.workerUuid = this.helperService.getQueryParamValues(urlParams, 'worker-uuid', 'worker_uuid');
      // worker uuid ends

      // meeting types starts
      this.meeting_type_id = this.helperService.getQueryParamValues(urlParams, 'meeting-type-id', 'meeting_type_id');
      // meeting types ends

      // customer notification preference starts
      this.customer_notification_preference = this.helperService.getQueryParamValues(urlParams, 'customer-notification-preference', 'customer_notification_preference');
      // customer notification preference ends

      // utm source starts
      this.utmSource = this.helperService.getQueryParamValues(urlParams, 'utm_source', 'utm-source');
      // utm source ends

      // utm medium starts
      this.utmMedium = this.helperService.getQueryParamValues(urlParams, 'utm_medium', 'utm-medium');
      // utm medium ends

      // utm content starts
      this.utmContent = this.helperService.getQueryParamValues(urlParams, 'utm_content', 'utm-content');
      // utm content ends

      // utm campaign starts
      this.utmCampaign = this.helperService.getQueryParamValues(urlParams, 'utm_campaign', 'utm-campaign');
      // utm campaign ends

      // utm term starts
      this.utmTerm = this.helperService.getQueryParamValues(urlParams, 'utm_term', 'utm-term');
      // utm term ends

      // booker worker uuid starts
      this.bookerWorkerId = this.helperService.getQueryParamValues(urlParams, 'booker-worker-id', 'booker_worker_id');
      // booker worker uuid ends

      // whitelabel starts
      this.isWhiteLabeled = this.helperService.getQueryParamValues(urlParams, 'is-whitelabel', 'is_whitelabel');
      // whitelabel ends

      // internal starts
      this.internal = urlParams['internal'];
      // internal ends

      // event id starts
      this.eventId = this.helperService.getQueryParamValues(urlParams, 'event-id', 'event_id');
      // event id ends

      // define UI Mode for events listing starts
      this.uiMode = this.helperService.getQueryParamValues(urlParams, 'ui-mode', 'ui_mode');
      // define UI Mode for events listing ends

      // hide switcher to change events listing layuot starts
      this.hideGridSwitcher = this.helperService.getQueryParamValues(urlParams, 'hide-grid-switcher', 'hide_grid_switcher');
      // hide switcher to change events listing layuot ends

      // hide store selection starts
      this.hideStoreSelection = this.helperService.getQueryParamValues(urlParams, 'hide-store', 'hide_store') === 'true' ? true : false;
      // hide store selection ends

      // skip qualification question starts
      this.skipQualificationQuestion = this.helperService.getQueryParamValues(urlParams, 'skip-qualification-question', 'skip_qualification_question') === 'true' ? true : false;
      // skip qualification question ends

      // store id starts
      this.selectedStoreId = this.helperService.getQueryParamValues(urlParams, 'store_id', 'store-id');
      // store id ends

      // store zip code starts
      this.selectedStoreZipCode = this.helperService.getQueryParamValues(urlParams, 'store-zip', 'store_zip');
      // store zip code ends

      // store name starts
      this.selectedStoreName = this.helperService.getQueryParamValues(urlParams, 'store_name', 'store-name');
      if (this.selectedStoreName) {
        this.selectedStoreName = decodeURIComponent(this.selectedStoreName);
      }
      // store name ends

      // worker id starts
      this.selectedWorkerId = this.helperService.getQueryParamValues(urlParams, 'worker_id', 'worker-id');
      this.selectedWorkerId = this.selectedWorkerId ? Number(this.selectedWorkerId) : undefined;
      // worker id ends

      // worker email starts
      this.selectedWorkerEmail = this.helperService.getQueryParamValues(urlParams, 'worker_email', 'worker-email');
      this.selectedWorkerEmail = this.selectedWorkerEmail ? this.selectedWorkerEmail.toLowerCase() : undefined;
      // worker email ends

      // internal comment
      this.internalComment = this.helperService.getQueryParamValues(urlParams, 'internal_comment', 'internal-comment');
      this.internalComment = this.internalComment ? decodeURIComponent(this.internalComment) : undefined;
      // internal comment

      // Appointment services start
      const appointmentServiceIds: any[] = this.helperService.getQueryStringKey('service', urlQueryStringValue, true);
      this.selectedAppointmentServiceIds = appointmentServiceIds ? appointmentServiceIds : [];
      if (this.selectedAppointmentServiceIds.length > 0) {
        this.selectedAppointmentServiceIds = this.selectedAppointmentServiceIds[0].split(',');
      }
      // Appointment services ends

      // Appointment category start
      const appointmentCategoryIds: any[] = this.helperService.getQueryStringKey('category', urlQueryStringValue, true);
      this.selectedAppointmentServiceCategoryIds = appointmentCategoryIds ? appointmentCategoryIds : [];
      if (this.selectedAppointmentServiceCategoryIds.length > 0) {
        this.selectedAppointmentServiceCategoryIds = this.selectedAppointmentServiceCategoryIds[0].split(',');
      }
      // Appointment category ends

      // filter stores start
      const filterStoresIds = this.helperService.getQueryParamValues(urlParams, 'filter-stores[]', 'filter_stores[]') as string;
      if (filterStoresIds) {
        this.filterStores = filterStoresIds.split(',');
      }
      // filter stores end

      // filter categories start
      const filterCategoriesIds = this.helperService.getQueryParamValues(urlParams, 'filter-categories[]', 'filter_categories[]') as string;
      if (filterCategoriesIds) {
        this.filterCategories = filterCategoriesIds.split(',');
      }
      // filter categories ends

      // conference uuid start
      const conferenceUuid = this.helperService.getQueryParamValues(urlParams, 'conference-uuid', 'conference_uuid') as string;
      conferenceUuid && (this.conferenceUuid = conferenceUuid.trim());
      // conference uuid ends

      // Customer starts
      const tempCustomerData: CustomerPrefillDataModel = this.helperService.getQueryStringKey('customer', urlQueryStringValue, false);
      this.selectedJsonCustomerDetail = tempCustomerData ? tempCustomerData : undefined;
      if (this.selectedJsonCustomerDetail?.email) {
        this.selectedJsonCustomerDetail.email = this.helperService.fixEmailSpaceIssue(this.selectedJsonCustomerDetail.email);
      }
      // Customer ends

      // Custom field starts
      const temp1: { [key: number]: { type: string, value: any } | any } = this.helperService
        .getQueryStringKey('custom_field', urlQueryStringValue, false);
      const temp2: { [key: number]: { type: string, value: any } | any } = this.helperService
        .getQueryStringKey('custom-field', urlQueryStringValue, false);
      if (temp1) {
        this.selectedJsonCustomFields = temp1;
      } else if (temp2) {
        this.selectedJsonCustomFields = temp2;
      } else {
        this.selectedJsonCustomFields = undefined;
      }
      // Custom field ends
    }

    if (base64Params) {
      this.bookingName = base64Params['partner'] ? base64Params['partner'] : this.bookingName;
      this.bookingType = base64Params['type'] ? base64Params['type'] : this.bookingType;
      base64Params['debug'] === "true" && (this.debug = base64Params['debug']);
      this.isCompactMode = (this.widgetConstants.COMPACT === this.bookingType);
      this._lang = base64Params['language'] ? base64Params['language'] : base64Params['lang'] ? base64Params['lang'] : this._lang;
      this.template = base64Params['template'] ? base64Params['template'] : this.template;
      this.token = base64Params['token'] ? base64Params['token'] : this.token;
      this.calendarPreselectedDate = base64Params['date'] ? base64Params['date'] : this.calendarPreselectedDate;
      this.langSwitcher = ['false', false].includes(this.helperService.getQueryParamValues(urlParams, 'lang-switcher', 'lang_switcher')) ? false : this.langSwitcher;
      this.isPreview = this.helperService.getQueryParamValues(base64Params, 'is-preview', 'is_preview') ? true : this.isPreview;

      this.uiMode = this.helperService.getQueryParamValues(base64Params, 'ui-mode', 'ui_mode');
      this.hideGridSwitcher = this.helperService.getQueryParamValues(base64Params, 'hide-grid-switcher', 'hide_grid_switcher');
      this.hideStoreSelection = this.helperService.getQueryParamValues(base64Params, 'hide-store', 'hide_store') === 'true' ? true : false;
      this.skipQualificationQuestion = this.helperService.getQueryParamValues(base64Params, 'skip-qualification-question', 'skip_qualification_question') === 'true' ? true : false;
      this.isLoggingEnabled = this.helperService.getQueryParamValues(base64Params, 'enable_logs', 'enable-logs') ? true : this.isLoggingEnabled;
      this.isBookingDisabled = this.helperService.getQueryParamValues(base64Params, 'disable_bookings', 'disable-bookings') ? true : this.isBookingDisabled;
      this.disableLocalStorage = this.helperService.getQueryParamValues(base64Params, 'disable_localstorage', 'disable-localstorage') ? 1 : this.disableLocalStorage;

      // Date context starts
      const dateContext: string = this.helperService.getQueryParamValues(base64Params, 'date_context', 'date-context');
      this.dateContext = dateContext ? dateContext : this.dateContext;
      // Date context ends

      // Date context start date starts
      const dateContextStart: string = this.helperService.getQueryParamValues(base64Params, 'date_context_start', 'date-context-start');
      this.dateContextStart = dateContextStart ? dateContextStart : this.dateContextStart;
      // Date context start date ends

      // Date context end date starts
      const dateContextEnd: string = this.helperService.getQueryParamValues(base64Params, 'date_context_end', 'date-context-end');
      this.dateContextEnd = dateContextEnd ? dateContextEnd : this.dateContextEnd;
      // Date context end date ends

      // worker uuid starts
      const workerUuid: string = this.helperService.getQueryParamValues(base64Params, 'worker-uuid', 'worker_uuid');
      this.workerUuid = workerUuid ? workerUuid : this.workerUuid;
      // worker uuid ends

      // meeting type starts
      const meeting_type_id: string = this.helperService.getQueryParamValues(base64Params, 'meeting-type-id', 'meeting_type_id');
      this.meeting_type_id = meeting_type_id ? meeting_type_id : this.meeting_type_id;
      // meeting type ends

      // customer notification preference starts
      const customer_notification_preference: string = this.helperService.getQueryParamValues(base64Params, 'customer-notification-preference', 'customer_notification_preference');
      this.customer_notification_preference
        = customer_notification_preference ? customer_notification_preference : this.customer_notification_preference;
      // customer notification preference ends

      // utm source starts
      const utmSource: string = this.helperService.getQueryParamValues(base64Params, 'utm_source', 'utm-source');
      this.utmSource = utmSource ? utmSource : this.utmSource;
      // utm source ends

      // utm medium starts
      const utmMedium: string = this.helperService.getQueryParamValues(base64Params, 'utm_medium', 'utm-medium');
      this.utmMedium = utmMedium ? utmMedium : this.utmMedium;
      // utm medium ends

      // utm content starts
      const utmContent: string = this.helperService.getQueryParamValues(base64Params, 'utm_content', 'utm-content');
      this.utmContent = utmContent ? utmContent : this.utmContent;
      // utm content ends

      // utm campaign starts
      const utmCampaign: string = this.helperService.getQueryParamValues(base64Params, 'utm_campaign', 'utm-campaign');
      this.utmCampaign = utmCampaign ? utmCampaign : this.utmCampaign;
      // utm campaign starts

      // utm term starts
      const utmTerm: string = this.helperService.getQueryParamValues(base64Params, 'utm_term', 'utm-term');
      this.utmTerm = utmTerm ? utmTerm : this.utmTerm;
      // utm term ends

      // booker worker uuid starts
      const bookerWorkerId: number = this.helperService.getQueryParamValues(base64Params, 'booker-worker-id', 'booker_worker_id');
      this.bookerWorkerId = bookerWorkerId ? bookerWorkerId : this.bookerWorkerId;
      // booker worker uuid ends

      // whitelabel starts
      const isWhiteLabeled: string = this.helperService.getQueryParamValues(base64Params, 'is-whitelabel', 'is_whitelabel');
      this.isWhiteLabeled = isWhiteLabeled ? isWhiteLabeled : this.isWhiteLabeled;
      // whitelabel ends

      // internal starts
      this.internal = base64Params['internal'] ? base64Params['internal'] : this.internal;
      // internal ends

      // event id starts
      const eventId: string = this.helperService.getQueryParamValues(base64Params, 'event-id', 'event_id');
      this.eventId = eventId ? eventId : this.eventId;
      // event id ends

      // store id starts
      const selectedStoreId: number = this.helperService.getQueryParamValues(base64Params, 'store_id', 'store-id');
      this.selectedStoreId = selectedStoreId ? selectedStoreId : this.selectedStoreId;
      // store id ends

      // store zip code starts
      const selectedStoreZip: number = this.helperService.getQueryParamValues(base64Params, 'store-zip', 'store_zip');
      this.selectedStoreZipCode = selectedStoreZip ? selectedStoreZip : this.selectedStoreZipCode;
      // store zip code ends

      // store name starts
      const selectedStoreName: string = this.helperService.getQueryParamValues(base64Params, 'store_name', 'store-name');
      this.selectedStoreName = selectedStoreName ? selectedStoreName : this.selectedStoreName;
      // store name ends

      // worker id starts
      let selectedWorkerId: number = this.helperService.getQueryParamValues(base64Params, 'worker_id', 'worker-id');
      selectedWorkerId = selectedWorkerId ? Number(selectedWorkerId) : undefined;
      this.selectedWorkerId = selectedWorkerId ? selectedWorkerId : this.selectedWorkerId;
      // worker id ends

      // worker email starts
      let selectedWorkerEmail: string = this.helperService.getQueryParamValues(base64Params, 'worker_email', 'worker-email');
      selectedWorkerEmail = selectedWorkerEmail ? selectedWorkerEmail : undefined;
      this.selectedWorkerEmail = selectedWorkerEmail ? selectedWorkerEmail.toLowerCase() : this.selectedWorkerEmail;
      // worker email ends

      // Appointment services start
      const appointmentServiceIds: any[] = this.helperService.getQueryStringKey('service', this.decodedBase64String, true);
      this.selectedAppointmentServiceIds = appointmentServiceIds ? appointmentServiceIds : this.selectedAppointmentServiceIds;
      // Appointment services ends

      // Filter stores start
      const filterStoresIds = this.helperService.getQueryParamValues(base64Params, 'filter-stores[]', 'filter_stores[]') as string;
      if (filterStoresIds) {
        this.filterStores = filterStoresIds.split(',');
      }
      // Filter stores ends

      // Filter categories start
      const filterCategoriesIds = this.helperService.getQueryParamValues(base64Params, 'filter-categories[]', 'filter_categories[]') as string;
      if (filterCategoriesIds) {
        this.filterCategories = filterCategoriesIds.split(',');
      }
      // Filter categories ends

      // Filter categories start
      const conferenceUuid = this.helperService.getQueryParamValues(base64Params, 'conference-uuid', 'conference_uuid') as string;
      if (conferenceUuid) {
        this.conferenceUuid = conferenceUuid.trim();
      }
      // Filter categories ends

      // internal comment
      const internalComment = this.helperService.getQueryParamValues(base64Params, 'internal_comment', 'internal-comment');
      this.internalComment = internalComment ? decodeURIComponent(internalComment) : this.internalComment;
      // internal comment

      // Appointment service categories start
      const appointmentServiceCategoryIds: any[] = this.helperService.getQueryStringKey('category', this.decodedBase64String, true);
      this.selectedAppointmentServiceCategoryIds = appointmentServiceCategoryIds && appointmentServiceCategoryIds[0]
        ? (<string>appointmentServiceCategoryIds[0]).split(',')
        : this.selectedAppointmentServiceCategoryIds;
      // Appointment service categories ends

      // Customer starts
      const tempCustomerData: CustomerPrefillDataModel = this.helperService.getQueryStringKey('customer', this.decodedBase64String, false);
      this.selectedJsonCustomerDetail = tempCustomerData ? tempCustomerData : this.selectedJsonCustomerDetail;
      if (this.selectedJsonCustomerDetail?.email) {
        this.selectedJsonCustomerDetail.email = this.helperService.fixEmailSpaceIssue(this.selectedJsonCustomerDetail.email);
      }
      // Customer ends

      // Custom field starts
      const temp1: { [key: number]: { type: string, value: any } | any } = this.helperService
        .getQueryStringKey('custom_field', this.decodedBase64String, false);
      const temp2: { [key: number]: { type: string, value: any } | any } = this.helperService
        .getQueryStringKey('custom-field', this.decodedBase64String, false);
      if (temp1) {
        this.selectedJsonCustomFields = temp1;
      } else if (temp2) {
        this.selectedJsonCustomFields = temp2;
      }
      // Custom field ends
    }

    if (isNaN(Number(this.selectedStoreId))) {
      LoggerService.warn('[Debug] Invalid store id');
      this.selectedStoreId = undefined;
    }

    if (isNaN(Number(this.selectedStoreZipCode))) {
      LoggerService.warn('[Debug] Invalid store zip');
      this.selectedStoreZipCode = undefined;
    }

    if (this.selectedAppointmentServiceIds?.length > 0 && this.selectedAppointmentServiceIds !== 'NULL') {
      this.selectedAppointmentServiceIds = (this.selectedAppointmentServiceIds as string[]).filter((item: string) => {
        return Number(item);
      });
      if (this.selectedAppointmentServiceIds?.length > 0) {
        // Don't do anything as of now
      } else {
        this.selectedAppointmentServiceIds = undefined;
      }
    } else {
      this.selectedAppointmentServiceIds = undefined;
    }

    if (this.selectedAppointmentServiceCategoryIds?.length > 0 && this.selectedAppointmentServiceCategoryIds !== 'NULL') {
      this.selectedAppointmentServiceCategoryIds = (this.selectedAppointmentServiceCategoryIds as string[]).filter((item: string) => {
        return (Number(item));
      });
      if (this.selectedAppointmentServiceCategoryIds?.length > 0) {
        // Don't do anything as of now
      } else {
        this.selectedAppointmentServiceCategoryIds = undefined;
      }
    } else {
      this.selectedAppointmentServiceCategoryIds = undefined;
    }

    this.dateContext = this.helperService.isEmptyString(this.dateContext) ? undefined : this.dateContext;
    this.dateContextStart = this.helperService.isEmptyString(this.dateContextStart) ? undefined : this.dateContextStart;
    this.dateContextEnd = this.helperService.isEmptyString(this.dateContextEnd) ? undefined : this.dateContextEnd;
    this.calendarPreselectedDate = this.helperService.isEmptyString(this.calendarPreselectedDate) ? undefined : this.calendarPreselectedDate;

    // Listen date context enforcecment event based event
    this.customEventService.updateWidgetDateContext
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(dateContext => {
        LoggerService.warn("[debug][date_context_enforcement] Apply date context based on selected date");
        this.dateContext = dateContext.date;
        this.dateContextStart = dateContext.date_context_start.toString();
        this.dateContextEnd = dateContext.date_context_end.toString();
      });

    LoggerService.log('[Attribute] bookingName ', this.bookingName);
    LoggerService.log('[Attribute] bookingType ', this.bookingType);
    LoggerService.log('[Attribute] lang ', this._lang);
    LoggerService.log('[Attribute] workerUuid ', this.workerUuid);
    LoggerService.log('[Attribute] meeting_type_id ', this.meeting_type_id);
    LoggerService.log('[Attribute] customer_notification_preference ', this.customer_notification_preference);
    LoggerService.log('[Attribute] isWhiteLabeled ', this.isWhiteLabeled);
    LoggerService.log('[Attribute] internal ', this.internal);
    LoggerService.log('[Attribute] eventId ', this.eventId);
    LoggerService.log('[Attribute] selectedStoreId ', this.selectedStoreId);
    LoggerService.log('[Attribute] selectedStoreZipCode ', this.selectedStoreZipCode);
    LoggerService.log('[Attribute] selectedAppointmentServiceIds ', this.selectedAppointmentServiceIds);
    LoggerService.log('[Attribute] selectedWorkerId ', this.selectedWorkerId);
    LoggerService.log('[Attribute] selectedWorkerEmail ', this.selectedWorkerEmail);
    LoggerService.log('[Attribute] selectedJsonCustomFields ', this.selectedJsonCustomFields);
    LoggerService.log('[Attribute] selectedJsonCustomerDetail ', this.selectedJsonCustomerDetail);
    LoggerService.log('[Attribute] bookerWorkerId ', this.bookerWorkerId);
    LoggerService.log('[Attribute] utmSource ', this.utmSource);
    LoggerService.log('[Attribute] isLoggingEnabled ', this.isLoggingEnabled);
    LoggerService.log('[Attribute] isBookingDisabled ', this.isBookingDisabled);
    LoggerService.log('[Attribute] disableLocalStorage ', this.disableLocalStorage);
    LoggerService.log('[Attribute] template ', this.template);
    LoggerService.log('[Attribute] token ', this.token);
    LoggerService.log('[Attribute] internalComment', this.internalComment);
    LoggerService.log('[Attribute] date', this.calendarPreselectedDate);
    LoggerService.log('[Attribute] dateContext', this.dateContext);
    LoggerService.log('[Attribute] dateContextStart', this.dateContextStart);
    LoggerService.log('[Attribute] dateContextEnd', this.dateContextEnd);
    LoggerService.log('[Attribute] isPreview', this.isPreview);
    LoggerService.log('[Attribute] langSwitcher', this.langSwitcher);
    LoggerService.log('[Attribute] filterStores', this.filterStores);
    LoggerService.log('[Attribute] filterCategories', this.filterCategories);
    LoggerService.log('[Attribute] conferenceUuid', this.conferenceUuid);
    LoggerService.log('[Attribute] debug', this.debug);
    LoggerService.log('[Attribute] skip-qualification-question', this.skipQualificationQuestion);

    // DEV alert
    if (environment.baseUrl.includes('dev')) {
      this.showDevAlert = true;
    }

    // Show localstorage not accessable warning
    this.localStorageAccessible = this.helperService.storageAvailable('localStorage');

    // internal check to show customer selection in the personal-data-component
    if (this.internal === 'true' || this.debug === 'true') {
      this.internal === 'true' && (this.isInternal = true);

      if (!this.token || this.token === null || this.token === 'null') {
        this.setLanguage(this._lang, null);
        this.tokenError = true;
        this.tokenErrorMessage = 'common.verification_token_missing';
        return;
      } else {
        this.partnerService.partnerVerificationCode = this.token;
      }
    } else {
      this.debug = 'false';
      this.isInternal = false;
      this.debug = 'false';
      this.tokenError = false;
    }

    // detect if reference to calenso should be shown in footer
    this.whiteLabelBoolean = (this.isWhiteLabeled === 'true');

    if (Number(this.disableLocalStorage) === 1) {
      this.localStorageService.disableLocalStorage = true;
      this.localStorageService.clear(() => this.initWidgetConf());
    } else {
      this.initWidgetConf();
    }
  }

  initWidgetConf(): void {
    this.setWidgetConfData();
  }

  setWidgetConfData() {
    if (!this.bookingName) {
      return;
    }

    const widgetConfBody: IPartnerInformationBody = {
      booking_name: this.bookingName,
      token_type: TokenType.publicWidget
    };

    if (this.selectedWorkerId !== undefined) {
      widgetConfBody.worker_id = this.selectedWorkerId;
    } else if (this.selectedWorkerEmail !== undefined) {
      widgetConfBody.worker_email = this.selectedWorkerEmail;
    }

    // When widget is internal pass token and internal along with existing params
    // in partner information call so BE can generate new auth token
    if (this.internal === 'true' || this.debug === 'true') {
      widgetConfBody.token = this.token;

      if (this.internal === 'true') {
        widgetConfBody.internal = true;
        widgetConfBody.token_type = TokenType.internalWidget
      } else if (this.debug === 'true') {
        widgetConfBody.debug = true;
      }
    }

    return this.partnerService.getWidgetConf(widgetConfBody).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(partner => {
        const observables: {
          partner: Observable<PartnerDao>,
          customCss: Observable<CssOverwrites>,
          conference: Observable<ConferenceDbModel>
        } = {
          partner: of(null),
          customCss: of(null),
          conference: of(null)
        };

        if (!partner) {
          return forkJoin(observables);
        }

        // Set partner
        observables.partner = of(partner);
        this.partnerService.partnerUUID = partner.uuid;

        if (Number(partner?.bookingwidget_enabled) === 0) {
          this.bookingWidgetMasterSettingsEnabled = false;
          LoggerService.warn('[WARNING] The booking widget is temporary disabled. Please contact the Calenso support');
        }
        if (!partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.EXTERNAL_APPOINTMENT)) {
          this.appointmentExternalBookingEnabled = false;
          LoggerService.warn('[WARNING] Appointment booking is not enabled.');
        }
        if (!partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.INTERNAL_APPOINTMENT)) {
          this.appointmentInternalBookingEnabled = false;
          LoggerService.warn('[WARNING] Internal Appointment booking is not enabled.');
        }
        if (!partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.EXTERNAL_GROUP_APPOINTMENT)) {
          this.eventExternalBookingEnabled = false;
          LoggerService.warn('[WARNING] Event booking is not enabled.');
        }

        if (partner?.is_widget_password_protection_setting_enabled === 1) {
          this.isWidgetPasswordProtected = true;
        }

        // If partner subscription is > whitelabel check for custom css is active or passed
        if (
          this.isPreview ||
          (partner?.subscription?.subscription_type_id >= 100 && Number(partner?.is_custom_css_widget_enabled) === 1)
        ) {
          observables.customCss = this.template
            ? this.partnerService.getCssOverwriteUsingUuid(this.template, this.isPreview)
            : this.partnerService.getCssOverwrites();
        }

        // When confernece uuid is passed grab conference details
        if (this.conferenceUuid !== '' && this.conferenceUuid !== undefined) {
          observables.conference = this.conferenceService.getConferenceDetails(this.conferenceUuid).pipe(
            switchMap(conference => {
              this.appointmentService.appointment_services_uuids = conference.appointment_services?.map(conf => conf.uuid);
              if (conference?.partner_id !== partner.id) {
                this.errorInConference = 'common.conference_not_belonging';
                LoggerService.warn('[Conference] The provided conference UUID belongs to a different partner.');
                return of(null);
              }

              if (conference?.uuid !== undefined && conference?.is_expired === false && ((!conference?.is_permanent && conference?.is_booked === false) || conference?.is_permanent)) {
                this.conferenceState.uuid = conference.uuid;
                this.appointmentService.appointment_services_uuids = conference.appointment_services?.map(conf => conf.uuid);

                // Set worker id
                if (conference?.worker_id) {
                  this.conferenceState.worker_id = conference.worker_id;
                }

                // Set store id
                if (conference?.worker) {
                  this.selectedStoreId = conference.worker.store_id;
                  this.conferenceState.store_id = conference.worker.store_id;
                }

                // Set appointment service ids
                if (conference?.appointment_services) {
                  conference.appointment_service_ids = conference.appointment_services?.map(service => service.id);
                  const mappedServiceIdsObj: MappedAppointmentServicesDao = {
                    0: {
                      serviceIds: conference.appointment_service_ids,
                      is_multiple_select: false
                    }
                  };
                  this.appointmentService.mappedServiceIds = mappedServiceIdsObj;
                  this.conferenceState.appointment_service_ids = conference.appointment_service_ids;
                }

                if (conference.booking_links_workers?.length) {
                  this.conferenceState.worker_ids = [];
                  this.conferenceState.worker_uuids = [];
                  conference.booking_links_workers.forEach(worker => {
                    this.conferenceState.worker_ids.push(Number(worker.worker_id));
                    this.conferenceState.worker_uuids.push(worker.worker.uuid);
                  });
                  this.workerUuids = this.conferenceState.worker_uuids;
                }
              } else {
                if (!conference?.uuid) {
                  this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.INVALID);
                } else if (conference?.is_booked) {
                  this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.BOOKED);
                } else if (conference?.is_expired) {
                  this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.EXPIRED);
                } else {
                  this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.INVALID);
                }

                return of(null);
              }

              return of(conference);
            }),
            catchError((error: HttpErrorResponse) => {
              LoggerService.error(error);
              if (error.status === CONFERENCE_ERROR_CODES.BOOKED) {
                this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.BOOKED);
              } else if (error.status === CONFERENCE_ERROR_CODES.NOT_BELONGING) {
                this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.NOT_BELONGING);
              } else if (error.status === CONFERENCE_ERROR_CODES.EXPIRED) {
                this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.EXPIRED);
              } else if (error.status === CONFERENCE_ERROR_CODES.INVALID) {
                this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.INVALID);
              } else {
                this.errorInConference = this.conferenceErrorHandler(CONFERENCE_ERROR_STATE.INVALID);
              }

              return of(null);
            }),
          );
        }

        return forkJoin(observables);
      })
    ).subscribe({
      next: partnerConfigs => {
        this.enableLangSetter = true;
        this.setLanguage(this._lang, (partnerConfigs.partner?.id ? partnerConfigs.partner : null));

        // Handle token verification errors
        if (partnerConfigs.partner?.errors) {
          this.tokenError = true;
          if (partnerConfigs.partner?.errors[0].code === HttpStatusCode.Forbidden) {
            this.tokenErrorMessage = 'common.invalid_verification_code'
          } else if (partnerConfigs.partner?.errors[0].code === HttpStatusCode.Unauthorized) {
            this.tokenErrorMessage = 'common.verification_token_missing'
          } else {
            this.showSubscriptionError = true;
          }

          return;
        } else {
          this.tokenError = false;
        }

        if (!partnerConfigs.partner?.subscription) {
          this.showSubscriptionError = true;
          return;
        }

        if (!partnerConfigs.partner?.has_bookable_workers) {
          this.showWorkersNotAvailableError = true;
        }

        // Handle custom css
        this.setCssProperties(partnerConfigs.partner, partnerConfigs.customCss);

        if (this.selectedWorkerId || this.selectedWorkerEmail) {
          if (partnerConfigs.partner.worker) {
            this.foundedWorker = partnerConfigs.partner.worker;
            this.selectedWorkerId = this.foundedWorker.id;
            LoggerService.log('[Debug] Setting hide_resource_step to 1 because worker was preselected.');
            partnerConfigs.partner.hide_resource_step = 1;
            if (!this.selectedStoreId && !this.selectedStoreName) {
              this.selectedStoreId = this.foundedWorker.store_id;
            } else if (this.selectedStoreId) {
              if (this.selectedStoreId !== this.foundedWorker.store_id) {
                LoggerService.warn('[Debug] Store Id and Worker store id is not matching. Resetting store id to worker store id.');
                this.selectedStoreId = this.foundedWorker.store_id;
              }
            }
            LoggerService.log('[Debug] Selected Store ID after foundedWorker', this.selectedStoreId);
          } else {
            if (Number(this.selectedWorkerId) === 0) {
              LoggerService.warn('[Debug] Selected worker ID is 0. Setting any worker and setting hide_resource_step to 1.');
              this.selectedWorkerId = 0;
              partnerConfigs.partner.hide_resource_step = 1;
            } else {
              LoggerService.warn('[Debug] Selected worker ID cannot be found in the list of workers. Ignoring the selected worker ID and setting hide_resource_step to 0.');
              this.selectedWorkerId = undefined;
              partnerConfigs.partner.hide_resource_step = 0;
            }
          }
        }

        this.googleAnalyticsService.partnerAnalyticsObj = partnerConfigs.partner.analytics;
        this.googleAnalyticsService.partnerSubscriptionPlan = partnerConfigs.partner.subscription;
        this.analytics = partnerConfigs.partner.analytics;
        if (partnerConfigs.partner?.adobe_launch_tracking_url && partnerConfigs.partner?.adobe_launch_tracking_url?.trim() !== '') {
          this.loadAdobeAnalytics(partnerConfigs.partner.adobe_launch_tracking_url);
        }
        this.widgetBookingInfo = partnerConfigs.partner.widget_booking_info;

        // Manually setting partner configs to do not allow book multiple appointments
        // while booking conference
        if (this.conferenceState.uuid !== undefined) {
          partnerConfigs.partner.is_cart_supported = 0;
          partnerConfigs.partner.hide_widget_cart_functionality_message = 0;
        }

        this.widgetConf = WidgetConf.createWidgetConfObject(partnerConfigs.partner);

        this.widgetUtilService.setWidgetConf(this.widgetConf);
        this.customizeAppearance();

        this.bookTypesEnabled = this.widgetUtilService.getBookingTypes(this.bookingType, this.widgetConf);
        this.currentBookingType = this.bookTypesEnabled[0];

        if (this.currentBookingType === this.widgetConstants.APPOINTMENT) {
          this.modifyAppointmentLocalStorageData(partnerConfigs.partner.booking_name, partnerConfigs.partner);
        } else if (this.currentBookingType === this.widgetConstants.EVENT) {
          this.modifyEventLocalStorageData(partnerConfigs.partner.booking_name, partnerConfigs.partner);
        } else {
          this.modifyAppointmentLocalStorageData(partnerConfigs.partner.booking_name, partnerConfigs.partner);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.enableLangSetter = true;
        this.setLanguage(this._lang, null);
        LoggerService.error(error);
      }
    });
  }

  setCssProperties(partner: PartnerDao, customCss: CssOverwrites): void {
    if (this.template) {
      if (customCss === null) {
        LoggerService.warn('[css-overwrite] Invalid css-overwrite', this.template);
      } else if (!this.isPreview && partner?.id !== customCss?.partner_id) {
        LoggerService.warn('[css-overwrite] Partner id is not matching for apply pre-define css-overwrite');
      } else if (customCss?.type !== 'widget') {
        LoggerService.error('[css-overwrite] Invalid css-overwrite type', customCss?.type);
      } else {
        LoggerService.log('[css-overwrite] Applying css-overwrite provided in url parameter', customCss?.uuid);
        this.applyCustomCss(customCss);
      }
    } else {
      if (customCss?.is_active) {
        LoggerService.log('[css-overwrite] Applying activated css-overwrite manually', customCss?.uuid);
        this.applyCustomCss(customCss);
      }
    }
  }

  applyCustomCss(css: CssOverwrites): void {
    this.cssOverwrites = css.code;
    const style = document.createElement('style');
    style.setAttribute('scoped', '');
    style.innerHTML = this.cssOverwrites;
    document.body.append(style);
  }

  private conferenceErrorHandler(state: string): string {
    if (state === CONFERENCE_ERROR_STATE.BOOKED) {
      return 'common.conference_booked';
    } else if (state === CONFERENCE_ERROR_STATE.NOT_BELONGING) {
      return 'common.conference_not_belonging';
    } else if (state === CONFERENCE_ERROR_STATE.EXPIRED) {
      return 'common.conference_expired_link';
    } else if (state === CONFERENCE_ERROR_STATE.INVALID) {
      return 'common.conference_invalid_link';
    } else {
      return 'common.conference_invalid_link';
    }
  }

  switchForm(bType: string): void {
    this.currentBookingType = bType;
  }

  /**
   * Customizes brackground color of the widget based on partner settings
   */
  customizeAppearance(): void {
    if (this.widgetUtilService.getWidgetConf()?.backgroundColor && this.calioSectionRef) {
      this.calioSectionRef.nativeElement.style.backgroundColor = this.widgetUtilService.getWidgetConf()?.backgroundColor;
    }
  }

  modifyEventLocalStorageData(bookingName: string, partner: PartnerDao): void {
    this.localStorageService.getItem(EVENT_CONSTANT.EVENT_GLOBAL_USER_LS + bookingName, (savedUserState: UserState) => {
      this.localStorageService.getItem(EVENT_CONSTANT.EVENT_CUSTOM_FIELDS_LS + bookingName, (savedCustomFieldsState) => {
        this.localStorageService.clear(() => {
          if (savedUserState) {
            this.utilService.updateLocalStorage(EVENT_CONSTANT.EVENT_GLOBAL_USER_LS + bookingName, savedUserState);
          }
          if (savedCustomFieldsState) {
            this.utilService.updateLocalStorage(EVENT_CONSTANT.EVENT_CUSTOM_FIELDS_LS + bookingName, savedCustomFieldsState);
          }
          this.setUpWidgetData(partner);
        });
      });
    });
  }

  modifyAppointmentLocalStorageData(bookingName: string, partner: PartnerDao): void {
    this.localStorageService.getItem(APPOINTMENT_CONSTANT.APPOINTMENT_GLOBAL_USER_LS + bookingName, (savedUserState: UserState) => {
      this.localStorageService.getItem(APPOINTMENT_CONSTANT.APPOINTMENT_CUSTOMFIELDS_LS_NAME + bookingName, (savedCustomFieldsState) => {
        this.localStorageService.clear(() => {
          if (savedUserState) {
            this.utilService.updateLocalStorage(APPOINTMENT_CONSTANT.APPOINTMENT_GLOBAL_USER_LS + bookingName, savedUserState);
          }
          if (savedCustomFieldsState) {
            this.utilService.updateLocalStorage(APPOINTMENT_CONSTANT.APPOINTMENT_CUSTOMFIELDS_LS_NAME + bookingName, savedCustomFieldsState);
          }
          this.setUpWidgetData(partner);
        });
      });
    });
  }

  async setUpWidgetData(partner: PartnerDao) {
    this.globals = this.widgetUtilService.createGlobalObjectData(
      this.widgetConf,
      this.eventId,
      this.isInternal,
      this.whiteLabelBoolean,
      this._lang,
      (this.debug === 'true'),
      (this.debug === 'true') ? this.token : undefined
    );

    this.translateService.use(this._lang);

    // Set partner data object to be passed to all child components
    this.partnerGlobalData = this.widgetUtilService.createPartnerGlobalData(this.widgetConf, this._lang, partner);
    this.widgetColorConf = this.widgetUtilService.createWidgetColorConf(partner);

    if (this.globals.isInternal && !this.tokenError && this.appointmentInternalBookingEnabled) {
      this.partnerService.getCustomers()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: customers => this.globals.customers = customers,
          error: (error: HttpErrorResponse) => LoggerService.error(error)
        });
    }
  }

  onBookingSuccessfull(): void {
    this.calioSectionRef && this.calioSectionRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onMaintenancePageButton(): void {
    window.open('https://status.calenso.com', '_blank');
  }

  loadAdobeAnalytics(adobe_launch_tracking_url: string) {
    try {
      const script = document.createElement('script');
      script.src = adobe_launch_tracking_url;
      script.async = true;
      document.head.appendChild(script);
    } catch (ex) {
      LoggerService.warn('Error appending google analytics');
      LoggerService.error(ex);
    }
  }

  isWidgetPasswordVerified(): void {
    this.isWidgetPasswordProtected = false;
  }
}
