@if (appointmentState.date && showAppointmentSlots) {
  @if (noAppointments) {
    <app-alert text="{{ noFreeAppointments | translation: templateContent.TEMPLATE_CONTENT: lang }}" type="warning" />
  }

  @if (!noAppointments) {
    <form id="checkboxForm" name="appCheckboxForm" #appCheckboxForm="ngForm">
      @if (hasGroups) {
        <mat-tab-group [selectedIndex]="0" [dynamicHeight]="true" [disableRipple]="true" class="slots-group">
          @for (appointmentsGroup of freeAppointments; track appointmentsGroup; let index = $index) {
            @if (appointmentsGroup.widgetGroup) {
              <mat-tab>
                <ng-template mat-tab-label>
                  {{ appointmentsGroup.widgetGroup | translation: 'name' : lang }}
                  @if (appointmentsGroup.selectedSlots > 0) {
                    <span class="badge tabs-slot-counter ms-2">
                      {{ appointmentsGroup.selectedSlots }}
                    </span>
                  }
                </ng-template>

                <div class="row">
                  <ng-container *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, index: index }" />
                </div>
              </mat-tab>
            }
          }
        </mat-tab-group>
      } @else {
        @for (appointmentsGroup of freeAppointments; track appointmentsGroup; let index = $index) {
          <div class="row">
            <ng-container *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, index: index }" />
          </div>
        }
      }
    </form>
  }

  <ng-template #slotsBlock let-appointmentsGroup let-index="index">
    @for (appointment of appointmentsGroup.appointments; track appointment; let appointmentindex = $index) {
      @if (appointmentsGroup?.loadMore === true || appointmentindex < availableSlotsCount) {
        <div
          [ngClass]="{
            'slot-box-group': hasGroups,
            'col-xs-12 col-sm-6': !isTroubleshootEnabled,
            'col-12': isTroubleshootEnabled,
          }"
        >
          <div
            class='cbw-appointment-card {{ appointment.is_bookable }}'
            [ngClass]="{
              'cal-debug': isTroubleshootEnabled,
              'cbw-appointment-card-disabled': terminConflict(appointment),
              'cbw-selected-appointment-card': isTerminInCart(createCartItemId(appointment?.worker?.id, appointmentState.date, appointment.short_start)),
              'cbw-appointment-reserved': appointment?.isReserved,
              'debug-slot-available': isTroubleshootEnabled && appointment.is_bookable,
              'debug-slot-unavailable': isTroubleshootEnabled && !appointment.is_bookable
            }"
            (click)="updateCartManually(appointment.short_start + '_' + appointment?.worker?.id)"
          >
            <div
              class="cbw-check-box-container w-100"
              (click)="updateCartManually(appointment.short_start + '_' + appointment?.worker?.id); $event.stopPropagation();"
            >
              <app-cw-checkbox-radio-button
                class="w-100"
                customId="{{ appointment.short_start + '_' + appointment?.worker?.id }}"
                name="{{ 'freeAppointments' + appointment.short_start }}"
                value="{{ appointment.short_start }}"
                containerClass="justify-content-center"
                labelClass="w-100 justify-content-center"
                [checked]="isTerminInCart(createCartItemId(appointment?.worker?.id, appointmentState.date, appointment.short_start))"
                [isCheckbox]="true"
                [isDisabled]="appointment?.isReserved || terminConflict(appointment)"
                (selectionChange)="updateCart($event.event, appointment)"
                (click)="$event.stopPropagation()"
              >
                <div class="d-flex flex-column justify-content-center align-items-start">
                  @if (appointmentState.worker === 0 && partner.anyone_feature_hide_ressource === 0) {
                    <span class="cbw-worker-name text-center w-100">
                      @if (appointment?.worker?.is_resource === 1) {
                        @if (appointment?.worker | translation: 'booking_label': lang) {
                          {{ appointment?.worker | translation: 'booking_label': lang }}
                        } @else {
                          {{ appointment?.worker | translation: 'resource_name': lang }}
                        }
                      } @else {
                        {{ appointment?.worker | translation: 'resource_name': lang }}
                      }
                    </span>
                  }

                  <span class="cbw-title text-center w-100">
                    <div>
                      {{ appointment.start | dateutc:timeFormat }} - {{ appointment.end | dateutc:timeFormat }}
                    </div>

                    @if (partner?.widget_show_location_on_appointment_proposal === 1 && appointment?.location) {
                      <div class="cbw-location location">
                        <div>
                          <img class="cbw-icon icon" src="{{ deployUrl + '/assets/images/location.svg' }}" />
                        </div>
                        <div>
                          {{ appointment?.location }}
                        </div>
                      </div>
                    }
                  </span>

                  @if (!isTroubleshootEnabled) {
                    @if (appointment?.isReserved) {
                      <div class="reserved-lable text-center w-100 cbw-reserved-lable">
                        {{ 'alert_messages.reserved_by_someone' | translate }}
                      </div>
                    } @else if (appointment?.worker?.attributes?.length === 1) {
                        @if (appointment?.worker?.attributes[0].type === 'select') {
                          <div class="attribute-container">
                            <div class="cbw-attribute">
                              {{ appointment?.worker?.attributes[0] | translation: 'name': lang }}:
                            </div>
                            <div class="attribute-value-section">
                              @for (value of appointment?.worker?.attributes[0].attributes_values; track value.uuid; let last = $last) {
                                <div class="attribute-value-img">
                                  @if (value.icon_url !== null) {
                                    <img
                                      #image
                                      class="cbw-attribute-icon"
                                      alt="{{ value.value }}"
                                      src="{{ value.icon_url }}"
                                      (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                                    />
                                  }
                                  <div class="cbw-attribute-value">
                                    {{ value | translation: 'value' : lang }}
                                    @if (!last) { | }
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        } @else {
                          <div class="cbw-attribute cbw-boolean-attribute">
                            {{ appointment?.worker?.attributes[0] | translation: 'name': lang }}:
                            @if (appointment?.worker?.attributes[0].attributes_values?.length) {
                              {{ +appointment?.worker?.attributes[0].attributes_values[0].value === 1 ? ( 'yes' | translate ): ( 'no' | translate ) }}
                            }
                          </div>
                        }
                    }
                  }
                </div>

              </app-cw-checkbox-radio-button>

              @if (appointment?.worker?.attributes?.length > 1) {
                <app-cw-attribute-tooptip
                  id="{{ appointment.worker.id }}"
                  class="me-1"
                  language="{{ lang }}"
                  icon="circle-info"
                  [attributes]="appointment.worker.attributes"
                />
              }

              @if (isTroubleshootEnabled) {
                <div
                  class="cbw-debug-expand-btn debug-expand-btn"
                  (click)="updateCartManually(appointment.short_start + '_' + appointment?.worker?.id); $event.stopPropagation();"
                >
                  <fa-icon class="icon" icon="{{ appointment.isExpanded ? 'chevron-up' : 'chevron-down' }}" />
                </div>
              } @else {
                @if (isTerminInCart(createCartItemId(appointment?.worker?.id, appointmentState.date, appointment.short_start))) {
                  <div class="next-arrow-container">
                    <span (click)="gotoNextPage(); $event.stopPropagation()">
                      <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-circle-right" />
                    </span>
                  </div>
                }
              }
            </div>

            <!-- Troubleshooting information -->
            @if (isTroubleshootEnabled && appointment?.isExpanded) {
              <div
                class="troubleshoot-info-wrapper cbw-troubleshoot-info-wrapper"
                (click)="$event.stopPropagation()"
              >
                <ul class="troubleshoot-notes-list">
                  @for (note of appointment.notes; track note) {
                    <li class="troubleshoot-note" [innerHtml]="note | trustHtml: 'html'"></li>
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      }
    }

    @if (
      (
        appointmentsGroup?.loadMore === false ||
        appointmentsGroup?.loadMore === undefined
      ) &&
      appointmentsGroup?.appointments?.length > availableSlotsCount
    ) {
      <div class="col-md-12 text-center load-more-button">
        <a class="load-more-link" href="javascript:void(0)" (click)="loadMore(index)">
          {{ 'load_more' | translate }}
        </a>
      </div>
    }
  </ng-template>
}
