import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-cw-checkbox-radio-button',
  templateUrl: './cw-checkbox-radio-button.component.html',
  styleUrls: ['./cw-checkbox-radio-button.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class CwCheckboxRadioButtonComponent {

  @Input() value: any;
  @Input() checked = false;
  @Input() customId: string;
  @Input() isCheckbox = false;
  @Input() isDisabled = false;
  @Input() isMobile = false;
  @Input() name: string;
  @Input() labelClass = '';
  @Input() containerClass = '';
  @Output() selectionChange = new EventEmitter<{ event: any, id: string }>();

  onChange(event: any, id: string): void {
    this.selectionChange.emit({ event, id });
  }
}
