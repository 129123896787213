import { EventEmitter, inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GTM_EVENTS } from '../constants/widget-constants';
import { CustomFieldsDao } from '../db-models/appointment-custom-fields-dao';
import { CartItem, EventCartItem, GuestModel } from '../models/cart.model';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class CustomEventService {

  private googleAnalyticsService = inject(GoogleAnalyticsService);

  currentPage: string;
  previousPage: string;
  finalSuccessMessageChangedEvent = new EventEmitter<string>();
  customerChangedInInternalWidgetEvent = new EventEmitter<{ customerUuid: string }>();
  paymentIntentEvent = new EventEmitter<{ secret: string }>();
  refreshPaymentIntentEvent = new EventEmitter<any>();
  disableCalendarNextButtonEvent = new EventEmitter<boolean>();
  resetPayPalOrderDetailEvent = new EventEmitter<any>();
  couponChangeEvent = new EventEmitter<any>();
  cartItemsChangeEvent = new EventEmitter<{
    cart?: CartItem[],
    eventCart?: EventCartItem[],
    type: string,
  }>();
  additionalGuestChangeEvent = new EventEmitter<{
    discount: number,
    finalPrice: number,
    additionalGuests: GuestModel[]
  }>();
  paypalOrderSuccessfullEvent = new EventEmitter<any>();
  hideBookingButtonEvent = new EventEmitter<{ hide: boolean }>();
  paymentGatewayErrorEvent = new EventEmitter<any>();
  childCustomFieldsLoadedEvent = new EventEmitter<CustomFieldsDao[]>();
  removeChildCustomFieldsEvent = new EventEmitter<number[]>();
  checkEndOfProcessEvent = new EventEmitter<any>();

  // services are loaded
  servicesLoadedEvent = new EventEmitter<void>();

  // to track next/previous month click on calendar
  eventCalendarIsNextPressed: boolean = undefined;
  eventCalendarIsPreviousPressed: boolean = undefined;

  // to handle iframe parent window navigation actions
  iFrameNavigationFromParent = new EventEmitter<string>();

  // Refresh calendar manually from different component
  refreshCalendarEvent = new EventEmitter<void>();

  // Identify troubleshooting mode is enable or not
  toggleTroubleshootEvent = new BehaviorSubject(false);

  gtmStartTriggered = false;

  // Update widget date context based on selected qualificatino question
  updateWidgetDateContext = new EventEmitter<{
    date: string;
    date_context_start: number;
    date_context_end: number;
  }>();

  triggerGroupApoointmentStartEvent(): void {
    if (this.gtmStartTriggered === false) {
      this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.EVENT_BOOKING_START);
      this.gtmStartTriggered = true;
    }
  }
}
