@if (showEventsListing) {
  <!-- Filter section start -->
  @if ((isGroupAppointmentStoreFeatureEnabled || isGroupAppointmentCategoryFeatureEnabled) && !_noEvents) {
    <div class="row">
      <div class="col-lg-9 col-md-12">
        @if (isGroupAppointmentStoreFeatureEnabled && eventStores?.length) {
          <div class="category-filter cbw-store-category-filter">
            <div class="filter-title">
              {{ 'events.step1.store_filter' | translate }}:
            </div>
            <button
              type="button"
              class="btn btn-light btn-category cbw-btn-store selected mt-2"
              [ngClass]="{
                selected: (selectedEventStores.length === 0)
              }"
              (click)="!isEventsLoading && onStoreSelect()"
            >
              {{ 'events.step1.all_categories' | translate }}
            </button>
            @for (eventStore of (eventStores | sortTranslationList: 'name' : lang); track eventStore) {
              <button
                type="button"
                class="btn btn-light btn-category cbw-btn-store mt-2"
                [ngClass]="{
                  selected: selectedEventStores.includes(eventStore?.uuid)
                }"
                (click)="!isEventsLoading && onStoreSelect(eventStore?.uuid)"
              >
                {{ eventStore | translation: 'name' : lang }}
              </button>
            }
          </div>
        }
      </div>
      @if (isEventsCategoriesLoading) {
        <div class="category-filter-skeleton skeleton-animation my-2">
          <div class="bar w-25 label"></div>
          <div class="d-flex mt-2">
            <div class="bar mt-2 categories-bar"></div>
            <div class="bar mt-2 categories-bar-1 ms-2"></div>
            <div class="bar mt-2 categories-bar ms-2"></div>
            <div class="bar mt-2 categories-bar ms-2"></div>
            <div class="bar mt-2 categories-bar-1 ms-2"></div>
          </div>
        </div>
      }
      <div class="col-lg-9 col-md-12">
        @if (isGroupAppointmentCategoryFeatureEnabled && eventCategories?.length > 0) {
          <div class="category-filter cbw-event-category-filter">
            <div class="filter-title">
              {{ 'events.step1.category_filter' | translate }}:
            </div>
            <button
              type="button"
              class="btn btn-light btn-category cbw-btn-category selected mt-2"
              [ngClass]="{
                selected: (selectedEventCategories.length === 0)
              }"
              (click)="!isEventsLoading && onCategorySelect()"
            >
              {{ 'events.step1.all_categories' | translate }}
            </button>
            @for (eventCategory of (eventCategories | sortTranslationList: 'name' : lang); track eventCategory) {
              <button
                type="button"
                class="btn btn-light btn-category cbw-btn-category mt-2"
                [ngClass]="{
                  selected: selectedEventCategories.includes(eventCategory.uuid)
                }"
                (click)="!isEventsLoading && onCategorySelect(eventCategory?.uuid)"
              >
                {{ eventCategory | translation: 'name' : lang }}
              </button>
            }
          </div>
        }
      </div>
      @if (hideGridSwitcher === 'false' || !hideGridSwitcher) {
        <div class="col-lg-3 col-md-12 text-end">
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-secondary subnav-button cbw-btn-list-grid-switcher"
              [ngClass]="{ 'selected-subnav-button': screenType=='LIST' }"
              (click)="toggleView('LIST')"
            >
              <fa-icon class="icon" icon="list" />
              <span class="ps-1">
                {{ 'events.list' | translate }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-secondary subnav-button cbw-btn-list-grid-switcher"
              [ngClass]="{ 'selected-subnav-button': screenType=='GRID' }"
              (click)="toggleView('GRID')"
            >
              <fa-icon class="icon" icon="th" />
              <span class="ps-1">
                {{ 'events.grid' | translate }}
              </span>
            </button>
          </div>
        </div>
      }
    </div>
  }
  <!-- Filter section ends -->

  <!-- Events section start -->
  @if (!isEventsLoading) {
    <form>
      @if (_events?.length) {
        <div>
          @if (screenType === 'GRID') {
            <div class="row">
              @for (event of _events; track event; let i = $index) {
                @if (event?.status === 1 && event?.is_secret !== 1) {
                  <div class="col-md-4 col-lg-3 col-sm-6">
                    <div class="card mb-3 cbw-card-border">
                      <img
                        #image
                        class="card-img-top grid-card-image"
                        [src]="event?.image ? eventImgBaseUrl + event.uuid + '/' + event.image : logoBaseUrl + _partner.uuid + '/' + _partner.logo"
                        alt="{{ event | translation: 'title': lang }}"
                        (error)="image.src=deployUrl + '/assets/images/' + eventImgFallback"
                      />
                      @if (_partner?.widget_show_free_slots_in_overview) {
                        @if (event.free_time_windows === 0) {
                          <span class="seats-badge bg-fully-booked cbw-fully-booked-seats-badge">
                            {{ 'events.step2.fully_booked' | translate }}
                          </span>
                        }
                        @if (event.free_time_windows !== 0) {
                          <span
                            class="seats-badge cbw-seats-badge"
                            [innerHTML]="(event.free_time_windows > 1 ? 'events.step2.regular_count_plural' : 'events.step2.regular_count_singular') | translate: { number: event.free_time_windows } | trustHtml"
                          ></span>
                        }
                      }
                      <div class="card-body">
                        <h5 class="card-title">
                          <ngx-clamp row="2">
                            <div #content>
                              {{ event | translation: 'title': lang }}
                            </div>
                          </ngx-clamp>
                        </h5>
                      </div>
                      <div class="card-pre-footer cbw-pre-footer">
                        @if ([1, 12].includes(event?.meeting_type_id)) {
                          <span>
                            <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />
                            @if (event?.place && event?.place.trim() !== ',' && (event?.place | isEmptyString)) {
                              {{ event?.place }}
                            } @else {
                              {{ 'events.step1.noPlaceDefined' | translate }}
                            }
                          </span>
                        }
                        @if (![1, 12].includes(event?.meeting_type_id) && event?.meeting_type_id && event?.meeting_type) {
                          <span>
                            <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" /> {{ event.meeting_type | translation: 'name' : lang }}
                          </span>
                        }
                        @if (!event.meeting_type && (!event?.place || !(event?.place | isEmptyString) || event?.place.trim() === ',')) {
                          <span>
                            <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />
                            <ng-container *ngTemplateOutlet="noPlaceDefined" />
                          </span>
                        }
                      </div>
                      @if (event?.slots?.length > 0) {
                        <div
                          class="cbw-event-next-button"
                          id="cbw-next-button"
                          (click)="selectEvent(event)"
                          [ngStyle]="{'color': widgetColorConf?.widget_footer_button_color, 'background-color': widgetColorConf?.widget_footer_background_color, 'border-color': widgetColorConf?.widget_footer_background_color }">
                          {{ widgetBookButtonLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
                        </div>
                      }
                      @if (event?.slots?.length === 0) {
                        <div
                          class="cbw-no-slots-button"
                          id="cbw-no-slots-button"
                          [ngStyle]="{'color': widgetColorConf?.widget_footer_button_color, 'background-color': widgetColorConf?.widget_footer_background_color, 'border-color': widgetColorConf?.widget_footer_background_color, 'opacity': '80%' }">
                          {{ widgetNoFreeSlotsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
                        </div>
                      }
                    </div>
                  </div>
                }
              }
            </div>
          }
          @if (screenType === 'LIST') {
            <div class="cbw-list-card-container">
              @for (event of _events; track event; let i = $index; let lastEvent = $last) {
                @if (event?.status === 1 && event?.is_secret !== 1) {
                  <div>
                    <div class="d-flex flex-column flex-md-row py-4 ps-4 pe-4">
                      <div class="position-relative">
                        <img
                          #image
                          class="card-img-top card-image"
                          [src]="event?.image ? eventImgBaseUrl + event.uuid + '/' + event.image : logoBaseUrl + _partner.uuid + '/' + _partner.logo"
                          alt="{{ event | translation: 'title': lang }}"
                          (error)="image.src=deployUrl + '/assets/images/' + eventImgFallback"
                        />
                        @if (_partner?.widget_show_free_slots_in_overview) {
                          @if (event.free_time_windows === 0) {
                            <span class="seats-badge bg-fully-booked cbw-fully-booked-seats-badge">
                              {{ 'events.step2.fully_booked' | translate }}
                            </span>
                          }
                          @if (event.free_time_windows !== 0) {
                            <span
                              class="seats-badge cbw-seats-badge"
                              [innerHTML]="(event.free_time_windows > 1 ? 'events.step2.regular_count_plural' : 'events.step2.regular_count_singular') | translate: { number: event.free_time_windows } | trustHtml"
                            ></span>
                          }
                        }
                      </div>
                      <div class="d-flex justify-content-between w-100">
                        <div class="d-flex flex-column justify-content-between px-md-5 pt-4 pt-md-0">
                          <div>
                            <h5 class="card-title mb-1">
                              <ngx-clamp row="1">
                                <div class="text-break" #content>
                                  {{ event | translation: 'title': lang }}
                                </div>
                              </ngx-clamp>
                            </h5>
                            <p class="card-text my-2">
                              {{ event | translation: 'lead_description' : lang }}
                            </p>
                          </div>
                          <div class="card-text">
                            @if ([1, 12].includes(event?.meeting_type_id)) {
                              <span>
                                <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />
                                @if (event?.place && event?.place.trim() !== ',' && (event?.place | isEmptyString)) {
                                  {{ event?.place }}
                                } @else {
                                  <ng-container *ngTemplateOutlet="noPlaceDefined" />
                                }
                              </span>
                            }
                            @if (![1, 12].includes(event?.meeting_type_id) && event?.meeting_type_id && event?.meeting_type) {
                              <span>
                                <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" /> {{ event.meeting_type | translation:
                                'name' : lang }}
                              </span>
                            }
                            @if (!event.meeting_type && (!event?.place || !(event?.place | isEmptyString) || event?.place.trim() === ',')) {
                              <span>
                                <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />
                                <ng-container *ngTemplateOutlet="noPlaceDefined" />
                              </span>
                            }
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          @if (event?.slots?.length) {
                            <calenso-button
                              id="cbw-next-button"
                              text="{{ widgetBookButtonLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
                              [widgetColorConf]="widgetColorConf"
                              (clickEvent)="selectEvent(event)"
                            />
                          }
                          @if (event?.slots?.length == 0) {
                            <calenso-button
                              id="cbw-no-slots-button"
                              [disabled]="true"
                              text="{{ widgetNoFreeSlotsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
                              [widgetColorConf]="widgetColorConf"
                            />
                          }
                        </div>
                      </div>
                    </div>
                    @if (!lastEvent) {
                      <hr class="my-4">
                    }
                  </div>
                }
              }
            </div>
          }
          <div class="mt-3 d-flex justify-content-center">
            @if (!paginationData.hideLoadMoreButton) {
              <calenso-button
                id="cbw-load-more-events"
                text="{{ 'load_more' | translate }}"
                [widgetColorConf]="widgetColorConf"
                (clickEvent)="loadMoreEventsFn()"
              />
            }
          </div>
        </div>
      } @else {
        @if (_noFilterEvents) {
          <app-new-alert
            title="{{ 'events.filter_result_null' | translate }}"
            type="warning"
            customClass="mt-3 mb-3 custom-event-null-msg"
          />
        } @else if (_noActiveEvents) {
          <app-new-alert
            title="{{ hiddenEventsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
            type="warning"
            customClass="mt-3 mb-3 custom-event-null-msg"
          />
        } @else if (_noEvents) {
          <app-alert
            type="warning"
            text="{{ noEventsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
          />
        } @else {
          <app-alert
            type="warning"
            text="{{ noEventsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
          />
        }
      }
    </form>
  } @else {
    <app-loader [loaderType]="eventConstants.EVENT_LOADER" />
  }
  <!-- Events section ends -->
}

<ng-template #noPlaceDefined> {{ 'events.step1.noPlaceDefined' | translate }}</ng-template>
