import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IS_CALENSO_AUTH_REQUIRED } from '../constants/http-context';
import { WIDGET_CONSTANTS } from '../constants/widget-constants';
import { CustomFieldsDao, CustomerDbModel } from '../db-models/appointment-custom-fields-dao';
import { PartnerSettingDBModel, PayPalSettingsDbModel, VerifyPaymentStatusModel } from '../db-models/partner-setting.model';
import { UserDao } from '../db-models/user-data-dao';
import { CustomBookingMessageTemplate, FeaturesDao, PartnerDao } from '../db-models/widget-conf-dao';
import { IPartnerInformationBody } from '../models/partners';
import { CUSTOM_CSS_WIDGET_TYPE, CssOverwrites } from '../models/widget-conf';
import { AuthTokenService } from './auth-token.service';
import { CryptoService } from './crypto.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  protected _partnerVerificationCode = undefined;
  protected featuresList: { [key: string]: boolean } = {};

  set partnerVerificationCode(token: string) {
    this._partnerVerificationCode = token;
  }
  get partnerVerificationCode(): string {
    return this._partnerVerificationCode;
  }

  partnerUUID: string;
  customFieldsBefore: CustomFieldsDao[] = [];
  customFieldsAfter: CustomFieldsDao[] = [];

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cryptoService: CryptoService,
    private authTokenService: AuthTokenService
  ) {
  }

  protected getPartnerInfoFromLS(bookingName: string): PartnerDao | null {
    try {
      const encryptedPartnerObj = this.localStorageService.getItemLocally(WIDGET_CONSTANTS.BOOKINGPAGE_PARTNER_OBJECT + bookingName);
      const partneObj = JSON.parse(this.cryptoService.decryptValue(encryptedPartnerObj)) as PartnerDao;
      return partneObj?.uuid ? partneObj : null;
    } catch (e) {
      return null;
    }
  }

  getWidgetConf(body: IPartnerInformationBody): Observable<PartnerDao> {
    const partneObj = this.getPartnerInfoFromLS(body.booking_name);

    if (partneObj) {
      this.authTokenService.setAuthToken(partneObj.token);
      this.loadSubscriptionFeatures(partneObj.features);
      return of(partneObj);
    }

    return this.http.post<PartnerDao>(environment.apiUrl + 'partners/information', body, {
      context: new HttpContext().set(IS_CALENSO_AUTH_REQUIRED, true)
    }).pipe(
      switchMap(partner => {
        this.loadSubscriptionFeatures(partner.features);
        return of(partner);
      })
    );
  }

  protected loadSubscriptionFeatures(features: FeaturesDao[]): void {
    if (features?.length > 0) {
      features.forEach(feature => {
        this.featuresList[feature.feature.name] = true;
      });
    }
  }

  hasFeature(feature: string): boolean {
    return this.featuresList[feature] ? this.featuresList[feature] : false;
  }

  getWidgetTemplates(): Observable<CustomBookingMessageTemplate[]> {
    return this.http.get<CustomBookingMessageTemplate[]>(environment.apiUrl + 'templates/widget');
  }

  getCustomers(): Observable<CustomerDbModel[]> {
    let params = new HttpParams()
      .set('limit', 30)
      .set('offset', 0);
    this._partnerVerificationCode && (params = params.append('token', this._partnerVerificationCode));

    return this.http.get<CustomerDbModel[]>(environment.apiUrl + 'customers', { params: params });
  }

  getCustomersByUuid(uuid: string): Observable<{ customer: CustomerDbModel }> {
    return this.http.get<{ customer: CustomerDbModel }>(environment.apiUrl + 'customers/' + uuid);
  }

  getCssOverwrites(): Observable<CssOverwrites> {
    return this.http.get<CssOverwrites>(`${environment.apiUrl}css_overwrites/active?type=${CUSTOM_CSS_WIDGET_TYPE.widget}`, {}).pipe(
      catchError(() => of(null))
    );
  }

  getCssOverwriteUsingUuid(uuid: string, isPreview = false): Observable<CssOverwrites> {
    let params = new HttpParams();
    if (isPreview) { params = params.append('preview', true); }

    return this.http.get<CssOverwrites>(environment.apiUrl + 'css_overwrites/' + uuid, { params }).pipe(
      catchError(() => of(null)),
      map(result => result?.errors !== undefined ? null : result)
    );
  }

  public searchCustomersByTag(tag: string, limit?: number, offset?: number): Observable<CustomerDbModel[]> {
    const body: { query: string, appendpartnerVerificationCode: boolean } = { query: tag, appendpartnerVerificationCode: true };
    let params: HttpParams;
    if (limit && offset) {
      params = new HttpParams()
        .set('limit', limit + '')
        .set('offset', offset + '');
    }
    return this.http.post<CustomerDbModel[]>(`${environment.apiUrl}customers/search`, body, { params: params ? params : {} });
  }

  getWorker(uuid: string): Observable<UserDao> {
    return this.http.get(environment.apiUrl + 'workers/' + uuid).pipe(
      map(resp => {
        if (resp?.[0]) {
          return resp[0];
        }
      }));
  }

  getPartnerSettings(settingId: number): Observable<PartnerSettingDBModel[]> {
    const body = { setting_id: { value: settingId, operator: '=' } };
    return this.http.post<PartnerSettingDBModel[]>(environment.apiUrl + 'partners_settings/filter', body);
  }


  verifyOnlinePaymentStatus(): Observable<VerifyPaymentStatusModel> {
    return this.http.get<VerifyPaymentStatusModel>(environment.apiUrl + 'partners/verify_online_payment_status');
  }

  getPaypalSettings(): Observable<PayPalSettingsDbModel> {
    return this.http.get<PayPalSettingsDbModel>(environment.apiUrl + 'paypal/access_token');
  }

  verifyInternalToken(token: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(environment.apiUrl + 'partners/verify_internal_token', { token }, {}).pipe(
      catchError(error => of(null))
    );
  }

  verifyWidgetPassword(param: { password: string }): Observable<{ success: boolean, code: number, message: string }> {
    return this.http.post<{ success: boolean, code: number, message: string }>(environment.apiUrl + 'partners_settings/verify_widget', param, {})
  }
}
