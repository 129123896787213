export const WIDGET_CONSTANTS = {
  APPOINTMENT: 'appointment',
  EVENT: 'event',
  COMPACT: 'compact',
  GROUP_APPOINTMENT:'group_appointment',
  RESOURCE: 'resource',
  ANY: 'any',
  APPOINTMENTS: 'appointments',
  EVENTS: 'events',
  RESOURCES: 'resources',
  PREFFERED_COUNTRIES: ['ch', 'de', 'fr', 'gb', 'it', 'nl', 'fi', 'pl', 'es', 'se', 'at'],
  DEFAULT_LANG: 'de_CH',
  BOOKING_NAME: 'braincept',
  SUCCESS: 'success',
  ERROR: 'error',
  WIDGET_LANG: 'widget_lang',
  USER_LANG: 'user_lang',
  BOOKINGPAGE_PARTNER_OBJECT: 'cbp_pobj_',
  WIDGET_LABELS: {
    WIDGET_BOOKING_INFO_APPOINTMENT: 'widget_booking_info_appointment',
    WIDGET_BOOKING_INFO_EVENT: 'widget_booking_info_event',
    WIDGET_NEXT: 'widget_next_button',
    WIDGET_PREVIOUS: 'widget_previous_button',
    WIDGET_BOOK_BUTTON: 'widget_book_button',
    WIDGET_APPOINTMENT_STORE_LABEL: 'widget_store_label',
    WIDGET_APPOINTMENT_SERVICE_LABEL: 'widget_choose_appointment_service_label',
    WIDGET_DATE_LABEL: 'widget_choose_desired_date_label',
    WIDGET_NAV_SERVICE_LABEL: 'widget_navigation_selection_label',
    WIDGET_NAV_RESOURCE_LABEL: 'widget_navigation_resource_label',
    WIDGET_NAV_DATE_LABEL: 'widget_navigation_date_label',
    WIDGET_NAV_SUMMARY_LABEL: 'widget_navigation_summary_label',
    WIDGET_NAV_PERSONAL_DETAILS_LABEL: 'widget_navigation_personal_details_label',
    WIDGET_MEETING_TYPES_LABEL: 'widget_meeting_types_label',
    WIDGET_MORNING_LABEL: 'widget_morning_label',
    WIDGET_FORENOON_LABEL: 'widget_forenoon_label',
    WIDGET_NOON_LABEL: 'widget_noon_label',
    WIDGET_AFTERNOON_LABEL: 'widget_afternoon_label',
    WIDGET_EVENING_LABEL: 'widget_evening_label',
    WIDGET_NIGHT_LABEL: 'widget_night_label',
    WIDGET_RESOURCE_LABEL: 'widget_resource_label',
    WIDGET_ANYONE_LABEL: 'widget_anyone_label',
    WIDGET_ANYONE_DESC_LABEL: 'widget_anyone_description_label',
    WIDGET_NO_BOOKABLE_DATES_LABEL: 'widget_no_appointents_found',
    WIDGET_YES_SEARCH_BUTTON_LABEL: 'widget_no_appointents_found_button',
    WIDGET_IN_STORE_LABEL: 'widget_in_store_label',
    WIDGET_PAYPAL_LABEL: 'widget_paypal_label',
    WIDGET_SAFERPAY_LABEL: 'widget_saferpay_label',
    WIDGET_NO_POSSIBLE_APPOINTMENTS_HINT: 'widget_no_possible_appointments_hint',
    WIDGET_QUALIFICATION_BASED_QUESTIONS_TITLE: 'widget_qualification_based_questions_title',
    WIDGET_QUALIFICATION_BASED_QUESTIONS_DESCRIPTION: 'widget_qualification_based_questions_description',
    WIDGET_QUALIFICATION_BASED_QUESTIONS_SKIP_BUTTON: 'widget_qualification_based_questions_skip_button',
    WIDGET_QUALIFICATION_BASED_QUESTIONS_NEXT_BUTTON: 'widget_qualification_based_questions_next_button',
    WIDGET_MANAGE_BOOKING_LABEL: 'widget_manage_booking_label',
    WIDGET_DOWNLOAD_APPOINTMENT_ICS_FILE: 'widget_download_appointment_ics_file',
    WIDGET_CHOOSE_DATE_HINT: 'widget_choose_date_hint',
    PERSONAL_DATA_CARD_TITLE: 'personal_data_card_title',
    PAYMENT_CARD_TITLE: 'payment_card_title',
    SUMMARY_PAGE_PERSONAL_INFO_HEADING: 'summary_page_personal_info_heading',
    SUMMARY_PAGE_CART_DETAILS_HEADING: 'summary_page_cart_details_heading',
    WIDGET_SUCCESS_TITLE_LABEL: 'widget_success_page_header_text',
    WIDGET_SUMMARY_STEP_CROSS_SERVICES_SECTION_HEADING: 'widget_summary_step_cross_services_section_heading',
    WIDGET_SUMMARY_STEP_ADD_CROSS_SERVICE_BUTTON_TEXT: 'widget_summary_step_add_cross_service_button_text',
    WIDGET_STORE_SELECTION_HINT: "widget_store_selection_hint",
    WIDGET_CALENDAR_HIGHLIGHTS_NOTICE: "widget_calendar_highlights_notice",
    WIDGET_PASSWORD_PROTECTION_TITLE: "widget_password_protection_title",
    WIDGET_PASSWORD_PROTECTION_DESCRIPTION: "widget_password_protection_description",
    WIDGET_PASSWORD_PROTECTION_LABEL: "widget_password_protection_label",
    WIDGET_PASSWORD_PROTECTION_PLACEHOLDER: "widget_password_protection_placeholder",
    REDIRECTION_BUTTON_TEMPLATE: 'redirect_after_booking_action_button_text',
    WIDGET_PASSWORD_PROTECTION_LOGIN_BUTTON_TEXT: "widget_password_protection_login_button_text"
  },
  EVENT_WIDGET_LABELS: {
    WIDGET_EVENT_NO_EVENTS_LABEL: 'group_appointment_filter_message_no_group_appointments_found',
    WIDGET_EVENT_HIDDEN_EVENTS_LABEL: 'group_appointment_filter_message_hidden_group_appointments',
    WIDGET_EVENT_DISABLED_ERROR_LABEL: 'group_appointment_filter_message_disabled_group_appointments',
    WIDGET_EVENT_NAV_EVENTS_LABEL: 'widget_event_navigation_events',
    WIDGET_EVENT_NAV_SLOTS_LABEL: 'widget_event_navigation_slots',
    WIDGET_EVENT_NAV_SUMMARY_LABEL: 'widget_event_navigation_summary',
    WIDGET_EVENT_NO_MORE_FREE_SLOTS_LABEL:
      'widget_event_overview_no_more_free_slots_label',
    WIDGET_EVENT_NEXT_LABEL: 'widget_event_next_button',
    WIDGET_EVENT_PREVIOUS_LABEL: 'widget_event_previous_button',
    WIDGET_EVENT_BOOK_LABEL: 'widget_event_book_button',
    WIDGET_EVENT_DATE_LABEL: 'widget_event_choose_desired_date_label',
    WIDGET_SUCCESS_PAGE_EVENT_BOOKING_LIMIT_REACHED: 'widget_success_page_event_booking_limit_reached',
    WIDGET_SUCCESS_TITLE_LABEL: 'widget_success_page_header_text'
  },
   SUBSCRIPTION_FREE_TYPE: {
    id: 1,
    name: 'free',
    desc: 'Calenso Free',
  },
  SUBSCRIPTION_FLATRATE_TYPE: {
    id: 5,
    name: 'flatrate',
    desc: 'Calenso Flatrate',
  },
  SUBSCRIPTION_WHITELABEL_TYPE: {
    id: 100,
    name: 'whitelable',
    desc: 'Calenso Whitelable',
  },
  SUBSCRIPTION_ENTERPRISE_TYPE: {
    id: 101,
    name: 'enterprise',
    desc: 'Calenso Enterprise',
  },
  SUBSCRIPTION_PROFESSIONAL_TYPE: {
    id: 102,
    name: 'professional',
    desc: 'Calenso professional',
  },
  SUBSCRIPTION_BASIC_TYPE: {
    id: 103,
    name: 'basic',
    desc: 'Calenso Basic',
  },
  SUBSCRIPTION_BUSINESS_TYPE: {
    id: 104,
    name: 'business',
    desc: 'Calenso Business',
  },
  SUBSCRIPTION_NEW_ENTERPRISE_TYPE: {
    id: 105,
    name: 'newEnterprise',
    desc: 'Calenso New Enterprise',
  },
  LANGUAGES: [
    {
      text: 'german',
      value: 'de',
      secondary_text: 'DE',
      locale: 'de_CH',
      flagIcon: '/assets/images/flags/german.png',
    },
    {
      text: 'french',
      value: 'fr',
      secondary_text: 'FR',
      locale: 'fr_CH',
      flagIcon: '/assets/images/flags/french.png',
    },
    {
      text: 'italian',
      value: 'it',
      secondary_text: 'IT',
      locale: 'it_CH',
      flagIcon: '/assets/images/flags/italian.png',
    },
    {
      text: 'english',
      value: 'en',
      secondary_text: 'EN',
      locale: 'en_US',
      flagIcon: '/assets/images/flags/english.png',
    },
    {
      text: 'dutch',
      value: 'nl',
      secondary_text: 'NL',
      locale: 'nl_NL',
      flagIcon: '/assets/images/flags/dutch.png',
    },
    {
      text: 'finnish',
      value: 'fi',
      secondary_text: 'FI',
      locale: 'fi_FI',
      flagIcon: '/assets/images/flags/finnish.png',
    },
    {
      text: 'polish',
      value: 'pl',
      secondary_text: 'PL',
      locale: 'pl_PL',
      flagIcon: '/assets/images/flags/polish.png',
    },
    {
      text: 'spanish',
      value: 'es',
      secondary_text: 'ES',
      locale: 'es_ES',
      flagIcon: '/assets/images/flags/spanish.png',
    },
    {
      text: 'swedish',
      value: 'sv',
      secondary_text: 'SE',
      locale: 'sv_SE',
      flagIcon: '/assets/images/flags/swedish.png',
    },
  ],
};

export const GROUP_APPOINTMENTS_FILTER_BEHAVIOUR_TYPE = {
  AND: 'and',
  OR: 'or'
};

export const APPOINTMENT_SOURCE_ID = {
  INTERNAL_WIDGET: 6,
  EXTERNAL_WIDGET: 2
};

export const WINDOW_LISTENER_CONSTANTS = {
  APPOINTMENT_BOOKING_DONE: 'APPOINTMENT_BOOKING_DONE',
  EVENT_BOOKING_DONE: 'EVENT_BOOKING_DONE'
};

export const DEFAULT_CUSTOMER_FIELDS = [
  'custom_field_before',
  'title',
  'salutation',
  'prename',
  'lastname',
  'company',
  'email',
  'phone',
  'mobile',
  'notification_preferences',
  'address',
  'country',
  'comment',
  'internal_comment',
  'custom_field_after',
  'meeting_types',
  'policy',
  'birthday',
];

export const DEFAULT_REQUIRED_CUSTOMER_FIELDS = [
  'prename',
  'lastname',
  'company',
  'meeting_types',
  'notification_preferences',
  'policy',
  'internal_comment',
];

export const DEFAULT_CUSTOMER_PROFILES_ORDER = ['customer', 'broker'];

export const GTM_EVENTS = {
  APPOINTMENT_BOOKING_START: 'appointment_booking_start',
  APPOINTMENT_BOOKING_STEP_PROFILES: 'appointment_booking_step_profiles',
  APPOINTMENT_BOOKING_STEP_STORES_AND_SERVICES: 'appointment_booking_step_stores_and_services',
  APPOINTMENT_BOOKING_STEP_RESOURCES: 'appointment_booking_step_resources',
  APPOINTMENT_BOOKING_STEP_AVAILABLE_APPOINTMENTS: 'appointment_booking_step_available_appointments',
  APPOINTMENT_BOOKING_STEP_PERSONAL_INFO: 'appointment_booking_step_personal_info',
  APPOINTMENT_BOOKING_STEP_SUMMARY: 'appointment_booking_step_summary',
  APPOINTMENT_BOOKING: 'appointment_booking',
  APPOINTMENT_BOOKING_STEP_SUCCESS: 'appointment_booking_step_success',
  APPOINTMENT_BOOKING_STEP_FAILED: 'appointment_booking_step_failed',

  EVENT_BOOKING_START: 'event_booking_start',
  EVENT_BOOKING_STEP_SELECT_EVENT: 'event_booking_step_select_event',
  EVENT_BOOKING_STEP_AVAILABLE_EVENTS: 'event_booking_step_available_events',
  EVENT_BOOKING_STEP_SUMMARY: 'event_booking_step_summary',
  EVENT_BOOKING: 'event_booking',
  EVENT_BOOKING_STEP_SUCCESS: 'event_booking_step_success',
  EVENT_BOOKING_STEP_FAILED: 'event_booking_step_failed',

  COMPACT_WIDGET_BOOKING_START: 'compact_widget_booking_start',
  COMPACT_WIDGET_BOOKING_STEP_AVAILABLE_APPOINTMENTS: 'compact_widget_step_available_appointments',
  COMPACT_WIDGET_BOOKING_STEP_SUMMARY: 'compact_widget_booking_step_summary',
  COMPACT_WIDGET_BOOKING_STEP_PAYMENT: 'compact_widget_booking_step_payment',
  COMPACT_WIDGET_BOOKING: 'compact_widget_booking',
  COMPACT_WIDGET_BOOKING_STEP_SUCCESS: 'compact_widget_booking_step_success',
  COMPACT_WIDGET_BOOKING_STEP_FAILED: 'compact_widget_booking_step_failed'
};

export const LOCAL_STORAGE_CONSTANTS = {
  LAST_PAYMENT_METHOD: 'cl_lpm',
  CALENSO_AUTH_TOKEN: 'cw_cat'
};

export const PARENT_TO_CHILD_IFRAME_EVENTS = {
  NAVIGATION: 'cwParentToChildNavigation',
  VALIDATION: 'cwValidationFailed'
};

export const PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME = {
  CUSTOMER_PROFILE_DISABLED: 'CUSTOMER_PROFILE_DISABLED',
  CUSTOMER_PROFILE: 'CUSTOMER_PROFILE',
  STORE: 'STORE',
  SERVICE: 'SERVICE',
  WORKER: 'WORKER',
  DATE: 'DATE',
  SLOT: 'SLOT',
  PERSONAL_FORM: 'PERSONAL_FORM'
};

export const PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS: { [key: string]: string } = {
  PREVIOUS: 'previous',
  NEXT: 'next',
  BOOK: 'book'
};

export const SALUTATION_TYPES = [
  { display: 'mr', value: 'm' },
  { display: 'ms', value: 'f' },
  { display: 'other', value: 'o' }
];

export const CUSTOMER_CSTM_FIELD_TITLE_LIST = [
  { id: 0, name: '--' },
  { id: 1, name: 'Dr.' },
  { id: 2, name: 'Dr. med.' },
  { id: 3, name: 'Prof.' }
]

export const MAX_SLOTS_VISIBLE_LENGTH = 14;

export const MIME_TYPES = {
  IMAGE: 'image/jpeg,image/jpe,image/png,image/gif',
  DOC: 'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const EVENT_PAGINATION_LIMIT = 20;

export const MEETING_TYPE_ID = {
  STORE: 1,
  ZOOM: 2,
  CALENSO: 3,
  PHONE: 4,
  CUSTOMER: 5,
  UNBLU: 6,
  MICROSOFT_TEAMS: 7,
  GOOGLE_MEET: 8,
  GOTO_MEETING: 9,
  WEBEX: 10,
  WILL_CALL_YOU: 11,
  GROUP_APPOINTMENT_PLACE: 12,
  METAVERSE: 13
};

export const REGEX = {
  ZIP: /^((\d{4} [A-Z]{2})|(\d{3,6})|(\d{5}(-\d{4})?)|([A-Z0-9]{1,4} [A-Z0-9]{1,4}))$/,
};

export const INTL_TEL_INPUT = {
  UTIL_SCRIPTS: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/24.0.0/js/utils.js',
  IPLOOKUP_URL: 'https://ipinfo.io/json?token=e7e53bffbd5b01'
};

export const BOOKING_CUSTOM_FIELD_TYPES = {
  SELECT: 'select',
  TEXT: 'text',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  FILE: 'file',
  DATE: 'date',
  INFORMATION: 'information',
  NUMBER_SELECT: 'number-select',
  IMAGE_SELECT: 'image-select',
  CARD_SELECT: 'card-select',
  WORKER_SELECT: 'worker-select',
};

export const PAYMENT_TYPES = {
  PAYPAL: 'paypal',
  SAFERPAY: 'saferpay',
  STORE: 'store',
  INVOICE: 'invoice',
  CREDITCARD: 'creditcard',
  LEX_OFFICE_INVOICE: 'lex_office_invoice'
};

export const WIDGET_APPOINTMENT_TYPE = {
  INTERNAL_APPOINTMENT: 'internal_appointment',
  EXTERNAL_APPOINTMENT: 'external_appointment',
  EXTERNAL_GROUP_APPOINTMENT: 'external_group_appointment',
}

export const BOOKING_REDIRECTION_VIEWS = {
  BOOKING_SUMMARY_VIEW: 'booking_summary_view',
  BOOKING_SUCCESS_VIEW: 'booking_success_view',
} as const;
