import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NgModel } from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../constants/widget-constants';
import { CustomBookingMessageTemplate } from '../../../db-models/widget-conf-dao';
import { GlobalObjects } from '../../../models/global';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { HelperService } from '../../../services/helper.service';
import { LoggerService } from '../../../services/logger.service';
import { PartnerService } from '../../../services/partner.service';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';

const modules = [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, FormsModule, TranslateModule];
const components = [AlertComponent, ButtonComponent];

@Component({
  selector: 'app-password-protection',
  standalone: true,
  imports: [...modules, ...components, TranslationPipe],
  templateUrl: './password-protection.component.html',
  styleUrl: './password-protection.component.scss'
})
export class PasswordProtectionComponent {
  private translateService = inject(TranslateService);
  private partnerService = inject(PartnerService);
  private destroyRef = inject(DestroyRef);
  private helperService = inject(HelperService);

  protected readonly deployUrl = environment.deployUrl;
  protected readonly languages = WIDGET_CONSTANTS.LANGUAGES;
  protected readonly templateContent = TRANSLATION_TEMPLATES;
  protected isVerifyPasswordLoading = false;
  protected isTemplateLoading = false;
  protected invalidPassword = false;
  protected passwordProtectionTitleTemplate: CustomBookingMessageTemplate;
  protected passwordProtectionDescriptionTemplate: CustomBookingMessageTemplate;
  protected passwordProtectionLabelTemplate: CustomBookingMessageTemplate;
  protected passwordProtectionPlaceholderTemplate: CustomBookingMessageTemplate;
  protected passwordProtectionLoginButtonTextTemplate: CustomBookingMessageTemplate;

  @Input() protected supportedWidgetLanguages: string[] = [];
  @Input() protected langSwitcher = true;
  @Input() protected lang: string;
  @Input() protected globals: GlobalObjects;
  @Input() protected showDevAlert = false;
  @Input() protected widgetColorConf: WidgetColorConf;

  @Output() passwordVerified = new EventEmitter<void>();

  constructor() {
    this.translateService.onLangChange.subscribe(lang => this.lang = lang.lang);
    this.getWidgetTemplates();
  }

  protected switchLanguage(language: string): void {
    this.lang = language;
    this.translateService.use(language);
    this.globals.user_selected_language = language;
  }

  protected submitPassword(password: NgModel): void {
    if (password.invalid) {
      password.control.markAsTouched();
      return;
    }

    this.isVerifyPasswordLoading = true;
    this.partnerService.verifyWidgetPassword({ password: password?.value?.trim() })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.isVerifyPasswordLoading = false)
      )
      .subscribe({
        next: response => {
          if (response?.success) {
            this.passwordVerified.emit();
          } else {
            this.invalidPassword = response?.code === HttpStatusCode.Unauthorized || true;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.invalidPassword = true;
          LoggerService.error(error);
        }
      });
  }

  private getWidgetTemplates(): void {
    this.isTemplateLoading = true;
    this.partnerService.getWidgetTemplates()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.isTemplateLoading = false)
      )
      .subscribe({
        next: templates => templates.length && this.setupInitialTemplates(templates),
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
  }

  private setupInitialTemplates(widgetTemplates: CustomBookingMessageTemplate[]): void {
    this.passwordProtectionTitleTemplate = this.helperService.findTemplate(
      widgetTemplates, WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_PASSWORD_PROTECTION_TITLE
    );

    this.passwordProtectionDescriptionTemplate = this.helperService.findTemplate(
      widgetTemplates, WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_PASSWORD_PROTECTION_DESCRIPTION
    );

    this.passwordProtectionLabelTemplate = this.helperService.findTemplate(
      widgetTemplates, WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_PASSWORD_PROTECTION_LABEL
    );

    this.passwordProtectionPlaceholderTemplate = this.helperService.findTemplate(
      widgetTemplates, WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_PASSWORD_PROTECTION_PLACEHOLDER
    );

    this.passwordProtectionLoginButtonTextTemplate = this.helperService.findTemplate(
      widgetTemplates, WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_PASSWORD_PROTECTION_LOGIN_BUTTON_TEXT
    );
  }
}
