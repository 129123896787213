<app-cw-new-card [showHeader]="true" [showBody]="true">
  <div header>
    <app-cw-card-header
      [title]="templateTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
      [subtitle]="templateSubTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
    />
  </div>

  <div body class="my-4 event-body">
    @if (cart.length <= 0) {
      <div class="no-data">{{ 'appointments.step4.cart.emptyCartWarning' | translate }}</div>
    }

    @if (cart) {
      <div>
        @for (cartItem of cart; track cartItem; let i = $index; let last = $last) {
        <div class="row cw-cart-row">
          <div class="col-md-12">
            <div class="d-flex align-items-center padding-left-15px">
              <div class="service">
                <ngx-clamp row="3">
                  <div class="name" #content>
                    <span class="cart text-break">
                      {{ cartItem.referenceEvent | translation: 'title' : lang }}
                    </span>
                  </div>
                </ngx-clamp>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="appointment mt-1 padding-left-15px" [ngClass]="{ 'appointment-padding': cart.length != 1 }">
              @if (((cartItem.startTime | dateutc: dateFormat) === (cartItem.endTime | dateutc: dateFormat))) {
                @if (cartItem?.childSlots?.length) {
                  @for (slot of cartItem?.childSlots; track slot.id) {
                    <div class="cbw-summary-date-time">
                      {{ slot.start | dateutc: datePipeWithComma }} - {{ slot.end | dateutc: timeFormat }}
                    </div>
                  }
                }
                @if (cartItem?.childSlots?.length === 0) {
                  <span class="cbw-summary-date-time">
                    {{ cartItem.startTime | dateutc: datePipeWithComma }} - {{ cartItem.endTime | dateutc: timeFormat }}
                  </span>
                }
              }
              @if (((cartItem.startTime | dateutc: dateFormat) !== (cartItem.endTime | dateutc: dateFormat))) {
                <span class="cbw-summary-date-time">
                  {{ cartItem.startTime | dateutc: dateTimeFormat }} - {{ cartItem.endTime | dateutc: dateTimeFormat }}
                </span>
              }
              @if (showWidgetSlotLocation) {
                <div class="cbw-summary-date-time d-flex mt-1">
                  @if (cartItem?.location) {
                    @if (
                      [1, 12].includes(cartItem?.meeting_type_id) &&
                      cartItem?.location?.trim() !== ',' &&
                      (cartItem?.location | isEmptyString)
                    ) {
                      {{ 'common.location' | translate }}: {{ cartItem?.location }}
                    } @else if (cartItem?.meeting_type_id && ![1, 12]?.includes(cartItem?.meeting_type_id)) {
                      <ng-container *ngTemplateOutlet="meetingType; context: { eventInfo: cartItem }"/>
                    }
                  } @else {
                    @if (
                      [1, 12].includes(eventState?.meeting_type_id) &&
                      eventState?.place?.trim() !== ',' &&
                      (eventState?.place | isEmptyString)
                    ) {
                      {{ 'common.location' | translate }}: {{ eventState?.place }}
                    } @else if (![1, 12]?.includes(eventState?.meeting_type_id) && eventState?.meeting_type_id) {
                      <ng-container *ngTemplateOutlet="meetingType; context: { eventInfo: eventState }"/>
                    }
                  }
                </div>
              }
            </div>
            <div class="padding-left-15px mt-1 d-none d-md-block">
              <a class="remove-link" href="javascript:void(0)" (click)="removeFromCart( cartItem.slotId )">
                {{ 'buttons.remove' | translate }}
              </a>
            </div>
          </div>

          <div class="col-md-4">
            @if (eventState?.is_multi_day !== 1) {
              @if (partner.hide_mail_resource !== 1) {
                <div class="worker-name padding-left-15px">
                  @if ((cartItem.duration | replaceComma | eventToHour) > 0) {
                  <span>
                    {{ cartItem.duration | replaceComma | eventToHour }}{{ 'common.durationHour' | translate }}
                  </span>
                  }
                  @if ((cartItem.duration | replaceComma | eventToMinute) > 0) {
                    <span>
                      {{ cartItem.duration | replaceComma | eventToMinute }}{{ 'common.durationMinute' | translate }}
                    </span>
                  }
                </div>
              }
            }
          </div>

          <div class="col-md-2">
            <div class="no-wrap price text-md-end padding-left-15px">
              @if (cartItem.currentEvent?.price > 0) {
              <span>
                {{ cartItem.currentEvent?.price | calioCurrency: partner.currency }}
              </span>
              }
              @if (cartItem.currentEvent?.price <= 0) {
                <span>
                {{ cartItem.currentEvent?.price | price | translate | titlecase }}
                </span>
              }

              @if (cartItem?.currentEvent?.tax) {
                <div class="vat">
                  {{ 'summary_page_translations.tax' | translate: { 'rate': cartItem?.currentEvent?.taxRate } }}
                </div>
              }
            </div>
          </div>

          <div class="col-md-12 d-block d-md-none">
            <div class="padding-left-15px mt-1">
              <a class="remove-link" href="javascript:void(0)" (click)="removeFromCart(cartItem.slotId)">
                {{ 'buttons.remove' | translate }}
              </a>
            </div>
          </div>

          @if ((finalCouponDiscount > 0 || finalTotal !== 0 && cart && cart.length > 0) ||
            (cart && cart.length > 1 && !last)) {
              <hr class="card-divider w-100">
          }
        </div>
        }

        @if (finalCouponDiscount > 0 || finalTotal !== 0 && cart && cart.length > 0) {
        <div class="row cw-cart-row">
          <div class="col-lg-6 col-md-6"></div>
          <div class="col-lg-6 col-md-6">
            @if (additionalGuests?.length > 0) {
            <div class="d-flex justify-content-start justify-content-md-end">
              <div class="row total-row cw-cart-row padding-left-15px">
                <div class="col-lg-7 col-md-7 normal-field-label padding-left-15px">
                  @if (additionalGuests.length === 1) {
                  <div>{{ 'common.additionalGuestCartLabel' | translate : { number: additionalGuests.length } }}</div>
                  }
                  @if (additionalGuests.length > 1) {
                  <div>{{ 'common.additionalGuestsCartLabel' | translate : { number: additionalGuests.length } }}</div>
                  }
                </div>
                <div class="col-lg-5 col-md-5 text-md-end normal-field-label padding-left-15px">
                  <span>
                    @if (totalCartPrice > 0) {
                      @if (additionalGuests?.length > 0) {
                        {{ ((additionalGuests.length) * totalCartPrice) | calioCurrency: partner.currency }}
                      }
                      @if (additionalGuests?.length === 0) {
                        {{ totalCartPrice | calioCurrency: partner.currency }}
                      }
                    }
                    @if (totalCartPrice === 0) {
                      {{ 'free' | translate }}
                    }
                    @if (totalCartPrice === -1) {
                    &nbsp;
                    }
                  </span>
                </div>
              </div>
            </div>
            }

            <div class="d-flex justify-content-start justify-content-md-end">
              <div class="row total-row cw-cart-row padding-left-15px">
                <div class="col-lg-7 col-md-7 bold-field-label padding-left-15px">
                  <div>{{ 'summary_page_translations.subtotal' | translate }}</div>
                </div>
                <div class="col-lg-5 col-md-5 text-md-end bold-field-label padding-left-15px">
                  <span>
                    @if (totalCartPrice > 0) {
                      @if (additionalGuests?.length > 0) {
                        {{ ((additionalGuests.length + 1) * totalCartPrice) | calioCurrency: partner.currency }}
                      }
                      @if (additionalGuests?.length === 0) {
                      {{ totalCartPrice | calioCurrency: partner.currency }}
                      }
                    }
                    @if (totalCartPrice === 0) {
                    {{ 'free' | translate }}
                    }
                    @if (totalCartPrice === -1) {
                    &nbsp;
                    }
                  </span>
                </div>
              </div>
            </div>

            @if (coupon) {
            <div class="d-flex justify-content-start justify-content-md-end mt-2">
              <div class="row total-row cw-cart-row padding-left-15px">
                <div class="col-lg-7 col-md-7 normal-field-label padding-left-15px">
                  {{ 'coupon_credit_card_info.coupon' | translate }} ({{ coupon.code }})
                </div>
                <div class="col-lg-5 col-md-5 text-md-end normal-field-label padding-left-15px">
                  <span>
                    - {{ finalCouponDiscount | calioCurrency: partner.currency }}
                  </span>
                </div>
              </div>
            </div>
            }
            <div class="d-flex justify-content-start justify-content-md-end">
              <div class="row total-row cw-cart-row padding-left-15px">
                <hr class="card-divider w-100">
                <div class="col-lg-7 col-md-7 bold-field-label padding-left-15px">
                  {{ 'appointments.step4.total' | translate }}
                </div>
                <div class="col-lg-5 col-md-5 text-md-end red-bold-field-label padding-left-15px">
                  <span>
                    {{ finalTotal | calioCurrency: partner.currency }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    }
  </div>
</app-cw-new-card>


<ng-template #meetingType let-eventInfo="eventInfo">
  @if (eventInfo?.meeting_type_id === 1) {
    <img src="{{ environment.baseUrl }}/images/meetings/store.svg" alt="Store Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 2) {
    <img src="{{ environment.baseUrl }}/images/meetings/zoom.svg" alt="Zoom Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 3) {
    <img src="{{ environment.baseUrl }}/images/meetings/calenso.svg" alt="Calenso Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 4) {
    <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 5) {
    <img src="{{ environment.baseUrl }}/images/meetings/customer.svg" alt="Customer Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 6) {
    <img src="{{ environment.baseUrl }}/images/meetings/unblu.svg" alt="Unblu Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 7) {
    <img src="{{ environment.baseUrl }}/images/meetings/teams.svg" alt="Teams Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 8) {
    <img src="{{ environment.baseUrl }}/images/meetings/meet.svg" alt="Meet Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 9) {
    <img src="{{ environment.baseUrl }}/images/meetings/gotomeeting.png" alt="Gotomeeting Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 10) {
    <img src="{{ environment.baseUrl }}/images/meetings/webex.png" alt="Webex Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 11) {
    <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 13) {
    <img src="{{ environment.baseUrl }}/images/meetings/metaverse.svg" alt="Metaverse" width="15px" />
  }
  @if ((widgetTemplates | calioMeetingTemplate: { id: eventInfo?.meeting_type_id, type: 'meeting_template' }); as widgetTemplate) {
    <div class="meeting-option">
      @if ((widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
        <div class="meeting-type-name"
          [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
        </div>
      }
    </div>
  }
</ng-template>
