import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CountryDbModel } from '../models/country.model';


@Injectable({
  providedIn: 'root'
})
export class RefDataService {

  private http = inject(HttpClient);

  public getCountries(): Observable<CountryDbModel[]> {
    return this.http
      .get<CountryDbModel[]>(`${environment.apiUrl}countries`)
      .pipe(
        catchError(() => of([])),
        switchMap((countries: CountryDbModel[]) => {
          if (countries['errors'] !== undefined) {
            of([]);
          } else {
            return of(countries);
          }
        })
      );
  }
}
