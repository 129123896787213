import {
  faArrowLeftLong,
  faCalendar,
  faCalendarCheck,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faCircleNotch,
  faClock,
  faDownload,
  faEllipsisH,
  faHandshake,
  faHospitalUser,
  faList,
  faMinus,
  faPlus,
  faQuestionCircle,
  faSquare,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUser,
  faUsers,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';

export const icons = [
  faArrowLeftLong,
  faCalendar,
  faCalendarCheck,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faCircleNotch,
  faClock,
  faDownload,
  faEllipsisH,
  faHandshake,
  faHospitalUser,
  faList,
  faMinus,
  faPlus,
  faQuestionCircle,
  faSquare,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUser,
  faUsers,
  faUserTie
];
