import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, filter, map } from 'rxjs';
import { IS_CALENSO_AUTH_REQUIRED } from '../constants/http-context';
import { AuthTokenService } from '../services/auth-token.service';
import { LoggerService } from '../services/logger.service';
import { PartnerService } from '../services/partner.service';

export const AuthInterceptor: HttpInterceptorFn = (request, next) => {
  const partnerService = inject(PartnerService);
  const authTokenService = inject(AuthTokenService);
  const headers: {
    [name: string]: string | string[];
  } = {};

  if (
    request.method === 'POST' &&
    partnerService.partnerVerificationCode &&
    request.body?.['appendpartnerVerificationCode'] !== undefined
  ) {
    delete request.body['appendpartnerVerificationCode'];
    request.body['token'] = partnerService.partnerVerificationCode;
  }

  request = request.clone({ setHeaders: headers });

  return next(request).pipe(
    filter((event): event is HttpResponse<any> => event instanceof HttpResponse),
    map(event => {
      if (request.context.has(IS_CALENSO_AUTH_REQUIRED)) {
        const authToken = event?.body?.token;
        authToken && authTokenService.setAuthToken(authToken);
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      LoggerService.log('error is ', error);
      throw error;
    })
  )
}