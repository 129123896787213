export enum TokenType {
  internalWidget = 'internal_widget',
  publicWidget = 'public_widget'
}

export interface IPartnerInformationBody {
  booking_name: string;
  worker_id?: number;
  worker_email?: string;
  debug?: boolean;
  internal?: boolean;
  token?: string;
  token_type: TokenType;
}
