<ng-container>
  <button class="back-button back-button-slots cbcw-back-button" (click)="goBack()">
    <fa-icon class="icon" icon="chevron-left"></fa-icon>
    <span class="icon-text">
      {{ 'compact.navigation.date' | translate }}
    </span>
  </button>
</ng-container>

<div class="selected-date-label cbcw-selected-date-label">
  {{ appointmentState?.day?.date | date: 'cccc, dd.MM.YYYY' : 'ZZZZZ' : lang }}
</div>

@if (!showAppointmentSlotGhostElement && noAppointments) {
  <div class="no-appointment-msg cbcw-no-appointment-msg">
    <p class="alert alert-danger" [innerText]="((widgetTemplates | calioMeetingTemplate: { id: 241, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang)"></p>
  </div>
}

<div class="appointment-wrapper cbcw-appointment-wrapper">
  @if (showAppointmentSlotGhostElement) {
    @for (appointment of [0, 1, 2, 3, 4, 5]; track appointment) {
    <div class="appointment appointments-ghost skeleton-animation cbcw-appointments-ghost">
      <div class="bar bar-1"></div>
    </div>
    }
  }

  @if (!noAppointments && !showAppointmentSlotGhostElement) {
    @for (appointment of appointments?.appointments; track appointment) {
    <div class="appointment cbcw-appointment">
      <input
        type="radio"
        [id]="appointment.short_start + '_' + appointment.worker.id"
        name="appointment"
        [value]="appointment.short_start"
        [disabled]="appointment.isReserved"
        [checked]="isTerminInCart(createCartItemId(appointment.worker.id, appointmentState.date, appointment.short_start))"
      />
      <div class="selected-appointment-wrapper cbcw-selected-appointment-wrapper">
        <label
          class="appointment-label cbcw-appointment-label"
          [for]="appointment.short_start + '_' + appointment.worker.id"
        >
          {{ appointment.start | dateutc:timeFormat }} - {{ appointment.end | dateutc:timeFormat }}
          @if (appointment.isReserved) {
            <br />
            {{ 'alert_messages.reserved_by_someone' | translate }}
          }
        </label>
        <button class="btn-book cbcw-btn-book" (click)="updateCart(appointment);">
          {{ widgetTemplates | calioMeetingTemplate: { id: 130, type: 'template' } | translation:
          templateContent.TEMPLATE_CONTENT: lang }}
        </button>
      </div>
    </div>
    }
  }
</div>
