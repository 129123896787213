<div class="d-flex align-items-center">
  <ul class="nav nav-pills nav-fill w-100">
    <li
      class="nav-item"
      [ngClass]="{
        'active': viewMode == eventConstants.EVENTS, 'valid': events!= null
      }"
      [attr.aria-current]="viewMode == eventConstants.EVENTS ? 'step' : undefined"
    >
      <button
        type="button"
        class="nav-link btn"
        #tab
        (click)="navigateTo(eventConstants.EVENTS)"
        [disabled]="partner.hide_back_on_bookingsite === 1"
      >
        <span class="d-none d-sm-block text-uppercase">
          {{ widgetEventsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
        </span>
        <span class="d-sm-none d-flex justify-content-center">
          <fa-icon class="icon" icon="list" />
        </span>
      </button>
    </li>

    @if (!hideSlotStep) {
      <li
        class="nav-item"
        [ngClass]="{
          'active': viewMode == eventConstants.DATE, 'valid': eventState.eventId != null
        }"
        [attr.aria-current]="viewMode == eventConstants.DATE ? 'step' : undefined"
      >
        <button
          type="button"
          class="nav-link btn"
          #tab
          (click)="eventState.eventId != null ? navigateTo(eventConstants.DATE) : ignore()"
        >
          <span class="d-none d-sm-block text-uppercase">
            {{ widgetSlotsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          </span>
          <span class="d-sm-none d-flex justify-content-center">
            <fa-icon class="icon" icon="calendar"></fa-icon>
          </span>
        </button>
      </li>
    }

    <li
      class="nav-item"
      [ngClass]="{ 'active': viewMode == eventConstants.SUMMARY }"
      [attr.aria-current]="viewMode == eventConstants.SUMMARY ? 'step' : undefined"
    >
      <button
        type="button"
        class="nav-link btn" #tab
        (click)="!cart.length ? ignore() : navigateTo(eventConstants.SUMMARY)"
      >
        <span class="d-none d-sm-block text-uppercase">
          {{ widgetSummaryLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
        </span>
        <span class="d-sm-none d-flex justify-content-center">
          <fa-icon class="icon" icon="check" />
        </span>
      </button>
    </li>
  </ul>

  @if (langSwitcher && supportedWidgetLanguages?.length > 1) {
    <div>
      <div ngbDropdown class="d-inline-block">
        <button class="nav-link lang-dropdown" id="nav-lang-dropdown" ngbDropdownToggle>
          <span>
            @for (language of widgetConstant.LANGUAGES; track language) {
              @if (lang === language.locale) {
                <img
                  width="20px"
                  height="20px"
                  (click)="switchLanguage(language.locale)"
                  alt="{{ language.text }}"
                  title="{{ language.text }}"
                  src="{{ environment.deployUrl + language.flagIcon }}"
                />
              }
            }
          </span>
        </button>

        <div class="lang-dropdown-menu" ngbDropdownMenu aria-labelledby="nav-lang-dropdown">
          @for (language of widgetConstant.LANGUAGES; track language.value) {
            @if (lang !== language.locale && supportedWidgetLanguages && supportedWidgetLanguages.indexOf(language.value) > -1) {
              <a ngbDropdownItem class="lang-dropdown-menu-item" href="javascript:void(0)" (click)="switchLanguage(language.locale)">
                <span class="d-flex justify-content-center">
                  <img
                    width="20"
                    height="20"
                    alt="{{ language.text }}"
                    title="{{ language.text }}"
                    src="{{ environment?.deployUrl + language.flagIcon }}"
                  />
                </span>
              </a>
            }
          }
        </div>
      </div>
    </div>
  }
</div>
