import { AsyncPipe, DatePipe, NgClass, NgStyle, SlicePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarCommonModule, CalendarMonthModule, CalendarMonthViewDay } from 'angular-calendar';
import { delay, of, switchMap } from 'rxjs';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { UserDao } from '../../../db-models/user-data-dao';
import { Partner } from '../../../models/global';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';

const modules = [CalendarCommonModule, FontAwesomeModule, CalendarMonthModule, TranslateModule];
const components = [LoaderComponent];
const pipes = [AsyncPipe, DatePipe, SlicePipe];

@Component({
  selector: 'app-new-calendar-picker',
  templateUrl: './new-calendar-picker.component.html',
  styleUrls: ['./new-calendar-picker.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, ...modules, ...components, ...pipes]
})
export class NewCalendarPickerComponent implements OnInit {

  @Input() protected noFreeDates: boolean;
  @Input() protected disablePrev: boolean;
  @Input() protected viewDate: Date;
  @Input() protected excludeWeekDays: number[];
  @Input() protected calendarLoaded = false;
  @Input() protected days: CalendarMonthViewDay[];
  @Input() protected widgetColorConf?: WidgetColorConf;
  @Input() protected worker: UserDao;
  @Input() protected maxCalendarAvailabilityDate: Date;
  @Input() protected lang: string;
  @Input() protected calendarSlotsCountList?: {
    date: Date,
    slotsCount: number
  }[] = [];
  @Input() protected partner: Partner;
  @Input() protected calendarPreselectedDate: string;

  @Output() protected viewDateChangedEvent = new EventEmitter<{ viewDate: Date, isNext: Boolean }>();
  @Output() protected onDateSelectEvent = new EventEmitter<any>();
  @Output() protected loadCalendarWithAvailabilitiesEvent = new EventEmitter<any>();

  protected readonly appointmentConstant = APPOINTMENT_CONSTANT;
  protected disableNext = false;
  protected preselectedEventTriggered = false;
  protected weekStartsOn = 1;

  public cdref = inject(ChangeDetectorRef);
  private customEventService = inject(CustomEventService);

  ngOnInit(): void {
    this.customEventService.disableCalendarNextButtonEvent.subscribe(isDisableNextButton => {
      setTimeout(() => this.disableNext = isDisableNextButton, 500);
    });

    this.validateMaxDate(this.viewDate);
  }

  protected validateMaxDate(viewDate: Date) {
    this.disableNext = viewDate.getMonth() == this.maxCalendarAvailabilityDate.getMonth() && viewDate.getFullYear() == this.maxCalendarAvailabilityDate.getFullYear();
  }

  protected viewDateChanged(viewDate: Date, isNext: boolean) {
    this.validateMaxDate(viewDate);
    this.viewDate = viewDate;
    this.viewDateChangedEvent.emit({ viewDate: viewDate, isNext: isNext });
  }

  protected onDateSelect(day: any) {
    if (day.day.inMonth) {
      this.onDateSelectEvent.emit(day);
    }
  }

  protected loadCalendarWithAvailabilities(body: any) {
    this.loadCalendarWithAvailabilitiesEvent.emit(body);
  }

  protected getColorOfDay(day: any): any {
    let calendarPreselectedDate: Date;
    let hasAlreadySelected = false;

    if (this.calendarPreselectedDate) {
      calendarPreselectedDate = new Date(`${this.calendarPreselectedDate} 00:00:00`);
    }

    for (const calendarSlotsCount of this.calendarSlotsCountList) {
      if (day.date.toString() === calendarSlotsCount.date.toString()) {
        if (calendarSlotsCount.slotsCount && calendarSlotsCount.slotsCount > 0) {
          hasAlreadySelected = true;
          day.cssClass = 'cal-selected';
          day.badgeTotal = calendarSlotsCount.slotsCount;
        }
      }
    }

    if (this.calendarLoaded && hasAlreadySelected === false && calendarPreselectedDate) {
      if (day.date.getTime() === calendarPreselectedDate.getTime()) {
        if (
          day.isWeekend === false &&
          day.isPast === false &&
          !this.preselectedEventTriggered
        ) {
          this.preselectedEventTriggered = true;
          day.cssClass = 'cal-selected';
          this.onDateSelect({ day });
        }
      }
    }

    if (day.cssClass === 'cal-selected') {
      return of({
        'color': 'white',
        'background-color': this.widgetColorConf.widget_header_active_color
      }).pipe(delay(500), switchMap((value: any) => of(value)));
    } else {
      if (day.isWeekend) {
        return of({
          'color': ''
        }).pipe(delay(500), switchMap((value: any) => of(value)));
      } else {
        return of({
          'color': this.widgetColorConf.widget_text_color
        }).pipe(delay(500), switchMap((value: any) => of(value)));
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }
}
