import { NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, numberAttribute } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ControlContainer, FormsModule, NgForm, NgModel, ValidationErrors } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, debounceTime, finalize, of, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { MeetingTypeImages } from '../../../constants/meeting-type-images.constants';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { CUSTOMER_CSTM_FIELD_TITLE_LIST, MEETING_TYPE_ID, REGEX, SALUTATION_TYPES, WIDGET_CONSTANTS } from '../../../constants/widget-constants';
import { StoresDao } from '../../../db-models/stores-dao';
import { CustomBookingMessageTemplate } from '../../../db-models/widget-conf-dao';
import { CartItem } from '../../../models/cart.model';
import { CountryDbModel } from '../../../models/country.model';
import { GlobalObjects, Partner } from '../../../models/global';
import { MeetingTypeModel } from '../../../models/meeting-types.model';
import { AppointmentState, UserState } from '../../../models/state.model';
import { CaptchaService } from '../../../services/captcha.service';
import { CountryHelperService } from '../../../services/country-helper.service';
import { FormsService } from '../../../services/forms.service';
import { LoggerService } from '../../../services/logger.service';
import { RefDataService } from '../../../services/ref-data.service';
import { UtilService } from '../../../services/util.service';
import { CountrySelectComponent } from '../../../shared/components/country-select/country-select.component';
import { CalioAddRequiredPipe } from '../../../shared/pipes/calio-add-required.pipe';
import { CalioMeetingTemplatePipe } from '../../../shared/pipes/calio-meeting-template.pipe';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';
import { CwCheckboxComponent } from '../theme/cw-checkbox/cw-checkbox.component';
import { CwIntlTelInputComponent } from '../theme/cw-intl-tel-input/cw-intl-tel-input.component';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [NgSelectModule, FormsModule, NgClass, CwIntlTelInputComponent, CountrySelectComponent, CwCheckboxComponent, TranslateModule, TranslationPipe, TrustHtmlPipe, CalioMeetingTemplatePipe, CalioAddRequiredPipe, FontAwesomeModule, NgTemplateOutlet]
})
export class CustomerFormComponent implements OnInit, AfterViewInit {

  @Input({
    transform: numberAttribute
  }) index: number;
  @Input() customerRequiredFields: string[] = [];
  @Input() customerFormFields: any[] = [];
  @Input() userState: UserState;
  @Input() globals: GlobalObjects;
  @Input() partner: Partner;
  @Input() userDataLSNameKey: string;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() type: string;
  @Input() lang: string;
  @Input() meetingTypes: MeetingTypeModel[];
  @Input() appointmentCart?: CartItem[] = [];
  @Input() notificationTypes: any[] = [];
  @Input() showBeforeCustomField = false;
  @Input() showAfterCustomField = false;
  @Input() appointmentState: AppointmentState;
  @Input() stores: StoresDao[] = [];

  @ViewChild('phoneNumber') phoneNumberModel: NgModel;
  @ViewChild('mobileNumber') mobileNumberModel: NgModel;
  @ViewChild('smsPhoneCodeModel') smsPhoneCodeModel: NgModel;
  @ViewChild('smsMobileCodeModel') smsMobileCodeModel: NgModel;
  @ViewChild('captcha') captchaModel: NgModel;

  readonly salutationTypes = SALUTATION_TYPES;
  readonly titleTypes = CUSTOMER_CSTM_FIELD_TITLE_LIST;
  readonly meetingTypeImages = MeetingTypeImages;
  readonly meetingTypeId = MEETING_TYPE_ID;
  readonly zipRegex = REGEX.ZIP;
  readonly templateContent = TRANSLATION_TEMPLATES;

  appointmentConstant = APPOINTMENT_CONSTANT;
  currentLanguage: string;
  environment: any;
  fieldTypes: { type: string, value: any }[] = [];
  showPhoneSmsCodeButton = false;
  showPhoneSmsCodeInputField = false;
  smsPhoneButtonDisabled = false;
  smaPhoneButtonLoading = false;
  smsPhoneCode: number;
  showPhoneNumberVerifiedMessage = false;
  showPhoneSmsCodeVerifiedMessage = false;
  showMobileSmsCodeButton = false;
  showMobileSmsCodeInputField = false;
  smsMobileButtonDisabled = false;
  smaMobileButtonLoading = false;
  smsMobileCode: number;
  showMobileNumberVerifiedMessage = false;
  showMobileSmsCodeVerifiedMessage = false;
  smsResendForPhone = false;
  smsResendForMobile = false;
  countries: CountryDbModel[] = [];
  selectedStore: StoresDao = null;
  validatingCaptchaChallenge = false
  captchaValid = false;

  constructor(
    private utilService: UtilService,
    private formService: FormsService,
    private translate: TranslateService,
    private countryHelperService: CountryHelperService,
    private refDataService: RefDataService,
    private captchaService: CaptchaService,
    private el: ElementRef
  ) {
    this.environment = environment;
    this.translate.onLangChange.subscribe(language => {
      this.lang = language.lang;
      this.countries = this.countryHelperService.findCountriesByDefaultLanguage(this.countries, language.lang);
    });
  }

  ngOnInit(): void {
    // Load captcha only if widget type is other than events
    if (![WIDGET_CONSTANTS.EVENT, WIDGET_CONSTANTS.EVENTS].includes(this.type)) {
      this.checkForCaptcha();
    }

    if (this.customerFormFields?.length > 0) {
      const customFieldIds: number[] = [];
      this.fieldTypes = this.customerFormFields.map((item: any) => {
        if (typeof item === 'number') {
          customFieldIds.push(item);
          return { type: 'CUSTOM_FIELD', value: item };
        } else {
          return { type: item, value: item };
        }
      });
    }

    if (this.globals?.isInternal) {
      // when isInternal is true but internal_comment is disabled then
      // set internal_comment to null to be sent when it passed in URL
      if (
        this.partner?.widget_internal_comment_shown === 0 ||
        this.partner?.widget_internal_comment_shown === undefined
      ) {
        this.userState.internal_comment = null;
      }
    } else {
      // set internal_comment as null to prevent
      // it to be sent when it passed in URL and internal is false
      this.userState.internal_comment = null;
    }

    this.partner?.widget_country_shown === 1 && this.getCountries();
    this.meetingTypes?.length && this.manageDisable();
    this.findSelectedStore();
  }


  ngAfterViewInit(): void {
    this.focusFirstElement();
  }

  private focusFirstElement(): void {
    // Get all focusable form elements in the DOM within the current component's view
    const focusableElements: NodeListOf<HTMLElement> = this.el.nativeElement.querySelectorAll('input, textarea, ng-select');
    // Focus the first element found
    LoggerService.log('[Debug] Auto focus will be set to this element', focusableElements?.[0]);
    focusableElements?.[0]?.focus();
  }

  private checkForCaptcha(): void {
    this.appointmentState.captcha = undefined;
    this.userState.captcha_challenge = '';

    if (Number(this.partner?.is_captcha_feature_enabled) === 1) {
      this.captchaService.generateCaptcha().subscribe({
        next: (data) => {
          if (data) {
            this.appointmentState.captcha = data;
            setTimeout(() => {
              this.captchaModel?.control.addAsyncValidators(this.captchaValidator());
            }, 500);
          }
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
    }
  }

  private captchaValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.validatingCaptchaChallenge = true;

      return this.captchaService.validateCaptcha(this.appointmentState.captcha.uuid, control.value).pipe(
        debounceTime(2000),
        switchMap(result => {
          control.markAsTouched();
          if (!result) {
            return of({ invalidCaptcha: true });
          }
          return of(null);
        }),
        finalize(() => this.validatingCaptchaChallenge = false)
      );
    };
  }

  private manageDisable(): void {
    this.meetingTypes.forEach(meeting => {
      meeting.disabled = this.isMeetingTypeDisabled(meeting);
    });
  }

  private findSelectedStore(): void {
    const selectedStoreId = this.appointmentState?.store;
    if (selectedStoreId) {
      this.selectedStore = this.stores.find(store => store.id === selectedStoreId);
    }
  }

  private getCountries(): void {
    this.refDataService.getCountries().subscribe({
      next: countries => {
        if (countries) {
          this.countries = this.countryHelperService.findCountriesByDefaultLanguage(countries, this.currentLanguage);

          // Set country in user state when country_id is prefill
          if (this.userState?.country_id) {
            this.userState.country = this.countries.find(country => country.id === this.userState.country_id);
          }
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  onChangeUserData(country: CountryDbModel): void {
    this.userState.country = country;
    this.updateUserData();
  }

  onMeetingTypeChange(meetingType: MeetingTypeModel): void {
    this.userState.meetingType = meetingType;
  }

  updateUserData(): void {
    this.userDataLSNameKey && this.utilService.updateLocalStorage(
      this.userDataLSNameKey, Object.assign({}, this.userState)
    );
  }

  onEmailChange(eMailModel: NgModel): void {
    if (!this.globals?.isInternal) {
      if (this.partner?.widget_email_shown === 1) {
        if (this.partner?.widget_email_required === 1) {
        } else {
          if (eMailModel.value === '' || eMailModel.value === null || eMailModel.value === undefined) {
            eMailModel.control.markAsUntouched();
          }
        }
      }
    }

    if (this.globals?.isInternal) {
      if (this.partner?.internal_widget_email_shown === 1) {
        if (this.partner?.internal_widget_email_required === 1) {
        } else {
          if (eMailModel.value === '' || eMailModel.value === null || eMailModel.value === undefined) {
            eMailModel.control.markAsUntouched();
          }
        }
      }
    }

    this.updateUserData();
  }

  requestSmsCode(mobile: string, isResend: boolean, type: "phone" | "mobile"): void {
    this.formService.requestSmsCode(mobile).subscribe({
      next: (result: { requested: boolean, count: number } | { errors: { message: string, code: number }[] }) => {

        if ((<{ errors: { message: string, code: number }[] }>result)?.errors?.length > 0) {
          if (isResend) {
            if (type === "phone") {
              this.smsPhoneCodeModel.control.markAsTouched();
              this.smsPhoneCodeModel.control.setErrors({ exceededSmsLimits: true });
            } else if (type === "mobile") {
              this.smsMobileCodeModel.control.markAsTouched();
              this.smsMobileCodeModel.control.setErrors({ exceededSmsLimits: true });
            }
          } else {
            if (type === "phone") {
              this.phoneNumberModel.control.setErrors({ exceededSmsLimits: true });
            } else if (type === "mobile") {
              this.mobileNumberModel.control.setErrors({ exceededSmsLimits: true });
            }
          }
        } else {
          if (result && (<{ requested: boolean, count: number }>result).requested) {
            if (type === "phone") {
              this.showPhoneSmsCodeInputField = true;
              this.showPhoneSmsCodeButton = true;
              this.smsPhoneButtonDisabled = true;
            } else if (type === "mobile") {
              this.showMobileSmsCodeInputField = true;
              this.showMobileSmsCodeButton = true;
              this.smsMobileButtonDisabled = true;
            }

            if (isResend) {
              if (type === 'phone') {
                this.smsResendForPhone = true;
              } else if (type === 'mobile') {
                this.smsResendForMobile = true;
              }

              setTimeout(() => {
                this.smsResendForPhone = false;
                this.smsResendForMobile = false;
              }, 5000);
            }
          }
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  };

  onBlurPhoneNumber(type: "phone" | "mobile"): void {
    if (type === "phone") {
      this.showPhoneSmsCodeButton = false;
      this.showPhoneSmsCodeInputField = false;
      this.smsPhoneButtonDisabled = false;
      this.showPhoneNumberVerifiedMessage = false;
      this.showPhoneSmsCodeVerifiedMessage = false;
      this.smsPhoneCode = undefined;
    } else if (type === "mobile") {
      this.showMobileSmsCodeButton = false;
      this.showMobileSmsCodeInputField = false;
      this.smsMobileButtonDisabled = false;
      this.showMobileNumberVerifiedMessage = false;
      this.showMobileSmsCodeVerifiedMessage = false;
      this.smsMobileCode = undefined;
    }

    if (
      type === "phone" &&
      this.userState.phone &&
      this.phoneNumberModel.errors?.invalidNumber === undefined &&
      this.partner.activate_phone_number_verification_feature === 1
    ) {
      LoggerService.log('verificaiton is needed');
      this.verifyPhoneNumber(this.userState.phone, type);
    }

    if (
      type === "mobile" &&
      this.userState.mobile &&
      this.mobileNumberModel.errors?.invalidNumber === undefined &&
      this.partner.activate_mobile_number_verification_feature === 1
    ) {
      LoggerService.log('verificaiton is needed');
      this.verifyPhoneNumber(this.userState.mobile, type);
    }
  }

  verifyPhoneNumber(mobile: string, type: "phone" | "mobile"): void {
    if (type === "phone") {
      this.showPhoneSmsCodeButton = false;
      this.smaPhoneButtonLoading = true;
      this.smsPhoneCode = undefined;
    } else if (type === "mobile") {
      this.showMobileSmsCodeButton = false;
      this.smaMobileButtonLoading = true;
      this.smsMobileCode = undefined;
    }
    this.formService.verifyPhoneNumber(mobile).subscribe({
      next: (result: { verified: boolean, count: number }) => {
        if (result?.verified) {
          if (type === "phone") {
            this.smaPhoneButtonLoading = false;
            this.showPhoneNumberVerifiedMessage = true;
          } else if (type === "mobile") {
            this.smaMobileButtonLoading = false;
            this.showMobileNumberVerifiedMessage = true;
          }
          this.updateUserData();
        } else {
          if (type === "phone") {
            this.smaPhoneButtonLoading = false;
            this.phoneNumberModel.control.setErrors({ verficationNeeded: true });
            this.showPhoneSmsCodeButton = true;
            this.smsPhoneButtonDisabled = false;
          } else if (type === "mobile") {
            this.smaMobileButtonLoading = false;
            this.mobileNumberModel.control.setErrors({ verficationNeeded: true });
            this.showMobileSmsCodeButton = true;
            this.smsMobileButtonDisabled = false;
          }
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  confirmSmsCode(mobile: string, verification_code: number, type: "phone" | "mobile"): void {
    this.formService.confirmSmsCode(mobile, verification_code).subscribe({
      next: (result: { verified: boolean, count: number }) => {
        if (result?.verified) {
          if (type === "phone") {
            this.showPhoneSmsCodeVerifiedMessage = true;
            this.phoneNumberModel.control.setValidators([]);
            this.phoneNumberModel.control.updateValueAndValidity();
            this.smsPhoneCodeModel.control.setValidators([]);
            this.smsPhoneCodeModel.control.updateValueAndValidity();
            this.showPhoneSmsCodeInputField = false;
            this.showPhoneSmsCodeButton = false;
            this.smsPhoneCode = undefined;
          } else if (type === "mobile") {
            this.showMobileSmsCodeVerifiedMessage = true;
            this.mobileNumberModel.control.setValidators([]);
            this.mobileNumberModel.control.updateValueAndValidity();
            this.smsMobileCodeModel.control.setValidators([]);
            this.smsMobileCodeModel.control.updateValueAndValidity();
            this.showMobileSmsCodeInputField = false;
            this.showMobileSmsCodeButton = false;
            this.smsMobileCode = undefined;
          }
        } else {
          if (type === "phone") {
            this.smsPhoneCodeModel.control.setErrors({ validCodeNeeded: true });
          } else if (type === "mobile") {
            this.smsMobileCodeModel.control.setErrors({ validCodeNeeded: true });
          }
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  smsCodeVerification(event: KeyboardEvent, type: "phone" | "mobile"): boolean {
    const numReg = new RegExp('^[0-9]$');
    if (numReg.test(event.key)) {
      if (this.smsPhoneCode && (this.smsPhoneCode + '').length >= 6 && type === 'phone') {
        return false;
      } else if (this.smsMobileCode && (this.smsMobileCode + '').length >= 6 && type === 'mobile') {
        return false;
      }
      return true;
    }
    // Only ASCII charactar in that range allowed
    const ASCIICode = (event.which) ? event.which : event.keyCode;

    if (ASCIICode === 8) {
      return true;
    }

    if (ASCIICode === 65 && event.metaKey) {
      return true;
    }

    return false;
  }

  isMeetingTypeDisabled(meetingType: MeetingTypeModel): boolean {
    if (
      this.type === APPOINTMENT_CONSTANT.APPOINTMENT &&
      meetingType.id === 10 &&
      this.appointmentCart?.length
    ) {
      for (const cartItem of this.appointmentCart) {
        const duration = Math.round(cartItem.total.duration * 60);

        if (duration < 10) {
          return true;
        }
      }
    }

    return false;
  }

  toggleDataPolicy(event: any): void {
    this.userState.acceptDataPolicy = event.event.target.checked;
  }
}
