<!-- Fullscreen overlay for saferpay payment gateway to when payment is ongoing  -->
@if (showFullscreenOverlay) {
  <div class="overlay text-center">
    <div class="content">
      <p class="font-weight-light"
        innerHtml="{{ ('summary_page_translations.saferpay_payment_overlay' | translate) | trustHtml }}"
      ></p>
      <button #button class="btn btn-lg btn-warning mt-2" (click)="closeSaferpayOverlay()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </div>
}

<div class="compact-widget-wrapper cbcw-widget-wrapper" #cbcwWidgetWrapper>
  <ng-container
    [ngTemplateOutlet]="languageSwitcher"
    [ngTemplateOutletContext]="{ 'mobileSwitcher': true }"
  />

  @if (!showSubscriptionError && !noServiceFound && worker) {
    @if (!booked) {
      <form #appointmentForm="ngForm" onclick="void(0)">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-12 compact-top-section-wrapper"
              [ngClass]="{
                'col-lg-4': (appointmentState.date !== null && currentView !== appointmentConstant.PAYMENT),
                'col-lg-6': (appointmentState.date === null || currentView === appointmentConstant.PAYMENT)
              }"
            >
              <div class="left-side cbcw-left-side">
                <div class="sidebar-wrapper">
                  @if (
                    (currentView === appointmentConstant.PERSONAL_INFO || currentView === appointmentConstant.PAYMENT) &&
                    !backButtonDisabled
                  ) {
                    <div class="back-button-wrapper cbcw-back-button-wrapper">
                      <button class="back-button cbcw-back-button" (click)="goBack()">
                        <fa-icon class="icon" icon="chevron-left" />
                        <span class="icon-text">
                          {{ (currentView === appointmentConstant.PERSONAL_INFO ? 'compact.navigation.date_slots' : 'summary_page_translations.personal_form_title') | translate }}
                        </span>
                      </button>
                    </div>
                  }
                  <div
                    class="partner-section cbcw-partner-section"
                    [ngClass]="{
                      'compact-mobile-view-handles': appointmentConstant.PERSONAL_INFO === currentView ? false : appointmentState?.date !== null
                    }"
                  >
                    <img
                      #partnerLogo
                      fetchPriority="high"
                      src="{{ logoBaseUrl + partner?.uuid + '/' + partner?.logo }}"
                      alt="{{ partner?.name }}"
                      title="{{ partner?.name }}"
                      (error)="partnerLogo.src= baseUrl + '/img/sample-avatar.png'"
                    />
                  </div>
                  <div
                    class="worker-section cbcw-worker-section"
                    [ngClass]="{
                      'compact-mobile-view-handles': appointmentConstant.APPOINTMENT === currentView && appointmentState?.date !== null,
                      'payment-view': appointmentConstant.PAYMENT === currentView
                    }"
                  >
                    <div
                      class="user-wrapper cbcw-user-wrapper"
                      [ngClass]="{
                        'compact-mobile-view-handles': appointmentState?.date !== null
                      }"
                    >
                      <img
                        #workerAvatar
                        class="worker-profile-photo cbcw-worker-profile-photo"
                        fetchPriority="high"
                        src="{{ workerAvatarUrl + worker?.uuid + '/thumb_' + worker?.avatar }}"
                        alt="{{ worker?.resource_name }}"
                        title="{{ worker?.resource_name }}"
                        (error)="workerAvatar.src= baseUrl + '/img/sample-avatar.png'"
                      />
                      <div>
                        <p
                          class="worker-name cbcw-worker-name"
                          [ngClass]="{
                            'compact-mobile-view-handles': appointmentState?.date !== null
                          }"
                        >
                          {{ worker?.resource_name }}
                        </p>
                        @if ((worker | translation: 'job_title': lang); as jobTitle) {
                          <p
                            class="worker-function cbcw-worker-function"
                            [ngClass]="{
                              'compact-mobile-view-handles': appointmentState?.date !== null
                            }"
                          >
                            {{ jobTitle }}
                          </p>
                        }
                      </div>
                    </div>
                    <!-- service -->
                    @if (service) {
                      <!-- Service name -->
                      <p class="service-name cbcw-service-name">
                        {{ service | translation: 'name' : lang }}
                      </p>
                      <!-- Service duration -->
                      <p class="service-duration cbcw-service-duration">
                        <fa-icon class="icon me-2" icon="clock" />
                        @if ((service.duration | replaceComma | toHour) > 0) {
                          <span class="me-1">
                            {{ service.duration | replaceComma | toHour }}{{ 'common.durationHour' | translate }}
                          </span>
                        }
                        @if ((service.duration | replaceComma | toMinute) > 0) {
                          <span>
                            {{ service.duration | replaceComma | toMinute}}{{ 'common.durationMinute' | translate }}
                          </span>
                        }
                      </p>
                      <!-- Service description -->
                      @if (service?.description && currentView === appointmentConstant.APPOINTMENT) {
                        <p class="service-description  cbcw-service-description">
                          {{ service | translation: 'description' : lang }}
                        </p>
                      }
                      <!-- Active block when user is on personal info screen -->
                      @if (
                        (currentView === appointmentConstant.PERSONAL_INFO || currentView === appointmentConstant.PAYMENT) &&
                        cart?.length > 0
                        ) {
                        <!-- appointment duration  -->
                        <p class="appointment-duration cbcw-appointment-duration">
                          <fa-icon class="icon me-2" icon="calendar-check" />
                          {{ cart[0].date | date: 'dd.MM.y' }}, {{ cart[0].startTime | dateutc: "HH:mm" }} - {{ cart[0].endTime | dateutc: "HH:mm" }}
                          &nbsp;
                          <a routerLink="" class="remove-link" (click)="removeAppointmentFromCart(cart[0].cartItemId)">
                            ({{ 'buttons.remove' | translate }})
                          </a>
                        </p>
                        <!-- appointment price  -->
                        @if (
                          userState.meetingType &&
                          (widgetTemplates | calioMeetingTemplate: {
                          id: userState.meetingType.id,
                          type: 'meeting_template'
                          }); as widgetTemplate) {
                          <div class="appointment-meeting-type-wrapper cbcw-appointment-meeting-type-wrapper">
                            <div class="d-flex align-items-center">
                              @if (userState.meetingType.id === 1) {
                                <img src="{{ baseUrl }}/images/meetings/store.svg" alt="Store" width="18px">
                              } @else if (userState.meetingType.id === 2) {
                                <img src="{{ baseUrl }}/images/meetings/zoom.svg" alt="Zoom" width="18px">
                              } @else if (userState.meetingType.id === 3) {
                                <img src="{{ baseUrl }}/images/meetings/calenso.svg" alt="Calenso" width="18px">
                              } @else if (userState.meetingType.id === 4) {
                                <img src="{{ baseUrl }}/images/meetings/phone.svg" alt="Phone" width="16px">
                              } @else if (userState.meetingType.id === 5) {
                                <img src="{{ baseUrl }}/images/meetings/customer.svg" alt="Customer" width="18px">
                              } @else if (userState.meetingType.id === 6) {
                                <img src="{{ baseUrl }}/images/meetings/unblu.svg" alt="Unblu" width="18px">
                              } @else if (userState.meetingType.id === 7) {
                                <img src="{{ baseUrl }}/images/meetings/teams.svg" alt="Teams" width="18px">
                              } @else if (userState.meetingType.id === 8) {
                                <img src="{{ baseUrl }}/images/meetings/meet.svg" alt="Meet" width="18px">
                              } @else if (userState.meetingType.id === 9) {
                                <img src="{{ baseUrl }}/images/meetings/gotomeeting.png" alt="Gotomeeting" width="18px">
                              } @else if (userState.meetingType.id === 10) {
                                <img src="{{ baseUrl }}/images/meetings/webex.png" alt="Webex" width="18px">
                              } @else if (userState.meetingType.id === 11) {
                                <img src="{{ baseUrl }}/images/meetings/phone.svg" alt="Phone" width="16px">
                              }

                              <div class="appointment-meeting-type cbcw-appointment-meeting-type"
                                [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                              ></div>
                            </div>
                            @if (userState.meetingType.id === 1 && cart[0]) {
                              <div class="cbcw-appointment-meeting-type-location">
                                <span class="cbcw-appointment-meeting-type-location-name">
                                  {{ cart[0].store | translation: 'name' : lang }}
                                </span>
                                @if (cart[0].store.is_online === 0) {
                                  <span class="cbcw-appointment-meeting-type-location-separator">,&nbsp;</span>
                                  <span class="cbcw-appointment-meeting-type-location-address">
                                    @if (cart[0].store.street) {
                                      {{cart[0].store.street }},&nbsp;
                                    }
                                    @if (cart[0].store.zip) {
                                      {{ cart[0].store.zip }}&nbsp;
                                    }
                                    @if (cart[0].store.city) {
                                      {{ cart[0].store.city }}
                                    }
                                    @if (cart[0].store) {
                                      ,&nbsp;{{ cart[0].store.country | translation: 'name' : lang
                                      }}
                                    }
                                  </span>
                                }
                              </div>
                            }
                          </div>
                        } @else {
                          @if (userState?.meetingType?.id) {
                            <div [innerHTML]="(userState.meetingType | translation: 'name' : lang) | trustHtml"></div>
                          }
                        }
                        <!-- Appointment price  -->
                        @if (currentView === appointmentConstant.PERSONAL_INFO) {
                          @if (cart[0]?.total?.price > 0) {
                            <p class="appointment-price cbcw-appointment-price">
                              @if (cart[0].total.isPriceFromActivated) {
                                <span>
                                  {{ 'common.price_from_label' | translate }}
                                </span>
                              }
                              {{ cart[0].total.price | calioCurrency: partner?.currency }}
                              @if (service?.tax?.rate > 0) {
                                <span class="taxes cbcw-taxes">
                                  {{ 'tax_info.taxes' | translate: { percentage: service?.tax?.rate } }}
                                </span>
                              }
                            </p>
                          }
                          @if (cart[0].total.price === 0) {
                            <p class="appointment-price cbcw-appointment-price">
                              {{ 'free' | translate }}
                            </p>
                          }
                        }
                        @if (currentView === appointmentConstant.PAYMENT) {
                          @if (totalCartPrice > 0) {
                            <p class="appointment-price cbcw-appointment-price">
                              {{ totalCartPrice | calioCurrency: partner?.currency }}
                            </p>
                          }
                          @if (totalCartPrice  === 0) {
                            <p class="appointment-price cbcw-appointment-price">
                              {{ 'free' | translate }}
                            </p>
                          }
                          @if (coupon) {
                            <p class="cart-coupon cbcw-cart-coupon">
                              {{ 'coupon_credit_card_info.coupon' | translate }} ({{ coupon.code }})
                              <span>
                                - {{ finalCouponDiscount | calioCurrency: partner?.currency }}
                              </span>
                            </p>
                          }
                        }
                      }
                    }
                    @if (!service) {
                      <div class="service-name-ghost skeleton-animation cbcw-service-name-ghost">
                        <div class="bar bar-1 mb-3"></div>
                        <div class="bar bar-2 mb-3"></div>
                        <div class="bar bar-3"></div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 h-100"
              [ngClass]="{
                'col-lg-6': (appointmentState.date === null || currentView === appointmentConstant.PAYMENT),
                'col-lg-8': (appointmentState.date !== null && currentView !== appointmentConstant.PAYMENT)
              }"
            >
              <div
                class="right-side cbcw-right-side"
                [ngClass]="{
                  'compact-mobile-view-container-margins': appointmentConstant.PERSONAL_INFO === currentView ? true : appointmentState?.date === null,
                  'webc-padding': !isFrame
                }"
              >
                @if (service) {
                  <div
                    class="compact-cal-slot-wrapper step-header cbcw-step-header"
                    [ngClass]="{
                      'compact-mobile-view-handles': appointmentConstant.PERSONAL_INFO === currentView ? false : appointmentState?.date !== null
                    }"
                  >
                    <label class="step-name cbcw-step-name">
                      @switch (currentView) {
                        @case (appointmentConstant.APPOINTMENT) {
                          {{ 'appointments.step3.chooseDate' | translate }}
                        }
                        @case (appointmentConstant.PERSONAL_INFO) {
                          @if ((widgetTemplates?.length > 0)) {
                            {{ widgetTemplates | calioMeetingTemplate: { id: 120, type: 'template' } | translation:
                            templateContent.TEMPLATE_CONTENT: lang }}
                          }
                        }
                        @case (appointmentConstant.PAYMENT) {
                          @if ((widgetTemplates?.length > 0)) {
                            {{ widgetTemplates | calioMeetingTemplate: { id: 122, type: 'template' } | translation:
                            templateContent.TEMPLATE_CONTENT: lang }}
                          }
                        }
                      }
                    </label>
                    <ng-container
                      [ngTemplateOutlet]="languageSwitcher"
                      [ngTemplateOutletContext]="{ 'mobileSwitcher': false }"
                    />
                  </div>

                  @if (currentView === appointmentConstant.APPOINTMENT) {
                    @if (appointmentReservationError) {
                      <div
                        class="reservation-error-message cbcw-reservation-error-message reserve-alert-mobile-margins my-2"
                        [ngClass]="{
                          'reserve-alert-mobile-margins': appointmentConstant.PERSONAL_INFO === currentView ? false : appointmentState?.date !== null
                        }"
                      >
                        {{ 'alert_messages.slot_already_reserved_by_other_error' | translate }}
                      </div>
                    }

                    <div class="appointment-slots-section cbcw-appointment-slots-section">
                      <app-new-calendar-picker
                        class="w-100"
                        [ngClass]="{
                          'compact-mobile-view-handles': appointmentConstant.PERSONAL_INFO === currentView ? false : appointmentState?.date !== null
                        }"
                        [calendarLoaded]="calendarLoaded"
                        [calendarPreselectedDate]="calendarPreselectedDate"
                        [days]="days"
                        [disablePrev]="disablePrev"
                        [excludeWeekDays]="excludeWeekDays"
                        [lang]="lang"
                        [maxCalendarAvailabilityDate]="maxCalendarAvailabilityDate"
                        [viewDate]="viewDate"
                        [widgetColorConf]="widgetColorConf"
                        (loadCalendarWithAvailabilitiesEvent)="loadCalendarWithAvailabilities($event)"
                        (onDateSelectEvent)="onDateSelect($event)"
                        (viewDateChangedEvent)="viewDateChanged($event)"
                      />

                      @if (showAvailableAppointments) {
                        <app-compact-slots
                          class="slots-section cbcw-slots-section"
                          [appointments]="freeAppointments"
                          [appointmentState]="appointmentState"
                          [cart]="cart"
                          [lang]="lang"
                          [noAppointments]="noAppointments"
                          [showAppointmentSlotGhostElement]="showAppointmentSlotGhostElement"
                          [widgetTemplates]="widgetTemplates"
                          (updateCartEvent)="updateCart($event)"
                          (goBackEvent)="goBack(appointmentConstant.APPOINTMENT)"
                        />
                      }
                    </div>
                  }

                  @if (currentView === appointmentConstant.PERSONAL_INFO) {
                    <app-compact-personal-form
                      [appointmentState]="appointmentState"
                      [appointmentCart]="cart"
                      [customFields]="appointmentCustomFields"
                      [(customFieldValues)]="customFieldValues"
                      [customer_notification_preference]="customer_notification_preference"
                      [selectedJsonCustomerDetail]="selectedJsonCustomerDetail"
                      [selectedJsonCustomFields]="selectedJsonCustomFields"
                      [globals]="globals"
                      [isCustomFieldsLoaded]="isCustomFieldsLoaded"
                      [meeting_type_id]="meeting_type_id"
                      [(userState)]="userState"
                      [lang]="lang"
                      [partner]="partner"
                      [userDataLSNameKey]="USER_LS_NAME"
                      [customFieldValuesLSNameKey]="CUSTOMFIELDS_LS_NAME"
                      [store]="[store]"
                      [widgetTemplates]="widgetTemplates"
                      [widgetType]="widgetConstants.COMPACT"
                      (updateUserStateEvent)="updateUserState($event)"
                      (updateCustomerSelectedEvent)="updateCustomerSelected($event)"
                    />
                    @if (totalCartPrice > 0 && isServiceOnlinePaymentEnabled === 1) {
                      <calenso-button
                        class="ms-2"
                        #button
                        id="cbw-booking-finish"
                        (clickEvent)="book()"
                        customCss="cw-ml-auto"
                        [disabled]="disableBookingButton"
                        [widgetColorConf]="widgetColorConf"
                        [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                      />
                    } @else {
                      @if (!hideBookingButton) {
                        @if (showSaferpayButtonLink) {
                          <calenso-button
                            class="ms-2"
                            #button
                            id="cbw-booking-finish"
                            (clickEvent)="payUsingSaferpay()"
                            [widgetColorConf]="widgetColorConf"
                            customCss="cw-ml-auto"
                            [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                          />
                        }
                        @if (!showSaferpayButtonLink) {
                          <calenso-button
                            class="ms-2"
                            #button
                            id="cbw-booking-finish"
                            (clickEvent)="book()"
                            [disabled]="disableBookingButton"
                            [widgetColorConf]="widgetColorConf"
                            customCss="cw-ml-auto"
                            [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                          />
                        }
                      }
                    }
                  }

                  @if (currentView === appointmentConstant.PAYMENT) {
                    <!-- Block for showing error message from API -->
                    @if (bookingErrorMessage) {
                      <div>
                        <app-alert text="{{ bookingErrorMessage }}" type="danger" />
                      </div>
                    }
                    <!-- Block for showing a warning message when payment type is paypal -->
                    @if (paymentType === paymentTypes.PAYPAL && !paypalOrderDetail) {
                      <div>
                        <app-alert text="{{ 'summary_page_translations.paypal_warning_msg' | translate }}" type="warning" />
                      </div>
                    }

                    <app-cw-payment-methods
                      (changePaymentTypeEvent)="onChangePaymentType($event)"
                      (couponFailedEvent)="onCouponFailedEvent($event)"
                      (couponSuccessEvent)="onCouponSuccessEvent($event)"
                      (resetStripeTokenEvent)="onResetStripeTokenEvent($event)"
                      (stripeSuccessfulEvent)="onStripeSuccessfulEvent($event)"
                      (paypalSuccessfulEvent)="onPaypalSuccessfulEvent($event)"
                      (resetPaypalEvent)="onResetPaypalEvent($event)"
                      (saferpaySuccessfulEvent)="onSaferpaySuccessfulEvent($event)"
                      (resetSaferpayEvent)="onResetSaferpayEvent($event)"
                      [isStripeEnabled]="isStripeEnabled"
                      [isOnlinePaymentMandatory]="isOnlinePaymentMandatory"
                      [isBexioEnabled]="isBexioEnabled"
                      [isStoreEnabled]="isStoreEnabled"
                      [isPaypalEnabled]="isPaypalEnabled"
                      [isSaferpayEnabled]="isSaferpayEnabled"
                      [(paymentType)]="paymentType"
                      [partner]="partner"
                      [lang]="lang"
                      [widgetType]="widgetConstants.COMPACT"
                      [paypalSetting]="paypalSetting"
                      [priceAfterCouponReduction]="priceAfterCouponReduction"
                      [totalAmount]="totalCartPrice"
                      [courtesyForm]="courtesyForm"
                      [widgetTemplates]="widgetTemplates"
                      [appoinrmentServicesAvailable]="[service]"
                      [cart]="cart"
                    />

                    <ng-container *ngTemplateOutlet="finalBookingButton" />
                  }
                }

                <div class="d-flex justify-content-center">
                  <ng-container *ngTemplateOutlet="brandingCalensoLogo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    }

    @if (booked) {
      <div>
        <app-finalpage
          [lang]="lang"
          [errMsg]="'appointments.finalpage.errorMessage' | translate"
          [errTitle]="'appointments.finalpage.errorTitle' | translate"
          [successMsg]="successMessage"
          [success]="finalPage"
          [pageType]="appointmentConstant.APPOINTMENT"
          [widgetBookingInfo]="widgetBookingInfo"
          [manageAppointmentNowTemplate]="manageAppointmentNowTemplate"
          [downloadBookingDetailTemplate]="downloadBookingDetailTemplate"
          [successFullBookings]="successFullBookings"
          [successTitleTemplate]="successTitleTemplate"
        />
        <div class="additional-message cbcw-additional-message">
          @if (hideBookingAgainButton()) {
            <calenso-button
              #button
              text="{{ 'appointments.finalpage.bookNextAppointment' | translate }}"
              id="cbw-book-next-appointment-button"
              [widgetColorConf]="widgetColorConf"
              (clickEvent)="bookAppointmentAgain();"
            />
          }

          @if (
            partnerService.hasFeature('is_external_success_page_forwarding_enabled') &&
            partner?.is_redirect_after_booking_enabled &&
            partner?.successful_booking_redirect_url &&
            partner?.redirect_after_booking_action === bookingRedirectionViews.BOOKING_SUCCESS_VIEW &&
            !globals.isInternal
          ) {
            <calenso-button
              id="cbw-redirect-to-external-url-button"
              #button
              (clickEvent)="redirectToExternalUrl();"
              customCss="mt-2"
              [widgetColorConf]="widgetColorConf"
              text="{{ redirectionButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
            />
          }

          @if (!finalPage) {
            <calenso-button
              #button
              text="{{ 'appointments.finalpage.bookAppointmentAgain' | translate }}"
              id="cbw-book-appointment-again-button"
              [widgetColorConf]="widgetColorConf"
              (clickEvent)="bookAppointmentAgain();"
            />
          }

          <ng-container *ngTemplateOutlet="brandingCalensoLogo" />
        </div>
      </div>
    }
  }

  @if (!worker && !showSubscriptionError && !noServiceFound) {
    <app-new-alert
      type="danger"
      title="{{ 'common.workers_not_available' | translate }}"
      subTitle="{{ 'common.workers_not_available_description' | translate }}"
      type="warning"
      customClass="m-5"
    />
  }

  @if (showSubscriptionError) {
    <app-new-alert
      type="danger"
      title="{{ 'common.subscription_error_title' | translate }}"
      subTitle="{{ 'common.subscription_error_desc' | translate }}"
      customClass="m-5"
    />
  }

  @if (noServiceFound) {
    <app-new-alert
      type="danger"
      subTitle="{{ 'compact.no_service' | translate }}"
      customClass="m-5"
    />
  }
</div>

<ng-template #brandingCalensoLogo>
  @if (widgetConf && widgetConf?.subscription.subscription_type_id < widgetConstants?.SUBSCRIPTION_WHITELABEL_TYPE.id) {
    <div class="mt-4 mb-4">
      <img alt="Calenso" [src]="deployUrl + '/assets/images/' + footerLogoUrl" width="100">
    </div>
  }
</ng-template>

<ng-template #finalBookingButton>
  @if (!hideBookingButton) {
    @if (showSaferpayButtonLink) {
      <calenso-button
        class="ms-2"
        #button
        id="cbw-booking-finish"
        customCss="cw-ml-auto"
        [widgetColorConf]="widgetColorConf"
        [isloading]="bookButtonLoader"
        [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
        (clickEvent)="payUsingSaferpay()"
      />
    }
    @if (!showSaferpayButtonLink) {
      <calenso-button
        class="ms-2"
        #button
        id="cbw-booking-finish"
        customCss="cw-ml-auto"
        [disabled]="disableBookingButton"
        [widgetColorConf]="widgetColorConf"
        [isloading]="bookButtonLoader"
        [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
        (clickEvent)="book()"
      />
    }
  }
</ng-template>

<ng-template #languageSwitcher let-mobileSwitcher="mobileSwitcher">
  @if (langSwitcher && supportedWidgetLanguages?.length > 1) {
    <div
      ngbDropdown class="lang-dropdown-wrapper cbcw-lang-dropdown-wrapper"
      [ngClass]="{
      'mobile-view-only': mobileSwitcher
      }"
    >
      <button class="nav-link lang-dropdown" id="nav-lang-dropdown" ngbDropdownToggle>
        <span>
          @for (language of widgetConstants.LANGUAGES; track language) {
            @if (lang === language.locale) {
              <img width="20px" height="20px"
                alt="{{ language.text | titlecase }}"
                title="{{ language.text | titlecase }}"
                src="{{ deployUrl + language.flagIcon }}"
              />
            }
          }
        </span>
      </button>

      @if (supportedWidgetLanguages?.length > 1) {
        <div class="lang-dropdown-menu" ngbDropdownMenu aria-labelledby="nav-lang-dropdown">
          @for (language of widgetConstants.LANGUAGES; track language) {
            @if (lang !== language.locale && supportedWidgetLanguages && supportedWidgetLanguages.indexOf(language.value) > -1) {
              <button ngbDropdownItem class="lang-dropdown-menu-item" href="javascript:void(0)" (click)="switchLanguage(language.locale)">
                <span class="d-flex justify-content-center">
                  <img width="20" height="20"
                    alt="{{ language.text | titlecase }}"
                    title="{{ language.text | titlecase }}"
                    src="{{ deployUrl + language.flagIcon }}"
                  />
                </span>
              </button>
            }
          }
        </div>
      }
    </div>
  }
</ng-template>
