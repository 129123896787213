import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthTokenService } from '../services/auth-token.service';

export const AuthTokenInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authTokenService = inject(AuthTokenService);
  const authToken = authTokenService.getAuthToken();
  if (authToken) {
    const headers: { [name: string]: string } = {};
    headers['Authorization'] = `Bearer ${authToken}`;
    const tokenReq = request.clone({ setHeaders: headers });
    return next(tokenReq);
  }
  return next(request);
}