@if (workersAvailable.length > 0) {
  <div>
    <h4 class="cbw-resource-title">
      {{ widgetResourceHeadingTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
    </h4>
    <form #workerForm="ngForm">
      <div class="worker-container">
        <div class="row cbw-worker-card-container">
          @if (workersAvailable.length > 1 && showEgalWer === true) {
            <div class="col-lg-6 col-md-6 col-sm-6 calenso-col">
              <div class="row cbw-worker-card" (click)="selectWorker(0);" [ngClass]="{'cbw-active-worker-card': 0 === appointmentState.worker}">
                <div class="avatar col-3">
                  <img
                    alt="Worker Avatar"
                    class="cbw-avatar"
                    src="{{ baseUrl }}/img/sample-avatar.png"
                    />
                  </div>
                  <div class="cbw-detail_description col-6">
                    <div
                      class="cbw-worker-name"
                      [innerHTML]="(widgetAnyoneLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                    ></div>
                    <div
                      class="cbw-function"
                      [innerHTML]="(widgetAnyoneDescTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                    ></div>
                    @if (partner?.is_widget_anyone_exception_enabled === 1) {
                      <span class="cbw-exception" (click)="toggleException()">{{ 'appointments.step2.exception' | translate }}</span>
                    }
                  </div>
                  <div class="col-3 text-end">
                    <app-cw-radio-tick-button
                      [color]="0 === appointmentState.worker ? widgetColorConf.widget_header_active_color : ''"
                      [checked]="0 === appointmentState.worker"
                      />
                    </div>
                  </div>
                </div>
              }
              @for (worker of workersAvailable; track worker; let index = $index) {
                <div class="col-lg-6 col-md-6 col-sm-6 calenso-col position-relative">
                  @if (isExceptionEnabled) {
                    <div class="position-absolute exception-selection cbw-exception-selection">
                      <app-cw-checkbox
                        id="{{ 'cf-' + worker.id }}"
                        name="{{ 'cf-' + worker.id }}"
                        [(fieldNgModel)]="worker.checked"
                        (selectionChange)="onSelectCheckbox($event.event,worker.uuid)"
                      />
                    </div>
                  }
                  <div
                    class="row cbw-worker-card"
                    (click)="!isExceptionEnabled && selectWorker(worker.id);"
                    [ngClass]="{
                      'cbw-active-worker-card' : worker.id === appointmentState.worker,
                      'cbw-disabled-worker-card': isExceptionEnabled
                     }"
                  >
                    <div class="avatar col-3">
                      @if (worker.avatar !== null) {
                        <img
                          alt="Worker Avatar"
                          #image
                          class="cbw-avatar"
                          src="{{ workerAvatarUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                          (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                        />
                      }
                      @if (worker.avatar === null) {
                        <img
                          alt="Worker Avatar"
                          class="cbw-avatar"
                          src="{{ baseUrl }}/img/sample-avatar.png"
                        />
                      }
                    </div>

                    <div class="cbw-detail_description col-7">
                      <div class="cbw-worker-name">
                        @if (worker.is_resource === 0) {
                          {{ worker.resource_name }}
                        } @else {
                          {{ worker | translation: 'resource_name': lang }}
                        }
                      </div>

                      @if (worker.is_resource === 0) {
                        @if (worker.is_multi_language === 1 && (worker | translation: 'job_title': lang)) {
                          <div
                            class="cbw-function"
                            [innerHTML]="(worker | translation: 'job_title': lang) | trustHtml"
                          ></div>
                        }
                        @if (worker.is_multi_language === 0 && worker.job_title) {
                          <div
                            class="cbw-function"
                            [innerHTML]="worker.job_title | trustHtml"
                          ></div>
                        }
                      } @else {
                        @if (worker.is_multi_language === 1 && (worker | translation: 'description': lang)) {
                          <div
                            class="cbw-function"
                            [innerHTML]="(worker | translation: 'description': lang) | trustHtml"
                          ></div>
                        }
                        @if (worker.is_multi_language === 0 && worker.description) {
                          <div
                            class="cbw-function"
                            [innerHTML]="worker.description | trustHtml"
                          ></div>
                        }
                      }

                      @if (partner?.widget_show_resource_store_name === 1) {
                        <div class="cbw-worker-store-location">
                          <span class="cbw-worker-store-location-name">{{ worker.store | translation: 'name' : lang }}</span>
                          @if (worker.store.is_online === 0) {
                            <span class="cbw-worker-store-location-separator">,&nbsp;</span>
                            <span class="cbw-worker-store-location-address">
                              @if (worker.store.street) {
                                <span>{{ worker.store.street }}</span>
                              }
                              @if (worker.store.zip) {
                                <span>,&nbsp;{{ worker.store.zip }}</span>
                              }
                              @if (worker.store.city) {
                                <span>&nbsp;{{ worker.store.city }}</span>
                              }
                              @if (worker.store.country) {
                                <span>,&nbsp;{{ worker.store.country | translation: 'name' : lang }}</span>
                              }
                            </span>
                          }
                        </div>
                      }
                    </div>

                    <div class="col-2 text-end">
                      <app-cw-radio-tick-button
                        [color]="worker.id === appointmentState.worker ? widgetColorConf.widget_header_active_color : ''"
                        [checked]="worker.id === appointmentState.worker"
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
            </div>
            <div class="wizard-footer clearfix">
              <div class="row margin-x-0">
                <div class="col-4 d-flex align-items-center justify-content-start">
                  @if (subscriptionTypeId < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                    <div class="d-md-none">
                      <img
                        alt="Logo"
                        src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
                        width="100">
                      </div>
                    }
                  </div>
                  <div class="col-4 d-flex align-items-center justify-content-center">
                    @if (subscriptionTypeId < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                      <div class="d-none d-md-block">
                        <img
                          alt="Logo"
                          src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
                          width="100">
                        </div>
                      }
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-end next-btn-custom-right-padding">
                      <calenso-button
                        id="cbw-prev-button"
                        [text]="previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                        (clickEvent)="previousNavigation(appointmentConstant.APPOINTMENT)"
                        placement="left"
                        [widgetColorConf]="widgetColorConf"
                        />
                        <calenso-button
                          id="cbw-next-button"
                          class="ms-2"
                          [text]="nextButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                          (clickEvent)="navigateTo(appointmentConstant.DATE, appointmentState?.has_meeting_types_restrictions)"
                          [disabled]="appointmentState.worker === null"
                          placement="right"
                          [widgetColorConf]="widgetColorConf"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              }
