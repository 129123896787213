@if (calendarLoaded) {
  <div class="agenda-calendar cbw-agenda-calendar">
    <div class="agenda-calendar-header cbw-agenda-calendar-header">
      <div
        class="btn"
        [ngClass]="{ 'disabled': (currentDate | date: 'yyyy') === (selectedDate | date: 'yyyy')}"
        (click)="getPreviousYearSlots()"
      >
        <div class="prev-arrow-container">
          <fa-icon class="icon cbw-prev-arrow-button d-flex" icon="chevron-left" />
        </div>
      </div>
      <div class="year">{{ selectedDate | date: 'yyyy' }}</div>
      <div
        class="btn"
        [ngClass]="{ 'disabled': +(selectedDate | date: 'yyyy') >= +(maxDate | date: 'yyyy')}"
        (click)="getNextYearSlots()"
      >
        <div class="next-arrow-container" >
          <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-right" />
        </div>
      </div>
    </div>
    <div class="agenda-calendar-months-wrapper cbw-agenda-calendar-months-wrapper">
      <div class="agenda-calendar-months cbw-agenda-calendar-months">
        @for (month of monthsConst | keepOrderKeyvalue; track month.key; let index = $index) {
          <div
            class="month"
            [ngClass]="{
              'active': month.key === selectedMonth,
              'disabled': !_eventforYear[month.key]
            }"
            (click)="_eventforYear[month.key] && filterWithMonth(month.key)"
          >{{ 'shortMonths.' + month.value | translate }}</div>
        }
      </div>
    </div>
    <div class="agenda-slots cbw-agenda-slots">
      @if (noFreeSlots) {
        <div role="alert" class="no-bookable-error-message alert alert-warning cbw-agenda-no-bookable-error-message">
          {{ 'alert_messages.no_slots_years_reached_message' | translate: {year: (selectedDate | date:'yyyy' | translate)} }}
        </div>
      } @else {
        @for (months of _eventforYear | keepOrderKeyvalue; track $index) {
          <div>
            @if (!selectedMonth) {
              <div class="agenda-month-title cbw-agenda-month-title">{{ monthsConst[months.key] | translate }}</div>
            }
            @if ((selectedMonth === months.key) || !selectedMonth) {
              @for (dates of months.value | keepOrderKeyvalue; track $index) {
                <div class="agenda-date-title cbw-agenda-date-title">{{ dates.key | dateutc: dateFormat}}</div>
                <div class="agenda-slot cbw-agenda-slot">
                  @for (eventInfo of dates.value; track $index) {
                    <ng-container *ngTemplateOutlet="eventInformation; context: { $implicit: eventInfo }"/>
                  }
                </div>
              }
            }
          </div>
        }
      }
    </div>
  </div>

} @else {
  <ng-container *ngTemplateOutlet="agendaSkeleton"/>
}

<ng-template #eventInformation let-eventInfo>
  <ng-container class="form-input checkbox">
    @let displayWaitingDetails = eventInfo?.waitinglist_booking_count < eventInfo?.waitinglist_capacity; 
    @let displayRegularDetails = eventInfo?.regular_booking_count < eventInfo?.regular_capacity;

    <div class="label-container d-flex cbw-event-slot-card"
      [ngClass]="{'disabled-input': (!displayRegularDetails && !displayWaitingDetails) || isSlotBooked( eventInfo.id )}"
      [ngClass]="{'cbw-selected-event-card': isTerminInCart(eventInfo.id)}"
    >
      <div class="w-100 cbw-event-slot-lg">
        <app-cw-checkbox-radio-button
          class="w-100"
          containerClass="ps-0"
          labelClass="flex-direction-col w-100 hide-custom-control-label"
          customId="{{ eventInfo.id }}"
          name="{{ 'freeEvents' + eventInfo.id }}"
          value="{{ eventInfo.id }}"
          [checked]="isTerminInCart(eventInfo.id)"
          [isCheckbox]="true"
          [isDisabled]="(!displayRegularDetails && !displayWaitingDetails) || isSlotBooked(eventInfo.id)"
          (selectionChange)="updateCart($event.event, eventInfo)"
        >
          <span class="event-info-text col-sm-12 col-md-6 mt-2 mt-md-0 cbw-agenda-info-text">
            @if (eventInfo?.title) {
              {{ eventInfo.title }}
            }
            @if (eventState?.is_multi_day === 0) {
            <div>
              {{ eventInfo.start | dateutc:timeFormat }} - {{ eventInfo.end | dateutc:timeFormat }}
            </div>
            }
            @if (eventState?.is_multi_day === 1) {
              <div class="value cbw-agenda-slots-time-difference mb-2">
                  @if (checkIfStartEndDatesAreEqual(eventInfo.start, eventInfo.end)) {
                  {{ eventInfo.start | dateutc:timeFormat }} - {{ eventInfo.end | dateutc:timeFormat }}
                } @else {
                  {{ 'events.step2.multiday_group_appointment' | translate }}:
                }
              </div>
            }
            @if (eventState?.is_multi_day === 1 && eventInfo?.child_slots?.length > 0) {
              <div class="agend-event-info-child-slot cbw-agend-info-child-slot">
                <ul class="ps-3">
                  @for (childSlot of eventInfo.child_slots; track childSlot) {
                    <li class="event-info-child-slot pt-1 cbw-agend-info-child-slot">
                      {{ childSlot.start | dateutc:datePipeWithComma }} - {{ childSlot.end | dateutc:timeFormat }}
                    </li>
                  }
                </ul>
              </div>
            }

          </span>

          @if (!displayRegularDetails) {
            <span class="fw-bold mb-2">
              @if (displayWaitingDetails) {
                @let waiting_count = eventInfo?.waitinglist_capacity - eventInfo?.waitinglist_booking_count;
                <span class="col-sm-12 col-md-6 mt-2 mt-md-0 event-slot-bookedout cbw-slot-bookedout"
                    [innerHTML]="
                    (waiting_count === 1 ? 'alert_messages.singular_waitinglist_is_available' : 'alert_messages.plural_waitinglist_is_available') 
                    | translate: { number: waiting_count } | trustHtml"
                    >
                </span>  
              } @else {
                <span class="col-sm-12 col-md-6 mt-2 mt-md-0 event-slot-bookedout cbw-slot-bookedout">
                  {{ 'alert_messages.window_is_fully_booked' | translate }}
                </span>
              }
              <ng-container *ngTemplateOutlet="eventLocation; context: { $implicit: eventInfo }"/>
            </span>
          }

          @if (displayRegularDetails && !isSlotBooked(eventInfo.id)) {
            <span class="event-slot-bookedout col-sm-12 col-md-6 mt-2 mt-md-0 cbw-slot-bookedout">
              @if (hideEventCapacity === 0) {
              <div class="fw-bold mb-2">
                @if ((eventInfo.regular_capacity - eventInfo.regular_booking_count) === 1) {
                  <span [innerHTML]="('events.step2.regular_count_singular' | translate) | trustHtml"></span>
                }
                @if ((eventInfo.regular_capacity - eventInfo.regular_booking_count) > 1) {
                  <span [innerHTML]="('events.step2.regular_count_plural' | translate: {number: (eventInfo.regular_capacity - eventInfo.regular_booking_count)}) | trustHtml"></span>
                }
              </div>
              <div class="color-grey fw-300 cbw-slot-waiting-count mb-2">
                @if ((eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count) === 1) {
                <span [innerHTML]="('events.step2.waiting_count_singular' | translate) | trustHtml"></span>
                }
                @if ((eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count) > 1) {
                <span
                  [innerHTML]="('events.step2.waiting_count_plural' | translate: {number: (eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count)}) | trustHtml">
                </span>
                }
              </div>

              }
              @if (hideEventCapacity === 1) {
                <div class="fw-bold mb-2 cbw-slot-free-seats">
                  <span>{{ 'events.step2.free_seats' | translate }}</span>
                </div>
                @if ((eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count) >= 1) {
                  <div class="color-grey fw-300 cbw-slot-free-waiting">
                    <span>{{ 'events.step2.free_waiting_seats' | translate }}</span>
                  </div>
                }
              }
              <ng-container *ngTemplateOutlet="eventLocation; context: { $implicit: eventInfo }"/>
            </span>
          }

        </app-cw-checkbox-radio-button>
      </div>
      <div class="next-arrow-container">
        @if (isTerminInCart( eventInfo.id )) {
          <span (click)="gotoNextPage()">
            <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-circle-right"></fa-icon>
          </span>
        }
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #eventLocation let-eventInfo>
  @if (showWidgetSlotLocation) {
    <div class="cbw-summary-date-time d-flex color-grey fw-300">
      @if (eventInfo?.location) {
        @if (
          [1, 12].includes(eventInfo?.meeting_type_id) &&
          eventInfo?.location?.trim() !== ',' &&
          (eventInfo?.location | isEmptyString)
        ) {
          {{ 'common.location' | translate }}: {{ eventInfo?.location }}
        } @else if (eventInfo?.meeting_type_id && ![1, 12]?.includes(eventInfo?.meeting_type_id)) {
          <ng-container *ngTemplateOutlet="meetingType; context: { eventInfo: eventInfo }"/>
        }
      } @else {
        @if (
          [1, 12].includes(eventState?.meeting_type_id) &&
          eventState?.place?.trim() !== ',' &&
          (eventState?.place | isEmptyString)
        ) {
          {{ 'common.location' | translate }}: {{ eventState?.place }}
        } @else if (![1, 12]?.includes(eventState?.meeting_type_id) && eventState?.meeting_type_id) {
          <ng-container *ngTemplateOutlet="meetingType; context: { eventInfo: eventState }"/>
        }
      }
    </div>
  }
</ng-template>

<ng-template #agendaSkeleton>
  <div class="agenda-calendar-ghost cbw-agenda-calendar-ghost shadow">
    <div class="skeleton-animation">
      <div class="agenda-calendar-header cbw-agenda-calendar-header">
        <div class="btn disabled">
          <div class="prev-arrow-container">
            <fa-icon class="icon cbw-prev-arrow-button d-flex" icon="chevron-left" />
          </div>
        </div>
        <div class="bar year-title"></div>
        <div class="btn disabled">
          <div class="next-arrow-container" >
            <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-right" />
          </div>
        </div>
      </div>

      <div class="agenda-calendar-months-wrapper cbw-agenda-calendar-months-wrapper">
        @for (month of [0, 1, 2, 3, 4, 5]; track month) {
          <div class="bar month"></div>
        }
      </div>

      <div class="agenda-slots cbw-agenda-slots">
        @for (month of [0, 1]; track month) {
          <div>
            <div class="bar date"></div>
            <div class="slots">
              @for (month of [0, 1, 2]; track month) {
                <div class="bar slot"></div>
              }
            </div>
          </div>
          <div>
            <div class="bar date"></div>
            <div class="slots">
              @for (month of [0]; track month) {
                <div class="bar slot"></div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>

</ng-template>


<ng-template #meetingType let-eventInfo="eventInfo">
  @if (eventInfo?.meeting_type_id === 1) {
    <img src="{{ environment.baseUrl }}/images/meetings/store.svg" alt="Store Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 2) {
    <img src="{{ environment.baseUrl }}/images/meetings/zoom.svg" alt="Zoom Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 3) {
    <img src="{{ environment.baseUrl }}/images/meetings/calenso.svg" alt="Calenso Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 4) {
    <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 5) {
    <img src="{{ environment.baseUrl }}/images/meetings/customer.svg" alt="Customer Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 6) {
    <img src="{{ environment.baseUrl }}/images/meetings/unblu.svg" alt="Unblu Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 7) {
    <img src="{{ environment.baseUrl }}/images/meetings/teams.svg" alt="Teams Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 8) {
    <img src="{{ environment.baseUrl }}/images/meetings/meet.svg" alt="Meet Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 9) {
    <img src="{{ environment.baseUrl }}/images/meetings/gotomeeting.png" alt="Gotomeeting Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 10) {
    <img src="{{ environment.baseUrl }}/images/meetings/webex.png" alt="Webex Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 11) {
    <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="15px" />
  } @else if (eventInfo?.meeting_type_id === 13) {
    <img src="{{ environment.baseUrl }}/images/meetings/metaverse.svg" alt="Metaverse" width="15px" />
  }
  @if ((widgetTemplates | calioMeetingTemplate: { id: eventInfo?.meeting_type_id, type: 'meeting_template' }); as widgetTemplate) {
    <div class="meeting-option">
      @if ((widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
        <div class="meeting-type-name"
          [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
        </div>
      }
    </div>
  }
</ng-template>
