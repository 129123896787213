@if (showDevAlert) {
  <app-alert text="{{ 'common.devNotice' | translate }}" type="warning" />
}
<div class="password-protection-wrapper cbw-password-protection-wrapper">
  @if (supportedWidgetLanguages?.length > 1 && langSwitcher) {
    <div ngbDropdown class="lang-dropdown-wrapper cbcw-lang-dropdown-wrapper">
      <button class="nav-link lang-dropdown" id="password-protection-lang-dropdown" ngbDropdownToggle>
        <span>
          @for (language of languages; track language) {
            @if (lang === language.locale) {
              <img width="20px" height="20px" (click)="switchLanguage(language.locale)" alt="{{ language.text }}" src="{{ deployUrl + language.flagIcon }}" />
            }
          }
        </span>
      </button>

      @if (supportedWidgetLanguages?.length > 1) {
        <div class="lang-dropdown-menu" ngbDropdownMenu aria-labelledby="password-protection-lang-dropdown">
          @for (language of languages; track language) {
            @if (lang !== language.locale && supportedWidgetLanguages && supportedWidgetLanguages.indexOf(language.value) > -1) {
              <a ngbDropdownItem class="lang-dropdown-menu-item" href="javascript:void(0)" (click)="switchLanguage(language.locale)">
                <span class="d-flex justify-content-center">
                  <img width="20px" height="20px" alt="{{ language.text }}" src="{{ deployUrl + language.flagIcon }}" />
                </span>
              </a>
            }
          }
        </div>
      }
    </div>
  }

  @if (isTemplateLoading) {
    <div class="password-protection skeleton-animation d-flex flex-column align-items-center">
      <div class="bar bar-1"></div>
      <div class="bar bar-2"></div>
      <div class="bar bar-3"></div>
      <div class="bar bar-4"></div>
      <div class="bar bar-5"></div>
    </div>
  } @else {
    <div class="password-protection cbw-password-protection">
      <div class="heading">
        <h1> {{ passwordProtectionTitleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}</h1>
        <p> {{ passwordProtectionDescriptionTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}</p>
      </div>
  
      <div body class="custom-field form-group">
        <label class="input-label mb-1" for="password" id="label_password">
          {{ passwordProtectionLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
        </label>
        <input
          required="true"
          placeholder="{{ passwordProtectionPlaceholderTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
          #password="ngModel"
          name="password"
          type="password"
          ngModel
          pattern=".*\S.*" 
          class="form-control cbw-input-field"
          (keyup.enter)="!password.invalid && submitPassword(password)"
        />
        @if (password.touched && password.invalid) {
          <div class="alert alert-danger summary-warning">
            @if (password.errors?.required) {
              {{ 'customer.customFields.error.required' | translate }}
            } @else if (password.errors?.pattern) {
              {{ 'common.empty_string_validation' | translate }}
            } 
          </div>
        }
        @if (invalidPassword) {
          <div class="alert alert-danger summary-warning">
            {{ 'common.invalid_password' | translate }}
          </div>
        }
        <div class="mt-3 action-button-section">
          <calenso-button
            #button
            id="cbw-next-button"
            (clickEvent)="submitPassword(password)"
            [widgetColorConf]="widgetColorConf"
            placement="right"
            text="{{ passwordProtectionLoginButtonTextTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
            [isloading]="isVerifyPasswordLoading"
            [disabled]="isVerifyPasswordLoading"
          />
        </div>
      </div>
    </div>
  }
</div>