import { AsyncPipe, DatePipe, NgClass, NgStyle, SlicePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarCommonModule, CalendarMonthModule, CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { CalendarHighlightModel } from '../../../db-models/calendar-highlight.model';
import { UserDao } from '../../../db-models/user-data-dao';
import { GlobalObjects, Partner } from '../../../models/global';
import { AppointmentState, ConferenceState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';

const modules = [FontAwesomeModule, TranslateModule, CalendarCommonModule, CalendarMonthModule];
const components = [LoaderComponent, NgbTooltip];
const pipes = [AsyncPipe, SlicePipe, DatePipe, TranslationPipe, TrustHtmlPipe];

@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, ...modules, ...components, ...pipes]
})
export class CalendarPickerComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private customEventService = inject(CustomEventService);
  private translate = inject(TranslateService);

  @Input() noFreeDates: boolean;
  @Input() disablePrev: boolean;
  @Input() viewDate: Date;
  @Input() calendarLoaded = false;
  @Input() days: CalendarMonthViewDay[];
  @Input() widgetColorConf?: WidgetColorConf;
  @Input() set workerSetter(worker: UserDao) {
    this.worker = worker;
  };
  @Input() futureBookingThreshold: number;
  @Input() lang: string;
  @Input() calendarSlotsCountList?: {
    date: Date,
    slotsCount: number
  }[] = [];
  @Input() partner: Partner;
  @Input() calendarPreselectedDate: string;
  @Input() conferenceState: ConferenceState;
  @Input() calendarHighlightData: CalendarHighlightModel[];
  @Input() appointmentState: AppointmentState;
  @Input() globals: GlobalObjects;

  @Output() viewDateChangedEvent = new EventEmitter<Params>();
  @Output() dayClickedEvent = new EventEmitter<any>();
  @Output() markExceptionDaysEvent = new EventEmitter<any>();

  protected readonly appointmentConstant = APPOINTMENT_CONSTANT;

  private preselectedEventTriggered = false;
  protected baseUrl = environment.baseUrl;
  protected disableNext = false;
  protected highlightedDatesDataSet: Record<string, { data: CalendarHighlightModel; css: string }>;
  protected refreshCalendar = new Subject<void>();
  protected worker: UserDao;
  protected workerAvatarUrl = environment.workerAvatarUrl;
  protected isTroubleshootEnabled = false;

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit() {
    this.customEventService.disableCalendarNextButtonEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(disableNext => setTimeout(() => this.disableNext = disableNext, 500));

    this.customEventService.toggleTroubleshootEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(state => this.isTroubleshootEnabled = this.globals.debugMode && state);

    this.customEventService.refreshCalendarEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.refreshCalendar.next());

    this.validateMaxDate(this.viewDate);
    this.calendarHighlightData?.length && (this.highlightedDatesDataSet = this.generateHighlightData(this.calendarHighlightData));
  }

  protected viewDateChanged(viewDate: Date, isNext: boolean): void {
    this.validateMaxDate(viewDate);
    this.viewDate = viewDate;
    this.viewDateChangedEvent.emit({ viewDate: viewDate, isNext: isNext });
  }

  private validateMaxDate(viewDate: Date): void {
    if (this.futureBookingThreshold) {
      const maxDate = moment().add(this.futureBookingThreshold, 'days').toDate();
      this.disableNext = (viewDate.getMonth() == maxDate.getMonth() && viewDate.getFullYear() == maxDate.getFullYear())
    }
  }

  protected dayClicked(day: any): void {
    day?.day?.inMonth && (this.dayClickedEvent.emit(day));
  }

  protected markExceptionDays(body: any): void {
    this.markExceptionDaysEvent.emit(body);
  }

  protected getColorOfDay(day: CalendarMonthViewDay): any {
    let calendarPreselectedDate: Date;
    let hasAlreadySelected = false;

    if (this.calendarPreselectedDate) {
      calendarPreselectedDate = new Date(`${this.calendarPreselectedDate} 00:00:00`);
    }

    for (const calendarSlotsCount of this.calendarSlotsCountList) {
      if (day.date.toString() === calendarSlotsCount.date.toString()) {
        if (calendarSlotsCount.slotsCount && calendarSlotsCount.slotsCount > 0) {
          hasAlreadySelected = true;
          day.cssClass = 'cal-selected';
          day.badgeTotal = calendarSlotsCount.slotsCount;
        }
      }
    }

    if (this.calendarLoaded && hasAlreadySelected === false && calendarPreselectedDate) {
      if (day.date.getTime() === calendarPreselectedDate.getTime()) {
        if (
          day.isWeekend === false &&
          day.isPast === false &&
          !this.preselectedEventTriggered
        ) {
          this.preselectedEventTriggered = true;
          day.cssClass = 'cal-selected';
          this.dayClicked({ day });
        }
      }
    }

    if (day.cssClass === 'cal-selected') {
      return of({
        'color': 'white',
        'background-color': this.widgetColorConf.widget_header_active_color
      }).pipe(delay(500), switchMap((value: any) => of(value)));
    } else {
      if (day.isWeekend) {
        return of({ 'color': '' }).pipe(delay(500), switchMap((value: any) => of(value)));
      } else {
        return of({ 'color': this.widgetColorConf.widget_text_color }).pipe(delay(500), switchMap((value: any) => of(value)));
      }
    }
  }

  private generateHighlightData(data: CalendarHighlightModel[]): Record<string, { data: CalendarHighlightModel; css: string }> {
    const result: Record<string, { data: CalendarHighlightModel; css: string }> = {};
    const today = moment();
    const servicesSet = new Set(this.appointmentState?.services);
    const workerId = this.appointmentState?.worker;

    // Sort the input data based on the priority order in reverse
    data.sort((a, b) => ['worker_id', 'appointment_service_id', 'partner_id'].reduce((result, key) => result || (b[key] && !a[key] ? -1 : !b[key] && a[key] ? 1 : 0), 0));

    for (const highlight of data) {
      const endDate = moment(highlight.end, 'YYYY-MM-DD');
      if (endDate.isBefore(today, 'day')) continue; // Skip if the end date is in the past

      const startDate = moment(highlight.start, 'YYYY-MM-DD');
      const adjustedStartDate = startDate.isBefore(today, 'day') ? today : startDate;
      const adjustedHighlight = { ...highlight, start: adjustedStartDate.format('YYYY-MM-DD') };

      if (adjustedHighlight.appointment_service_id && !servicesSet.has(adjustedHighlight.appointment_service_id)) {
        continue;
      } else if (adjustedHighlight.worker_id && workerId !== adjustedHighlight.worker_id) {
        continue;
      }

      const currentStartDate = moment(adjustedHighlight.start, 'YYYY-MM-DD');
      const currentEndDate = moment(adjustedHighlight.end, 'YYYY-MM-DD');

      for (let currentDate = currentStartDate.clone(); currentDate.isSameOrBefore(currentEndDate); currentDate.add(1, 'day')) {
        const dateKey = currentDate.format('YYYY-MM-DD');

        let cssClasses = 'cbw-highlight-day';

        if (currentStartDate.isSame(currentEndDate)) {
          cssClasses += ' cbw-highlight-start cbw-highlight-end';
        } else if (currentDate.isSame(currentStartDate)) {
          cssClasses += ' cbw-highlight-start';
        } else if (currentDate.isSame(currentEndDate)) {
          cssClasses += ' cbw-highlight-end';
        } else {
          cssClasses += ' cbw-highlight-middle';
        }

        result[dateKey] = {
          data: adjustedHighlight,
          css: cssClasses,
        };

        // If a highlight already exists for this date and it's the start date, update the existing CSS
        const dateBeforeKey = moment(dateKey).subtract(1, 'day').format('YYYY-MM-DD');
        if (
          result[dateKey] &&
          result[dateKey].css.includes('cbw-highlight-start') &&
          result[dateBeforeKey] &&
          (result?.[dateBeforeKey]?.css?.includes('cbw-highlight-middle') || result?.[dateBeforeKey]?.css?.includes('cbw-highlight-start'))
        ) {
          if (result[dateBeforeKey].css.includes('cbw-highlight-middle')) {
            result[dateBeforeKey].css = result[dateBeforeKey].css.replace('cbw-highlight-middle', '');
          }
          result[dateBeforeKey].css += ' cbw-highlight-end';
        }
        const dateAfterKey = moment(dateKey).add(1, 'day').format('YYYY-MM-DD');
        if (
          result[dateKey] &&
          result[dateKey].css.includes('cbw-highlight-end') &&
          result[dateAfterKey] &&
          (result?.[dateAfterKey]?.css?.includes('cbw-highlight-middle') || result?.[dateAfterKey]?.css?.includes('cbw-highlight-end'))
        ) {
          if (result[dateAfterKey].css.includes('cbw-highlight-middle')) {
            result[dateAfterKey].css = result[dateAfterKey].css.replace('cbw-highlight-middle', '');
          }
          result[dateAfterKey].css += ' cbw-highlight-start';
        }
      }
    }

    // Add custom class for each date based on priority
    Object.values(result).forEach(date => {
      if (date.data.worker_id) {
        date.css += ' cbw-highlight-worker';
      } else if (date.data.appointment_service_id) {
        date.css += ' cbw-highlight-service';
      } else if (date.data.partner_id) {
        date.css += ' cbw-highlight-global';
      }
    });
    return result;
  }
}
