import { enableProdMode } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { createApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { WidgetComponent } from './app/widget/widget.component';
import { environment } from './environments/environment';
// import { SentryConfig } from './sentry.config';

if (environment?.production) {
  enableProdMode();
  // Sentry.init(SentryConfig);
}

(async () => {
  const app = await createApplication(appConfig);

  const inputElement = createCustomElement(WidgetComponent, {
    injector: app.injector,
  });
  customElements.define('calenso-booking', inputElement);
})();